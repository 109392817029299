// react components
import React from 'react'
import {
    Link,
} from 'react-router-dom'

// components
import {
    ImageHelper,
} from 'components'

// data
import {
    view_url_room_detail,
} from 'data'

// serializers
import {
    RoomListBlockSerializer,
} from 'serializers'

// props
type RoomListBlockProps = {
    object: RoomListBlockSerializer
}

// main
export const RoomListBlock: React.FC<RoomListBlockProps> = React.memo(({
    object,
}) => {

    return (
        <Link
            className='room-list-block-web'
            to={`${view_url_room_detail}${object.id}/`}
        >
            {object.room_user?.userprofile?.get_image_lq
                ? (
                    <ImageHelper
                        alt=''
                        className='rlbw-profile-pic'
                        dominant_color={object.room_user.userprofile.get_dominant_color}
                        src={object.room_user.userprofile.get_image_lq}
                    />
                ) : (
                    object.room_user?.first_name
                        ? (
                            <div className='rlbw-profile-nopic'>
                                <span>{object.room_user.first_name[0]}</span>
                            </div>
                        ) : (
                            <ImageHelper
                                alt=''
                                className='rlbw-profile-pic'
                                dominant_color={undefined}
                                src={undefined}
                            />
                        )
                )}
            <div className='rlbw-wrap'>
                <div className='rlbw-center'>
                    <p className='rlbw-name'>{object.room_user?.first_name}</p>
                    <p className='rlbw-property'>{object.property?.title}</p>
                    <p className='rlbw-message'>{object.last_message?.body}</p>
                </div>
                <p className='rlbw-phone'>{object.room_user?.userprofile?.phone_number}</p>
            </div>
        </Link>
    )
})
