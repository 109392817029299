// data
import {
    reduxTextDataState,
} from 'data'

// main
export function getMainErrorMessage(thisError: any, reduxText: reduxTextDataState) {
    const mainError = thisError.message
    const errorStatus = thisError.response && thisError.response.status
    if (errorStatus === 400) {
        const tempError = thisError.response.data
        tempError.main_error = reduxText[790]
        return tempError
    }
    if (errorStatus === 500) {
        return { main_error: reduxText[791] }
    }
    return thisError.response ? thisError.response.data : { main_error: mainError }
}
