// react components
import React, {
    useState,
} from 'react'
import {
    IonAlert,
    IonContent,
    IonPage,
} from '@ionic/react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    Footer,
    Loader,
    SelectListInput,
    SeoBlock,
    TextAreaInput,
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormSetSuccessModal,
    reduxModalErrorEventHandler,
    select_url_subject,
} from 'data'

// serializers
import {
    FormMotherStateProps,
} from 'serializers'

// pages
import {
    ContactMotherFormState,
    ContactMotherFormStateProps,
} from 'pages'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getFormData,
    getInputValue,
    getMainErrorMessage,
} from 'services'

// main
export const ContactForm: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [mainError, setMainError] = useState<string>('')
    const [mother, setMother] = useState<FormMotherStateProps & ContactMotherFormStateProps>(ContactMotherFormState(reduxAuth))

    function handleInputChange(event: any) {
        try {
            setMother(getInputValue(event, mother))
            setButtonDisabled(false)
            setMainError('')
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContactForm',
                'handleInputChange',
            ))
        }
    }

    function handleRequired() {
        try {
            const result = checkRequiredFields(mother, reduxText)
            if (result === 'ok') {
                handleSubmit()
            } else {
                setMainError(reduxText[506])
                setMother({
                    ...mother,
                    errors: result,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContactForm',
                'handleRequired',
            ))
        }
    }

    function handleSubmit() {
        try {
            setButtonDisabled(true)
            setIsSaving(true)
            const axiosUrl = mother.createUrl
            const formData: any = getFormData(mother)
            axios({
                data: formData,
                method: 'post',
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsSaving(false)
                    setMother({
                        ...mother,
                        errors: {},
                        fields: {},
                    })
                    dispatch(reduxFormSetSuccessModal(true, reduxText[747], reduxText[748], reduxText[749], reduxText[746]))
                })
                .catch((error) => {
                    setButtonDisabled(false)
                    setIsSaving(false)
                    setMainError(error.response?.data?.non_field_errors || '')
                    setMother({
                        ...mother,
                        errors: getMainErrorMessage(error, reduxText),
                    })
                    if (!error.response?.data?.non_field_errors) {
                        axiosErrorHandler({
                            apiUrl: axiosUrl,
                            component: 'ContactForm',
                            dispatch,
                            error,
                            formFields: JSON.stringify(mother.fields),
                            reduxAuth,
                            reference: 'handleSubmit',
                        })
                    }
                })
        } catch (error) {
            if (process.env.NODE_ENV === 'development') console.log(error)
            setButtonDisabled(false)
            setIsSaving(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'ContactForm',
                'handleSubmit',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    return (
        <IonPage className='contact-form-web navbar-pt'>
            <SeoBlock pageId={11} />
            <IonContent>
                <div className='mb-container'>
                    <div className='cfw-content'>
                        <h1 className='cfw-title'>{reduxText[634]}</h1>
                        <p className='cfw-description'>{reduxText[635]}</p>
                        <div className='cfw-input-wrap'>
                            <TextInput
                                error={errorsMother?.first_name}
                                name='first_name'
                                onChange={handleInputChange}
                                placeholder={reduxText[637]}
                                value={fieldsMother.first_name || ''}
                            />
                            <TextInput
                                error={errorsMother?.last_name}
                                name='last_name'
                                onChange={handleInputChange}
                                placeholder={reduxText[636]}
                                value={fieldsMother.last_name || ''}
                            />
                        </div>
                        <div className='cfw-input-wrap'>
                            <TextInput
                                error={errorsMother?.mail}
                                name='mail'
                                onChange={handleInputChange}
                                placeholder={reduxText[638]}
                                value={fieldsMother.mail || ''}
                            />
                            <TextInput
                                error={errorsMother?.company}
                                name='company'
                                onChange={handleInputChange}
                                placeholder={reduxText[639]}
                                value={fieldsMother.company || ''}
                            />
                        </div>
                        <div className='cfw-input-wrap'>
                            <TextInput
                                error={errorsMother?.phone}
                                name='phone'
                                onChange={handleInputChange}
                                placeholder={reduxText[640]}
                                value={fieldsMother.phone || ''}
                            />
                            <SelectListInput
                                apiUrl={select_url_subject}
                                clearable={false}
                                error={errorsMother?.subject_new}
                                name='subject_new'
                                onChange={handleInputChange}
                                placeholder={reduxText[641]}
                                value={fieldsMother.subject_new || ''}
                            />
                        </div>
                        <TextAreaInput
                            error={errorsMother?.message}
                            name='message'
                            onChange={handleInputChange}
                            placeholder={reduxText[642]}
                            value={fieldsMother.message || ''}
                        />
                        <Button
                            disabled={buttonDisabled}
                            onClick={() => handleRequired()}
                            text={reduxText[643]}
                        />
                    </div>
                </div>
                <Footer />
            </IonContent>
            {isSaving && (
                <Loader
                    isOpen
                    message={reduxText[514]}
                />
            )}
            <IonAlert
                buttons={[reduxText[510]]}
                header={reduxText[511]}
                isOpen={Boolean(mainError)}
                message={`${mainError}${mainError === 'Network Error' ? reduxText[581] : ''}`}
                onDidDismiss={() => setMainError('')}
            />
        </IonPage>
    )
}
