// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    Footer,
    ImageHelper,
    InfiniteScrollHelper,
    SeoBlock,
} from 'components'

// data
import {
    defaultReduxState,
    api_url_article_list,
    reduxFormSetIsNewsletterOpen,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    ArticleListBlock,
} from 'pages'

// serializers
import {
    ArticleListBlockSerializer,
} from 'serializers'

// services
import {
    getApiUrl,
    getInfiniteList,
} from 'services'

// main
export const ArticleList: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        onGetListData(getApiUrl(api_url_article_list, reduxAuth), true)
    }, [reduxAuth.settings?.id])

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(true)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [items, setItems] = useState<ArticleListBlockSerializer[]>([])
    const [nextUrl, setNextUrl] = useState<string>('')

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteList(
                apiUrl,
                reduxAuth,
                dispatch,
                items,
                setItems,
                setNextUrl,
                axiosCancelToken,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setIsLoading,
                setHasMore,
                'ArticleList',
                changingView,
            )
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ArticleList',
                'onGetListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ArticleList',
                'onSearchNext',
            ))
        }
    }

    return (
        <IonPage className='article-list-web navbar-pt'>
            <SeoBlock pageId={2} />
            <IonContent>
                <div className='alw-cover'>
                    <ImageHelper
                        alt=''
                        className='alw-cover-image'
                        dominant_color={reduxText[53800]}
                        src={reduxText[538]}
                    />
                    <div className='alw-cover-text'>
                        <div className='mb-container'>
                            <h1 className='alw-text1'>{reduxText[536]}</h1>
                            <p className='alw-text2'>{reduxText[537]}</p>
                        </div>
                    </div>
                </div>
                <div className='mb-container'>
                    <div className='alw-infinite'>
                        {items.map((item) => {
                            return (
                                <ArticleListBlock
                                    key={item.id}
                                    object={item}
                                />
                            )
                        })}
                        <InfiniteScrollHelper
                            active={!disableInfiniteScroll}
                            endText
                            hasMore={hasMore}
                            isLoading={isLoading}
                            items={items}
                            onChange={onSearchNext}
                        />
                    </div>
                    <div className='alw-description-wrap'>
                        <ImageHelper
                            alt=''
                            className='alw-image'
                            dominant_color={reduxText[54100]}
                            src={reduxText[541]}
                        />
                        <div>
                            <p className='alw-text'>{reduxText[542]}</p>
                            <Button
                                onClick={() => dispatch(reduxFormSetIsNewsletterOpen(true))}
                                text={reduxText[543]}
                            />
                        </div>
                    </div>
                </div>
                <Footer />
            </IonContent>
        </IonPage>
    )
}
