// react components
import axios from 'axios'
import {
    DeviceInfo,
} from '@capacitor/device'

// data
import {
    api_url_auth_token_convert,
    api_url_auth_token_obtain,
    api_url_profile_action_detail,
    api_url_settings_detail,
    api_url_token_access_detail,
    reduxAuthState,
    view_url_profile_detail,
    view_url_profile_property_list,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    sendWelcomeMail,
} from 'services'

// constants
export const REDUX_AUTH_DEVICE_INFO = 'REDUX_AUTH_DEVICE_INFO'
export const REDUX_AUTH_FETCH_ACTIONS = 'REDUX_AUTH_FETCH_ACTIONS'
export const REDUX_AUTH_FETCH_SETTINGS = 'REDUX_AUTH_FETCH_SETTINGS'
export const REDUX_AUTH_GOOGLE_AUTH_FULFILLED = 'REDUX_AUTH_GOOGLE_AUTH_FULFILLED'
export const REDUX_AUTH_GOOGLE_AUTH_REJECTED = 'REDUX_AUTH_GOOGLE_AUTH_REJECTED'
export const REDUX_AUTH_LOGIN_FULFILLED = 'REDUX_AUTH_LOGIN_FULFILLED'
export const REDUX_AUTH_LOGIN_REJECTED = 'REDUX_AUTH_LOGIN_REJECTED'
export const REDUX_AUTH_LOGOUT = 'REDUX_AUTH_LOGOUT'
export const REDUX_AUTH_SET_ACTION = 'REDUX_AUTH_SET_ACTION'

const GOOGLE_BACKEND = 'google-oauth2'
export const GOOGLE_CLIENT_ID = process.env.NODE_ENV !== 'production' ? '430535249587-7b5imj3prp5ccsohvv4kgot1fa9fmuli.apps.googleusercontent.com' : '282161909547-skhilc1pb3nvin61o9rundkfqhsq50lv.apps.googleusercontent.com'
const GOOGLE_CLIENT_SECRET = process.env.NODE_ENV !== 'production' ? 'buHZ-ESHQkVah0na4dHyC-_X' : 'Zva98KO5atalco93aXJL71_3'
const GOOGLE_GRANT_TYPE = 'convert_token'

const GOOGLE_CONVERT_TOKEN_DATA_STRING = `grant_type=${GOOGLE_GRANT_TYPE}&client_id=${GOOGLE_CLIENT_ID}&client_secret=${GOOGLE_CLIENT_SECRET}&backend=${GOOGLE_BACKEND}&token=`

export function reduxAuthDeviceInfo(deviceInfo: DeviceInfo) {
    return {
        type: REDUX_AUTH_DEVICE_INFO,
        payload: deviceInfo,
    }
}

export function reduxAuthFetchActions(reduxAuth: reduxAuthState, dispatch2: any) {
    return function (dispatch: any) {
        const getUrl = getApiUrl(`${api_url_profile_action_detail}${reduxAuth.settings?.id}/`, reduxAuth)
        axios({
            headers: reduxAuth.axiosConfig?.headers,
            method: 'get',
            url: getUrl,
        })
            .then((response) => {
                dispatch({
                    type: REDUX_AUTH_FETCH_ACTIONS,
                    payload: response.data,
                })
            })
            .catch((error) => {
                axiosErrorHandler({
                    apiUrl: getUrl,
                    component: 'reduxAuthFetchActions',
                    dispatch: dispatch2,
                    error,
                    reduxAuth,
                    reference: 'get',
                })
            })
    }
}

export function reduxAuthFetchSettings(reduxAuth: reduxAuthState, dispatch2: any) {
    return function (dispatch: any) {
        if (!reduxAuth.settings?.id) return
        const getUrl = getApiUrl(api_url_settings_detail, reduxAuth)
        axios({
            headers: reduxAuth.axiosConfig.headers,
            method: 'get',
            url: getUrl,
        })
            .then((response) => {
                dispatch({
                    type: REDUX_AUTH_FETCH_SETTINGS,
                    payload: response.data,
                })
            })
            .catch((error) => {
                axiosErrorHandler({
                    apiUrl: getUrl,
                    component: 'reduxAuthFetchSettings',
                    dispatch: dispatch2,
                    error,
                    reduxAuth,
                    reference: 'get',
                })
            })
    }
}

export function reduxAuthLogin(
    username: string,
    password: string,
    reduxAuth: reduxAuthState,
    dispatch2: any,
    setIsLoading: any,
    history: any,
) {
    return function (dispatch: any) {
        const postUrl = getApiUrl(api_url_auth_token_obtain, reduxAuth)
        const formData = new FormData()
        formData.append('username', username)
        formData.append('password', password)
        axios({
            data: formData,
            method: 'post',
            url: postUrl,
        })
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log(response)
                const getUrl = getApiUrl(api_url_settings_detail, reduxAuth) // Keep the last '/' for Safari !!!!!!!
                const axiosHeaders = {
                    Authorization: `JWT ${response.data.access}`,
                }
                axios({
                    headers: axiosHeaders,
                    method: 'get',
                    url: getUrl,
                })
                    .then((response2) => {
                        if (process.env.NODE_ENV === 'development') console.log(response2)
                        dispatch({
                            type: REDUX_AUTH_LOGIN_FULFILLED,
                            payload: response.data,
                            settings: response2.data,
                        })
                        if (!response2.data.is_mail_sent) {
                            sendWelcomeMail(response2.data.id, reduxAuth, dispatch)
                        }
                        if (response2.data.is_profile_checked) {
                            history.push(view_url_profile_property_list)
                        } else {
                            history.push(view_url_profile_detail)
                        }
                    })
                    .catch((error) => {
                        dispatch({
                            type: REDUX_AUTH_LOGIN_REJECTED,
                            payload: error,
                        })
                        setIsLoading(false)
                        axiosErrorHandler({
                            error,
                            reduxAuth,
                            component: 'reduxAuthLogin',
                            apiUrl: getUrl,
                            reference: `get ${username}`,
                            dispatch: dispatch2,
                        })
                    })
            })
            .catch((error) => {
                dispatch({
                    type: REDUX_AUTH_LOGIN_REJECTED,
                    payload: error,
                })
                setIsLoading(false)
                axiosErrorHandler({
                    apiUrl: postUrl,
                    component: 'reduxAuthLogin',
                    dispatch: dispatch2,
                    error,
                    reduxAuth,
                    reference: `post ${username}`,
                    skip401: true,
                })
            })
    }
}

export function reduxAuthGoogle(
    idToken: string,
    refreshToken: string,
    reduxAuth: reduxAuthState,
    dispatch2: any,
    setIsLoading: any,
    history: any,
) {
    return function (dispatch: any) {
        const postUrl = getApiUrl(api_url_auth_token_convert, reduxAuth)
        axios.post(postUrl, `${GOOGLE_CONVERT_TOKEN_DATA_STRING + idToken}`)
            .then((response2) => {
                if (process.env.NODE_ENV === 'development') console.log('reduxAuthGoogle response2', response2)
                const getUrl = getApiUrl(`${api_url_token_access_detail}${response2.data.access_token}/`, reduxAuth)
                axios({
                    method: 'get',
                    url: getUrl,
                })
                    .then((response3) => {
                        if (process.env.NODE_ENV === 'development') console.log('reduxAuthGoogle response3', response3)
                        setIsLoading(false)
                        dispatch({
                            type: REDUX_AUTH_GOOGLE_AUTH_FULFILLED,
                            payload: response3.data,
                            refresh_token: refreshToken,
                        })
                        if (!response3.data.settings.is_mail_sent) {
                            sendWelcomeMail(response3.data.settings.id, reduxAuth, dispatch)
                        }
                        if (response3.data.settings.is_profile_checked) {
                            history.push(view_url_profile_property_list)
                        } else {
                            history.push(view_url_profile_detail)
                        }
                    })
                    .catch((error) => {
                        if (process.env.NODE_ENV === 'development') console.log('reduxAuthGoogle error', error)
                        dispatch({
                            type: REDUX_AUTH_GOOGLE_AUTH_REJECTED,
                            payload: error,
                        })
                        setIsLoading(false)
                        axiosErrorHandler({
                            apiUrl: getUrl,
                            component: 'reduxAuthGoogle',
                            dispatch: dispatch2,
                            error,
                            reduxAuth,
                            reference: 'get',
                        })
                    })
            })
            .catch((googleError) => {
                if (process.env.NODE_ENV === 'development') console.log('reduxAuthGoogle googleError', googleError)
                dispatch({
                    type: REDUX_AUTH_GOOGLE_AUTH_REJECTED,
                    payload: googleError,
                })
                setIsLoading(false)
                axiosErrorHandler({
                    apiUrl: postUrl,
                    component: 'reduxAuthGoogle',
                    dispatch: dispatch2,
                    error: googleError,
                    reduxAuth,
                    reference: 'post',
                })
            })
    }
}

export function reduxAuthLogout() {
    return {
        type: REDUX_AUTH_LOGOUT,
    }
}

export function reduxAuthSetAction(
    action: 'a' | 'r',
    type: 'bookmark',
    objectId: number,
) {
    return {
        type: REDUX_AUTH_SET_ACTION,
        payload: {
            action: action,
            type: type,
            objectId: objectId,
        },
    }
}
