// react components
import React from 'react'
import {
    IonPage,
    IonHeader,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'
import {
    Redirect,
} from 'react-router-dom'

// components
import {
    SeoBlock,
} from 'components'

// data
import {
    defaultReduxState,
    view_url_auth_login,
} from 'data'

// pages
import {
    FullPropertyPanel,
} from 'pages'

// main
export const PropertyEditForm: React.FC = () => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (!reduxAuth.authenticated) {
        return <Redirect to={view_url_auth_login} />
    }
    return (
        <IonPage className='property-form-web'>
            <SeoBlock pageId={10} />
            <IonHeader className='pfw-header'>
                <p className='pfw-text'>{reduxText[683]}</p>
            </IonHeader>
            <FullPropertyPanel />
        </IonPage>
    )
}
