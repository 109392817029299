// Root Url
const rootPath = 'v1/catalog/'

// Detail
export const api_url_property_detail = `${rootPath}detail/property/`

// List
export const api_url_property_list_favourite = `${rootPath}list/property/favourite/`
export const api_url_property_list_home = `${rootPath}list/property/home/`
export const api_url_property_list_map = `${rootPath}list/property/map/`
export const api_url_property_list_profile = `${rootPath}list/property/profile/`
export const api_url_property_list = `${rootPath}list/property/`
