// data
import {
    api_url_property_form_detail,
    api_url_image_form_create,
    api_url_image_form_detail,
    api_url_image_form_list,
    api_url_image_form_patch,
    api_url_image_form_update,
    reduxAuthState,
} from 'data'

// serializers
import {
    ImageDetailFormSerializer,
    ImageDetailFormSerializerError,
} from 'serializers'

// services
import {
    getApiUrl,
} from 'services'

// props
export type ImageFormStateProps = {
    errors: ImageDetailFormSerializerError | undefined
    fields: ImageDetailFormSerializer | undefined
}

// main
export function ImageFormState(reduxAuth: reduxAuthState) {
    return {
        // Urls
        createUrl: getApiUrl(api_url_image_form_create, reduxAuth),
        detailInlineUrl: getApiUrl(api_url_image_form_detail, reduxAuth),
        detailUrl: getApiUrl(api_url_property_form_detail, reduxAuth),
        listUrl: getApiUrl(api_url_image_form_list, reduxAuth),
        patchUrl: getApiUrl(api_url_image_form_patch, reduxAuth),
        updateUrl: getApiUrl(api_url_image_form_update, reduxAuth),

        // Fields
        errors: {},
        fields: undefined,

        // Required fields
        requiredAttribute: [
            'image',
        ],
        requiredForeignKey: [
            'property',
        ],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'image_doka_output_data',
        ],
        formFile: [
            'image',
        ],
        formForeignKey: [
            'property',
        ],
        formJson: [],
        formManyToMany: [],
    }
}
