// react components
import axios from 'axios'

// data
import {
    api_url_main_text_detail,
    reduxAuthState,
} from 'data'

// services
import {
    axiosErrorHandler,
} from 'services'

// constants
export const REDUX_TEXT_FETCH_MAIN_TEXT = 'REDUX_TEXT_FETCH_MAIN_TEXT'

// actions
export function reduxTextFetchMainText(reduxAuth: reduxAuthState, dispatch2: any) {
    return function (dispatch: any) {
        // const getUrl = `http://localhost:8004/${api_url_main_text_detail}1/`
        const getUrl = `https://api.mylab.immo/${api_url_main_text_detail}1/`
        axios({
            method: 'get',
            url: getUrl,
        })
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log(response)
                dispatch({
                    type: REDUX_TEXT_FETCH_MAIN_TEXT,
                    payload: response.data,
                })
            })
            .catch((error) => {
                axiosErrorHandler({
                    apiUrl: getUrl,
                    component: 'reduxTextFetchMainText',
                    dispatch: dispatch2,
                    error,
                    reduxAuth,
                    reference: 'get',
                })
            })
    }
}
