// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Link,
    useHistory,
    useLocation,
} from 'react-router-dom'

// components
import {
    Button,
    ErrorInput,
    Loader,
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
    api_url_auth_user_activation,
    reduxAuthLogin,
    reduxModalErrorEventHandler,
    view_url_auth_password_reset,
} from 'data'

// pages
import {
    GoogleLoginBlock,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getApiUrl,
} from 'services'

// props
type LoginBlockProps = {
    emptyFields: number
    setEmptyField: any
}

// main
export const LoginBlock: React.FC<LoginBlockProps> = React.memo(({
    emptyFields,
    setEmptyField,
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        if (location.hash && location.hash.split('/')[1] === 'activate') {
            const formData = new FormData()

            // Fields
            formData.append('uid', location.hash.split('/')[2])
            formData.append('token', location.hash.split('/')[3])

            // Submit Method
            const postUrl = getApiUrl(api_url_auth_user_activation, reduxAuth)
            axios({
                data: formData,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'LoginBlock',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'useEffect',
                        skipAlert: true,
                    })
                })
        }
    }, [location.hash])

    useEffect(() => {
        setFields(fieldsInitial)
    }, [emptyFields])

    type fieldsType = {
        password: string
        username: string
    }
    const fieldsInitial = {
        password: '',
        username: '',
    }
    const loginAuthError = reduxAuth.errors?.loginAuthError

    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setButtonDisabled(false)
            setFields({ ...fields, [name]: event.value })
            setEmptyField(Math.random())
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LoginBlock',
                'handleInputChange',
            ))
        }
    }

    function onSubmit() {
        try {
            setButtonDisabled(true)
            setIsLoading(true)
            // @ts-ignore
            dispatch(reduxAuthLogin(
                fields.username,
                fields.password,
                reduxAuth,
                dispatch,
                setIsLoading,
                history,
            ))
        } catch (error) {
            setButtonDisabled(false)
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'LoginBlock',
                'onSubmit',
            ))
        }
    }

    return (
        <div className='login-block-web'>
            <h1 className='lbw-title'>{reduxText[478]}</h1>
            {!(location.hash && location.hash.split('/')[1] === 'activate') && (
                <React.Fragment>
                    <GoogleLoginBlock
                        setIsLoading={setIsLoading}
                        signIn
                    />
                    <div className='lbw-separation'>
                        <div className='lbw-separation-line'>
                            <hr />
                        </div>
                        <p>{reduxText[488]}</p>
                        <div className='lbw-separation-line'>
                            <hr />
                        </div>
                    </div>
                </React.Fragment>
            )}
            {location.hash && location.hash.split('/')[1] === 'activate' && (
                <p className='lbw-activation-text'>{reduxText[489]}</p>
            )}
            <TextInput
                error={loginAuthError?.username}
                name='username'
                onChange={handleInputChange}
                placeholder={reduxText[479]}
                value={fields.username}
            />
            <TextInput
                error={loginAuthError?.password}
                name='password'
                onChange={handleInputChange}
                placeholder={reduxText[480]}
                type='password'
                value={fields.password}
            />
            {(loginAuthError?.non_field_errors || loginAuthError?.detail) && (
                <ErrorInput
                    error={`${loginAuthError.non_field_errors || loginAuthError.detail}`}
                />
            )}
            <Link
                className='lbw-password-reset'
                to={view_url_auth_password_reset}
            >
                {reduxText[481]}
            </Link>
            <Button
                disabled={buttonDisabled}
                onClick={onSubmit}
                text={reduxText[482]}
            />
            <Loader isOpen={isLoading} />
        </div>
    )
})
