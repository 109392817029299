// react components
import React from 'react'
import axios from 'axios'

// data
import {
    reduxAuthState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

// main
export function getDetailData(
    mother: any,
    objectId: any,
    reduxAuth: reduxAuthState,
    setMother: any,
    setIsLoading: any,
    dispatch: React.Dispatch<any>,
    component: string,
    defaultFields?: any,
) {
    try {
        if (reduxAuth.settings?.id && objectId === 'new') {
            setMother({
                ...mother,
                fields: defaultFields || {},
            })
            setIsLoading(false)
        } else if (reduxAuth.settings?.id && objectId && (objectId !== 'new')) {
            setIsLoading(true)
            const getUrl = `${mother?.detailUrl}${objectId}/` // Keep the last '/' for Safari !!!!!!!
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: getAxiosHeaders(reduxAuth, dispatch, component),
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setMother({
                        ...mother,
                        fields: response.data,
                    })
                    setIsLoading(false)
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component,
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getDetailData',
                    })
                })
        }
    } catch (error) {
        dispatch(reduxModalErrorEventHandler(
            error,
            component,
            'getDetailData',
        ))
    }
}
