// react components
import React from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Footer,
    ImageHelper,
    SeoBlock,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    PropertyListFiltered,
} from 'pages'

// main
export const TraiteurPage: React.FC = () => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <IonPage className='main-page-web navbar-pt'>
            <SeoBlock pageId={28} />
            <IonContent>
                <div className='mpw-content mb-container'>
                    <div className='mpw-title-text-block'>
                        <h1 className='mb-main-title'>{reduxText[909]}</h1>
                        <p className='mb-main-text'>{reduxText[910]}</p>
                    </div>
                    <div className='property-section'>
                        <div className='mpw-title-text-block'>
                            <h2 className='mb-main-title'>{reduxText[911]}</h2>
                        </div>
                        <PropertyListFiltered activity={1} />
                    </div>
                    <div className='mpw-image-text-block'>
                        <div className='mpw-image-wrap'>
                            <ImageHelper
                                alt=''
                                className='mpw-image'
                                dominant_color={reduxText[91400]}
                                src={reduxText[914]}
                            />
                        </div>
                        <div className='mpw-text-wrap'>
                            <h2 className='mb-main-title split'>{reduxText[912]}</h2>
                            <p className='mb-main-text'>{reduxText[913]}</p>
                        </div>
                    </div>
                    <div className='mpw-title-text-block'>
                        <h2 className='mb-main-title'>{reduxText[915]}</h2>
                        <p className='mb-main-text'>{reduxText[916]}</p>
                    </div>
                    <div className='mpw-image-text-block reverse'>
                        <div className='mpw-image-wrap'>
                            <ImageHelper
                                alt=''
                                className='mpw-image'
                                dominant_color={reduxText[92500]}
                                src={reduxText[925]}
                            />
                        </div>
                        <div className='mpw-text-wrap'>
                            <h2 className='mb-main-title split'>{reduxText[917]}</h2>
                            <p className='mb-main-text'>{reduxText[918]}</p>
                        </div>
                    </div>
                    <div className='mpw-image-text-block'>
                        <div className='mpw-image-wrap'>
                            <ImageHelper
                                alt=''
                                className='mpw-image'
                                dominant_color={reduxText[92600]}
                                src={reduxText[926]}
                            />
                        </div>
                        <div className='mpw-text-wrap'>
                            <h2 className='mb-main-title split'>{reduxText[919]}</h2>
                            <p className='mb-main-text'>{reduxText[920]}</p>
                        </div>
                    </div>
                    <div className='mpw-title-text-block'>
                        <h2 className='mb-main-title'>{reduxText[921]}</h2>
                        <p className='mb-main-text'>{reduxText[922]}</p>
                    </div>
                    <div className='mpw-image-text-block'>
                        <div className='mpw-image-wrap'>
                            <ImageHelper
                                alt=''
                                className='mpw-image'
                                dominant_color={reduxText[92700]}
                                src={reduxText[927]}
                            />
                        </div>
                        <div className='mpw-text-wrap'>
                            <h2 className='mb-main-title split'>{reduxText[923]}</h2>
                            <p className='mb-main-text'>{reduxText[924]}</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </IonContent>
        </IonPage>
    )
}
