// main
export function getFormData(formInfoObject: any) {

    // Construct FormData
    const formData = new FormData()

    // ForeignKey
    if (Array.isArray(formInfoObject.formForeignKey)) {
        formInfoObject.formForeignKey.map((field: string) => {
            if (formInfoObject.fields[field]) {
                formData.append(field, formInfoObject.fields[field] ? formInfoObject.fields[field].id : '')
            } else if (formInfoObject.fields.id) {
                formData.append(field, '')
            }
            return false
        })
    }

    // ManyToMany
    if (Array.isArray(formInfoObject.formManyToMany)) {
        formInfoObject.formManyToMany.map((field: string) => {
            if (Array.isArray(formInfoObject.fields[field])) {
                formInfoObject.fields[field].map((value: any) => {
                    formData.append(field, value.id)
                    return false
                })
            }
            return false
        })
    }

    // Files
    if (Array.isArray(formInfoObject.formFile)) {
        formInfoObject.formFile.map((field: string) => {
            if (formInfoObject.fields[field] && formInfoObject.fields[field].type) {
                const filetoUpload = formInfoObject.fields[field]
                let filetoUploadName = formInfoObject.fields[field].name
                if (filetoUploadName.length > 100) {
                    filetoUploadName = filetoUploadName.slice(filetoUploadName.length - 100)
                }
                formData.append(field, filetoUpload, filetoUploadName)
            }
            return false
        })
    }

    // Attributes
    if (Array.isArray(formInfoObject.formAttribute)) {
        formInfoObject.formAttribute.map((field: string) => {
            if (formInfoObject.fields[field]) {
                formData.append(field, formInfoObject.fields[field])
            } else if (formInfoObject.fields[field] === 0) {
                formData.append(field, formInfoObject.fields[field])
            } else if (formInfoObject.fields[field] === false) {
                formData.append(field, formInfoObject.fields[field])
            } else {
                formData.append(field, '')
            }
            return false
        })
    }

    // Json
    if (Array.isArray(formInfoObject.formJson)) {
        formInfoObject.formJson.map((field: string) => {
            formData.append(field, formInfoObject.fields[field] ? JSON.stringify(formInfoObject.fields[field]) : '')
            return false
        })
    }

    return formData
}
