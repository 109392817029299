// react components
import React from 'react'
import {
    Link,
} from 'react-router-dom'

// props
type ButtonProps = {
    disabled?: boolean
    href?: string
    onClick?: (e: any) => void
    reverseColor?: boolean
    text: string
    to?: string
}

// main
export const Button: React.FC<ButtonProps> = React.memo(({
    disabled,
    href,
    onClick,
    reverseColor,
    text,
    to,
}) => {

    if (to) {
        return (
            <div className={`mb-button-web${disabled ? ' disabled' : ''}${reverseColor ? ' reverse' : ''}`}>
                <Link
                    className='bw-button'
                    onClick={onClick}
                    to={to}
                >
                    {text}
                </Link>
            </div>
        )
    }
    if (href) {
        return (
            <div className={`mb-button-web${disabled ? ' disabled' : ''}${reverseColor ? ' reverse' : ''}`}>
                <a
                    className='bw-button'
                    href={href}
                    onClick={onClick}
                    rel='noopener noreferrer'
                    target='_blank'
                >
                    {text}
                </a>
            </div>
        )
    }
    return (
        <div className={`mb-button-web${disabled ? ' disabled' : ''}${reverseColor ? ' reverse' : ''}`}>
            <span
                className='bw-button'
                onClick={onClick}
            >
                {text}
            </span>
        </div>
    )
})
