// data
import {
    api_url_property_form_create,
    api_url_property_form_detail,
    api_url_property_form_publish,
    api_url_property_form_update,
    reduxAuthState,
} from 'data'

// serializers
import {
    PropertyDetailFormSerializer,
    PropertyDetailFormSerializerError,
} from 'serializers'

// services
import {
    getApiUrl,
} from 'services'

// props
export type PropertyLocationFormStateProps = {
    errors: PropertyDetailFormSerializerError | undefined
    fields: PropertyDetailFormSerializer | undefined
}

// main
export function PropertyLocationFormState(reduxAuth: reduxAuthState) {
    return {
        // Urls
        activeUrl: getApiUrl(api_url_property_form_publish, reduxAuth),
        createUrl: getApiUrl(api_url_property_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_property_form_detail, reduxAuth),
        updateUrl: getApiUrl(api_url_property_form_update, reduxAuth),

        // Fields
        errors: {},
        fields: undefined,

        // Required fields
        requiredAttribute: [],
        requiredForeignKey: [
            'transaction',
            'user',
        ],
        requiredManyToMany: [
            'city_temp',
            'transport_means',
        ],

        // Form fields
        formAttribute: [],
        formFile: [],
        formForeignKey: [
            'transaction',
            'user',
        ],
        formJson: [
            'city_temp',
        ],
        formManyToMany: [
            'transport_means',
        ],
    }
}
