// Root Url
const rootPath = 'v1/userprofile/form/'

// Profile Form API
export const api_url_profile_form_create = `${rootPath}profile/create/`
export const api_url_profile_form_detail = `${rootPath}profile/detail/`
export const api_url_profile_form_update = `${rootPath}profile/update/`
export const api_url_user_form_update = `${rootPath}user/update/`

// Settings Form API
export const api_url_settings_last_login_form_update = `${rootPath}settings/last_login/`
export const api_url_settings_mail_notification_form_update = `${rootPath}settings/mail_notification/`
export const api_url_settings_newsletter_form_update = `${rootPath}settings/newsletter/`

// Welcome mail
export const api_url_mail_welcome = 'v1/main/mail/auth_send_welcome_mail/'
