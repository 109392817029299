// react components
import React from 'react'
import {
    IonButton,
} from '@ionic/react'

// props
type IconBlockProps = {
    className?: string
    disabled?: boolean
    iconClass: string
    onClick?: () => void
}

// main
export const IconBlock: React.FC<IconBlockProps> = React.memo(({
    className,
    disabled,
    iconClass,
    onClick,
}) => {

    return (
        <IonButton
            className='icon-block-web'
            color='primary'
            disabled={disabled}
            onClick={onClick}
            size='small'
        >
            <span className={`ibw-icon ${iconClass} ${className}`} />
        </IonButton>
    )
})
