// react components
import React from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Footer,
    ImageHelper,
    SeoBlock,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    PropertyListFiltered,
} from 'pages'

// main
export const CuisinePartageePage: React.FC = () => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <IonPage className='main-page-web navbar-pt'>
            <SeoBlock pageId={30} />
            <IonContent>
                <div className='mpw-content mb-container'>
                    <div className='mpw-title-text-block'>
                        <h1 className='mb-main-title'>{reduxText[944]}</h1>
                        <p className='mb-main-text'>{reduxText[945]}</p>
                    </div>
                    <div className='property-section'>
                        <div className='mpw-title-text-block'>
                            <h2 className='mb-main-title'>{reduxText[946]}</h2>
                        </div>
                        <PropertyListFiltered activity={4} />
                    </div>
                    <div className='mpw-image-text-block'>
                        <div className='mpw-image-wrap'>
                            <ImageHelper
                                alt=''
                                className='mpw-image'
                                dominant_color={reduxText[95700]}
                                src={reduxText[957]}
                            />
                        </div>
                        <div className='mpw-text-wrap'>
                            <h2 className='mb-main-title split'>{reduxText[947]}</h2>
                            <p className='mb-main-text'>{reduxText[948]}</p>
                        </div>
                    </div>
                    <div className='mpw-title-text-block'>
                        <h2 className='mb-main-title'>{reduxText[949]}</h2>
                        <p className='mb-main-text'>{reduxText[950]}</p>
                    </div>
                    <div className='mpw-title-text-block'>
                        <h2 className='mb-main-title'>{reduxText[951]}</h2>
                        <p className='mb-main-text'>{reduxText[952]}</p>
                    </div>
                    <div className='mpw-title-text-block'>
                        <h2 className='mb-main-title'>{reduxText[953]}</h2>
                        <p className='mb-main-text'>{reduxText[954]}</p>
                    </div>
                    <div className='mpw-image-text-block'>
                        <div className='mpw-image-wrap'>
                            <ImageHelper
                                alt=''
                                className='mpw-image'
                                dominant_color={reduxText[95800]}
                                src={reduxText[958]}
                            />
                        </div>
                        <div className='mpw-text-wrap'>
                            <h2 className='mb-main-title split'>{reduxText[955]}</h2>
                            <p className='mb-main-text'>{reduxText[956]}</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </IonContent>
        </IonPage>
    )
}
