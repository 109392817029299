// react components
import React, {
    useEffect,
} from 'react'
import ReactGA from 'react-ga'
import {
    useLocation,
} from 'react-router-dom'

// main
export const GABlock: React.FC = () => {

    const location = useLocation()

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            ReactGA.pageview(window.location.pathname + window.location.search)
        }
    }, [
        location,
    ])

    return null
}
