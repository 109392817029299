// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import AsyncSelect from 'react-select/async'

// components
import {
    ErrorInput,
    MainFormLabel,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    axiosErrorHandler,
    ecSlugify,
    getApiUrl,
} from 'services'

// props
type SelectAsyncInputProps = {
    apiUrl: string
    clearable: boolean
    disabled?: boolean
    error: string | undefined
    helperText?: string
    label?: string
    multi?: boolean
    name: string
    onChange: React.Dispatch<any>
    optional?: boolean
    placeholder?: string
    staffOnly?: boolean
    team?: boolean
    value: any
}

// main
export const SelectAsyncInput: React.FC<SelectAsyncInputProps> = React.memo(({
    apiUrl,
    clearable,
    disabled,
    error,
    helperText,
    label,
    multi,
    name,
    onChange,
    optional,
    placeholder,
    staffOnly,
    team,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    useEffect(() => {
        setValue(value || (multi ? [] : ''))
    }, [value])

    const [newValue, setValue] = useState<any>(value || (multi ? [] : ''))

    function getOptions(inputValue: string) {
        const getUrl = `${getApiUrl(apiUrl, reduxAuth)}?search=${ecSlugify(inputValue)}`
        if (process.env.NODE_ENV === 'development') console.log(getUrl)
        return fetch(getUrl, reduxAuth.axiosConfig)
            .then((response) => response.json())
            .then((json) => {
                return json.results
            })
            .catch((error) => {
                axiosErrorHandler({
                    apiUrl,
                    component: 'SelectAsyncInput',
                    dispatch,
                    error,
                    reduxAuth,
                    reference: 'getOptions',
                })
            })
    }

    const promiseOptions = (inputValue: string) =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(getOptions(inputValue))
            }, 1000)
        })

    function onInputChange(value: any) {
        try {
            if (value === newValue) return
            setValue(value)
            onChange({
                name: name,
                value: value,
            })
            if (team && value.isNew) {
                onChange({
                    name: 'name',
                    value: value.name,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SelectAsyncInput',
                'onInputChange',
            ))
        }
    }

    const lightCustomStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            boxShadow: 0,
            borderColor: state.isFocused
                ? '#ed6363'
                : provided.borderColor,
            ':hover': {
                borderColor: state.isFocused
                    ? '#ed6363'
                    : provided.borderColor,
            }
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            zIndex: 9999,
        }),
        menuList: (provided: any, state: any) => ({
            ...provided,
            zIndex: 9999,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            background: state.isSelected ? '#ed6363' : '',
            color: state.isSelected ? 'white' : '',
            ':hover': {
                ...provided[':hover'],
                cursor: 'pointer',
            },
        }),
    }

    return (
        <div className={`select-input-web ${disabled ? 'disabled' : ''}`}>
            {label && (
                <MainFormLabel
                    helperText={helperText}
                    label={label}
                    name={name}
                    optional={optional}
                    staffOnly={staffOnly}
                />
            )}
            <div className='form-input-wrap'>
                <div className='fiw-input'>
                    <AsyncSelect
                        cacheOptions
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        isClearable={clearable}
                        isDisabled={disabled}
                        isMulti={multi}
                        menuPlacement='auto'
                        //@ts-ignore
                        loadOptions={promiseOptions}
                        onChange={onInputChange}
                        placeholder={placeholder}
                        styles={lightCustomStyles}
                        value={newValue}
                    />
                </div>
                {helperText && (
                    <div className='fiw-helper'>
                        <p className='pre-line'>{helperText}</p>
                    </div>
                )}
            </div>
            <ErrorInput error={error} />
        </div>
    )
})
