// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    Link,
} from 'react-router-dom'

// components
import {
    BookmarkButton,
    ImageHelper,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    PropertyListBlockSerializer,
} from 'serializers'

// props
type PropertyListSmallBlockProps = {
    object: PropertyListBlockSerializer
}

// main
export const PropertyListSmallBlock: React.FC<PropertyListSmallBlockProps> = React.memo(({
    object,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <div className='property-list-small-block-web'>
            <Link
                className='plsbw-image-wrap'
                to={object.get_absolute_url}
            >
                <div className='plsbw-ratio-helper'>
                    <div
                        className='plsbw-rhw-image-wrap'
                    >
                        <ImageHelper
                            alt={object.title}
                            className='plsbw-rhw-image'
                            dominant_color={object.get_dominant_color}
                            src={object.get_image_lq}
                        />
                    </div>
                </div>
                <ImageHelper
                    alt={object.title}
                    className='plsbw-image'
                    dominant_color={object.get_dominant_color}
                    src={object.get_image_lq}
                />
                <p className={`plsbw-transaction transaction-${object.transaction}`}>{reduxText[object.transaction === 1 ? 714 : 715]}</p>
            </Link>
            <div className='plsbw-info'>
                <div className='plsbw-name-wrap'>
                    <Link to={object.get_absolute_url}>
                        <p className='plsbw-name'>{object.title}</p>
                    </Link>
                    <p className='plsbw-price'>{object.get_price_first}{' € '}{object.get_price_type}</p>
                    <p className='plsbw-area'>{object.get_area_total} m<sup>2</sup></p>
                    <p className='plsbw-city'>{object.city?.name}</p>
                </div>
                <div className='plsbw-bookmark'>
                    <BookmarkButton
                        app='catalog'
                        model='property'
                        objectId={object.id}
                    />
                </div>
            </div>
        </div>
    )
})
