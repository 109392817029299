// react components
import React from 'react'
import {
    IonAlert,
    IonPage,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonFooter,
} from '@ionic/react'

// components
import {
    ImageHelper,
} from 'components'

// data
import {
    MainReduxContainer,
    reduxAuthState,
    reduxModalState,
    view_url_main_home,
} from 'data'

// services
import {
    axiosErrorHandlerErrorBoundary,
} from 'services'

// props
type ErrorBoundaryProps = {
    children: any
    reduxAuth: reduxAuthState
    reduxModal: reduxModalState
}

type ErrorBoundaryState = {
    errorInfo: any
}

// main
class ErrorBoundaryToConnect extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {

    componentDidCatch(error: any, errorInfo: any) {

        const reduxAuth = this.props.reduxAuth
        if (process.env.NODE_ENV === 'development') console.log(error, errorInfo)
        // Catch errors in any components below and re-render with error message
        this.setState({
            errorInfo: errorInfo,
        })
        // You can also log error messages to an error reporting service here
        axiosErrorHandlerErrorBoundary(
            `${error.toString()} - ${errorInfo.componentStack}`,
            reduxAuth,
            'ErrorBoundaryToConnect',
            '',
        )
    }

    render() {
        if (this.state && this.state.errorInfo) {
            return (
                <IonPage className='mb-error-boundary-web navbar-pt'>
                    <IonHeader className='mb-header-web'>
                        <IonToolbar>
                            <IonTitle>
                                <ImageHelper
                                    alt='Mylab'
                                    className='mb-logo auto'
                                    dominant_color={undefined}
                                    src='https://media.mylab.immo/global/main-logo.svg?v1'
                                />
                            </IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <a href={view_url_main_home} className='eb-go-home'>Go back to home page</a>
                    <IonAlert
                        buttons={['OK']}
                        header='Oops! Something went wrong'
                        isOpen
                        message="<div><p>If you keep seeing this error, try:</p><p>* Refresh the page.</p><p>* If refreshing the page doesn' help, log out and log in again.</p><p>* Still no luck? Contact our support team.</p></div>"
                        onDidDismiss={() => process.env.NODE_ENV === 'development' && console.log('closing ErrorBoundary')}
                        subHeader='What to do ?'
                    />
                    <IonFooter className='mb-footer-web' />
                </IonPage>
            )
        }
        return (this.props.children)
    }
}

export const ErrorBoundary = MainReduxContainer(ErrorBoundaryToConnect)
