// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    ItemReorderEventDetail,
} from '@ionic/core'
import {
    IonAlert,
    IonButtons,
    IonItem,
    IonLabel,
    IonReorder,
    IonReorderGroup,
} from '@ionic/react'
import {
    arrayMoveImmutable,
} from 'array-move'
import axios, {
    AxiosRequestConfig,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    IconBlock,
    MainFormLabel,
    SelectListInput,
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    select_url_component_type,
    select_url_designation,
} from 'data'

// pages
import {
    ComponentFormState,
    ComponentFormStateProps,
} from 'pages'

// serializers
import {
    ComponentListFormSerializer,
    FormInlineStateProps,
} from 'serializers'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getAxiosHeaders,
    getFormData,
    getInputValue,
    getMainErrorMessage,
} from 'services'

// props
type MatchParams = {
    id: string
}

// main
export const ComponentInput: React.FC = () => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState<boolean>(false)
    const [items, setItems] = useState<ComponentListFormSerializer[]>([])
    const [mainError, setMainError] = useState<string>('')
    const [mother, setMother] = useState<FormInlineStateProps & ComponentFormStateProps>(ComponentFormState(reduxAuth))
    const [object, setObject] = useState<any>(undefined)

    const defaultFields: ComponentFormStateProps['fields'] = {}
    defaultFields.property = {
        id: Number(params.id),
        name: params.id,
    }

    useEffect(() => {
        setMother({
            ...mother,
            fields: defaultFields,
        })
        getListData()
    }, [
        params.id,
        reduxAuth.settings?.id,
    ])

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        try {
            onSortEnd(event)
            event.detail.complete()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ComponentInput',
                'doReorder',
            ))
        }
    }

    function getListData() {
        try {
            const getUrl = `${mother.listUrl}${params.id}/`
            axios({
                headers: getAxiosHeaders(reduxAuth, dispatch, 'ComponentInput'),
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setItems(response.data)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'ComponentInput',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getListData',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ComponentInput',
                'getListData',
            ))
        }
    }

    function handleInlineDelete() {
        try {

            if (!object) return
            const deleteUrl = `${mother.updateUrl}${object.id}/`
            axios({
                headers: getAxiosHeaders(reduxAuth, dispatch, 'ComponentInput'),
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    getListData()
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'ComponentInput',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleInlineDelete',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ComponentInput',
                'handleInlineDelete',
            ))
        }
    }

    function handleInlineRetrieve(clickedObject: any) {
        try {
            console.log(clickedObject);
            setObject(clickedObject)
            if (!clickedObject) return
            const getUrl = `${mother.detailInlineUrl}${clickedObject.id}/`
            axios({
                headers: getAxiosHeaders(reduxAuth, dispatch, 'ComponentInput'),
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setMother({
                        ...mother,
                        fields: response.data,
                    })
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'ComponentInput',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleInlineRetrieve',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ComponentInput',
                'handleInlineRetrieve',
            ))
        }
    }

    function handleInputChange(event: any) {
        try {
            setMother(getInputValue(event, mother))
            setMainError('')
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainPropertyPanel',
                'handleInputChange',
            ))
        }
    }

    function handleRequired() {
        try {
            const result = checkRequiredFields(mother, reduxText)
            if (result === 'ok') {
                handleSubmit()
            } else {
                setMainError(reduxText[506])
                setMother({
                    ...mother,
                    errors: result,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainPropertyPanel',
                'handleRequired',
            ))
        }
    }

    function handleSubmit() {
        try {
            let axiosMethod: AxiosRequestConfig['method'] = 'post'
            let axiosUrl = mother.createUrl
            if (mother.fields?.id) {
                axiosMethod = 'put'
                axiosUrl = `${mother.updateUrl}${mother.fields.id}/`
            }
            const formData: any = getFormData(mother)
            axios({
                data: formData,
                headers: getAxiosHeaders(reduxAuth, dispatch, 'ComponentInput'),
                method: axiosMethod,
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setMainError('')
                    setMother({
                        ...mother,
                        errors: {},
                        fields: defaultFields,
                    })
                    getListData()
                })
                .catch((error) => {
                    setMainError(error.response?.data?.non_field_errors || '')
                    setMother({
                        ...mother,
                        errors: getMainErrorMessage(error, reduxText),
                    })
                    if (!error.response?.data?.non_field_errors) {
                        axiosErrorHandler({
                            apiUrl: axiosUrl,
                            component: 'MainPropertyPanel',
                            dispatch,
                            error,
                            formFields: JSON.stringify(mother.fields),
                            reduxAuth,
                            reference: 'handleSubmit',
                        })
                    }
                })
        } catch (error) {
            if (process.env.NODE_ENV === 'development') console.log(error)
            dispatch(reduxModalErrorEventHandler(
                error,
                'MainPropertyPanel',
                'handleSubmit',
            ))
        }
    }

    function onSortEnd(event: any) {
        try {
            const item = items[event.detail.from]
            setItems(arrayMoveImmutable(items, event.detail.from, event.detail.to))
            const formData = new FormData()
            formData.append('position', event.detail.to)
            formData.append('property', params.id)
            const putUrl = `${mother.patchUrl}${item.id}/`
            axios({
                data: formData,
                headers: getAxiosHeaders(reduxAuth, dispatch, 'ComponentInput'),
                method: 'put',
                url: putUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: putUrl,
                        component: 'ComponentInput',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onSortEnd',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ComponentInput',
                'onSortEnd',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    // Extra
    const deleteAlertButtons = [
        {
            role: 'destructive',
            text: reduxText[615],
            handler: () => {
                handleInlineDelete()
            }
        },
        {
            text: reduxText[616],
            role: 'cancel',
        },
    ]

    return (
        <div className='component-input-web'>
            <MainFormLabel
                helperText={reduxText[729]}
                label={reduxText[598]}
                name='component'
            />
            {fieldsMother && (
                <React.Fragment>
                    <div className='ciw-input-wrap'>
                        <div className='ciw-input'>
                            <SelectListInput
                                apiUrl={select_url_component_type}
                                clearable={false}
                                error={errorsMother?.type}
                                name='type'
                                onChange={handleInputChange}
                                placeholder={reduxText[599]}
                                value={fieldsMother.type || ''}
                            />
                            <SelectListInput
                                apiUrl={select_url_designation}
                                clearable={false}
                                error={errorsMother?.designation}
                                name='designation'
                                onChange={handleInputChange}
                                placeholder={reduxText[600]}
                                value={fieldsMother.designation || ''}
                            />
                            <TextInput
                                error={errorsMother?.area}
                                inputmode='decimal'
                                name='area'
                                onChange={handleInputChange}
                                placeholder={reduxText[601]}
                                type='number'
                                value={fieldsMother.area || ''}
                            />
                        </div>
                        <div className='ciw-helper'>
                            <p className='pre-line'>{reduxText[729]}</p>
                        </div>
                    </div>
                    <div className='ciw-input-action'>
                        <Button
                            onClick={() => handleRequired()}
                            text={reduxText[660]}
                        />
                    </div>
                </React.Fragment>
            )}
            <div className='ciw-reorder-wrap'>
                <IonReorderGroup
                    disabled={false}
                    onIonItemReorder={doReorder}
                >
                    {items.map((val) => {
                        return (
                            <IonItem
                                key={val.id}
                                detail={false}
                            >
                                <IonLabel>
                                    {val.type?.name}{' - '}{val.designation?.name}{' - '}{val.area}{' '}m<sup>2</sup>
                                </IonLabel>
                                <IonButtons slot='end'>
                                    <IconBlock
                                        iconClass='fa fa-trash'
                                        onClick={() => {
                                            setObject(val)
                                            setIsDeleteAlertOpen(true)
                                        }}
                                    />
                                    <IconBlock
                                        iconClass='fa fa-edit'
                                        onClick={() => handleInlineRetrieve(val)}
                                    />
                                    <IonReorder slot='end' />
                                </IonButtons>
                            </IonItem>
                        )
                    })}
                </IonReorderGroup>
            </div>
            <IonAlert
                buttons={[reduxText[510]]}
                header={reduxText[511]}
                isOpen={Boolean(mainError)}
                message={`${mainError}${mainError === 'Network Error' ? reduxText[581] : ''}`}
                onDidDismiss={() => setMainError('')}
            />
            <IonAlert
                buttons={deleteAlertButtons}
                header={reduxText[618]}
                isOpen={isDeleteAlertOpen}
                message={reduxText[619]}
                onDidDismiss={() => setIsDeleteAlertOpen(false)}
            />
        </div>
    )
}
