// data
import {
    api_url_contact_form_create,
    reduxAuthState,
} from 'data'

// serializers
import {
    ContactDetailFormSerializer,
    ContactDetailFormSerializerError,
} from 'serializers'

// services
import {
    getApiUrl,
} from 'services'

// props
export type ContactMotherFormStateProps = {
    errors: ContactDetailFormSerializerError | undefined
    fields: ContactDetailFormSerializer
}

// main
export function ContactMotherFormState(reduxAuth: reduxAuthState) {
    return {
        // Urls
        createUrl: getApiUrl(api_url_contact_form_create, reduxAuth),
        detailUrl: '',
        updateUrl: '',

        // Fields
        errors: {},
        fields: {},

        // Required fields
        requiredAttribute: [
            'first_name',
            'last_name',
            'mail',
            'message',
        ],
        requiredForeignKey: [
            'subject_new',
        ],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'company',
            'first_name',
            'last_name',
            'mail',
            'message',
            'phone',
        ],
        formFile: [],
        formForeignKey: [
            'subject_new',
        ],
        formJson: [],
        formManyToMany: [],
    }
}
