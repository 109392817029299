// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonAlert,
} from '@ionic/react'
import axios, {
    AxiosRequestConfig,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Redirect,
} from 'react-router-dom'

// components
import {
    Button,
    ImageCropInput,
    Loader,
    PhoneNumberInput,
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
    api_url_user_form_update,
    reduxAuthFetchSettings,
    reduxFormSetIsSuccessToastOpen,
    reduxModalErrorEventHandler,
    view_url_auth_login,
} from 'data'

// pages
import {
    ProfileMotherFormState,
    ProfileMotherFormStateProps,
} from 'pages'

// serializers
import {
    FormMotherStateProps,
} from 'serializers'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getDetailData,
    getFormData,
    getInputValue,
    getMainErrorMessage,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// main
export const ProfileForm: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [mainError, setMainError] = useState<string>('')
    const [mother, setMother] = useState<FormMotherStateProps & ProfileMotherFormStateProps>(ProfileMotherFormState(reduxAuth))

    useEffect(() => {
        getDetailData(
            mother,
            reduxAuth.settings?.id,
            reduxAuth,
            setMother,
            setIsLoading,
            dispatch,
            'ProfileForm',
        )
    }, [reduxAuth.settings?.id])

    function handleInputChange(event: any) {
        try {
            setMother(getInputValue(event, mother))
            setButtonDisabled(false)
            setMainError('')
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileForm',
                'handleInputChange',
            ))
        }
    }

    function handleRequired() {
        try {
            const result = checkRequiredFields(mother, reduxText)
            if (result === 'ok') {
                handleSubmit()
            } else {
                setMainError(reduxText[506])
                setMother({
                    ...mother,
                    errors: result,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileForm',
                'handleRequired',
            ))
        }
    }

    function handleSubmit() {
        try {
            setButtonDisabled(true)
            setIsSaving(true)

            let axiosMethod: AxiosRequestConfig['method'] = 'post'
            let axiosUrl = mother.createUrl
            if (mother.fields?.id) {
                axiosMethod = 'put'
                axiosUrl = `${mother.updateUrl}${mother.fields.id}/`
            }
            const formData: any = getFormData(mother)
            axios({
                data: formData,
                headers: getAxiosHeaders(reduxAuth, dispatch, 'ProfileForm'),
                method: axiosMethod,
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)

                    axios({
                        data: formData,
                        headers: getAxiosHeaders(reduxAuth, dispatch, 'ProfileForm'),
                        method: axiosMethod,
                        url: getApiUrl(`${api_url_user_form_update}${reduxAuth.settings?.id}/`, reduxAuth),
                    })
                        .then((response2) => {
                            if (process.env.NODE_ENV === 'development') console.log(response2)
                            dispatch(reduxFormSetIsSuccessToastOpen(true))
                            setButtonDisabled(true)
                            setIsSaving(false)
                            setMainError('')
                            setMother({
                                ...mother,
                                errors: {},
                            })
                            // @ts-ignore
                            dispatch(reduxAuthFetchSettings(reduxAuth, dispatch))
                        })
                        .catch((error2) => {
                            setButtonDisabled(false)
                            setIsSaving(false)
                            setMainError(error2.response.data?.non_field_errors || '')
                            setMother({
                                ...mother,
                                errors: getMainErrorMessage(error2, reduxText),
                            })
                            if (!error2.response.data?.non_field_errors) {
                                axiosErrorHandler({
                                    apiUrl: axiosUrl,
                                    component: 'ProfileForm',
                                    dispatch,
                                    error: error2,
                                    formFields: JSON.stringify(mother.fields),
                                    reduxAuth,
                                    reference: 'handleSubmit',
                                })
                            }
                        })
                })
                .catch((error) => {
                    setButtonDisabled(false)
                    setIsSaving(false)
                    setMainError(error.response?.data?.non_field_errors || '')
                    setMother({
                        ...mother,
                        errors: getMainErrorMessage(error, reduxText),
                    })
                    if (!error.response?.data?.non_field_errors) {
                        axiosErrorHandler({
                            apiUrl: axiosUrl,
                            component: 'ProfileForm',
                            dispatch,
                            error,
                            formFields: JSON.stringify(mother.fields),
                            reduxAuth,
                            reference: 'handleSubmit',
                        })
                    }
                })
        } catch (error) {
            if (process.env.NODE_ENV === 'development') console.log(error)
            setButtonDisabled(false)
            setIsSaving(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileForm',
                'handleSubmit',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    if (!reduxAuth.authenticated) {
        return <Redirect to={view_url_auth_login} />
    }
    return (
        <div className='profile-form-web'>
            {fieldsMother && (
                <React.Fragment>
                    <ImageCropInput
                        doka_output_data={fieldsMother.image_doka_output_data || ''}
                        error={errorsMother?.image}
                        name='image'
                        onChange={handleInputChange}
                        original={fieldsMother.image_original || ''}
                        value={fieldsMother.image || ''}
                    />
                    <div className='prfw-input-wrap'>
                        <TextInput
                            error={errorsMother?.first_name}
                            label={reduxText[637]}
                            name='first_name'
                            onChange={handleInputChange}
                            value={fieldsMother.first_name || ''}
                        />
                        <TextInput
                            error={errorsMother?.last_name}
                            label={reduxText[668]}
                            name='last_name'
                            onChange={handleInputChange}
                            optional
                            value={fieldsMother.last_name || ''}
                        />
                    </div>
                    <div className='prfw-input-wrap'>
                        <TextInput
                            disabled
                            error={errorsMother?.email}
                            name='email'
                            onChange={handleInputChange}
                            label={reduxText[479]}
                            value={fieldsMother.email || ''}
                        />
                        <PhoneNumberInput
                            error={errorsMother?.phone_number}
                            label={reduxText[640]}
                            name='phone_number'
                            onChange={handleInputChange}
                            optional
                            value={fieldsMother.phone_number || ''}
                        />
                    </div>
                    <div className='prfw-input-wrap'>
                        <TextInput
                            error={errorsMother?.company}
                            label={reduxText[639]}
                            name='company'
                            onChange={handleInputChange}
                            optional
                            value={fieldsMother.company || ''}
                        />
                        <TextInput
                            error={errorsMother?.website}
                            label={reduxText[669]}
                            name='website'
                            onChange={handleInputChange}
                            optional
                            value={fieldsMother.website || ''}
                        />
                    </div>
                </React.Fragment>
            )}
            <div className='prfw-wrap'>
                <Button
                    disabled={buttonDisabled}
                    onClick={() => handleRequired()}
                    text={reduxText[507]}
                />
            </div>
            <Loader isOpen={isLoading} />
            {isSaving && (
                <Loader
                    isOpen
                    message={reduxText[514]}
                />
            )}
            <IonAlert
                buttons={[reduxText[510]]}
                header={reduxText[662]}
                isOpen={Boolean(mainError)}
                message={`${mainError}${mainError === 'Network Error' ? reduxText[509] : ''}`}
                onDidDismiss={() => setMainError('')}
            />
        </div>
    )
}
