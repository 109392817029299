// react components
import React from 'react'
import {
    IonAlert,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

//data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalErrorShowHide,
} from 'data'

// main
export const ErrorAlert: React.FC = () => {

    const dispatch = useDispatch()
    const reduxModal = useSelector((state: defaultReduxState) => state.reduxModal)

    function onAlertDismiss() {
        try {
            dispatch(reduxModalErrorShowHide(false))
        } catch (error) {
            dispatch(reduxModalErrorShowHide(false))
            dispatch(reduxModalErrorEventHandler(
                error,
                'ErrorAlert',
                'onAlertDismiss',
            ))
        }
    }

    if (reduxModal.error.isOpen) {
        return (
            <IonAlert
                buttons={['OK']}
                header={reduxModal.error.header}
                isOpen
                message="<div><p>If you keep seeing this error, try:</p><p>* Refresh the page.</p><p>* If refreshing the page doesn' help, log out and log in again.</p><p>* Still no luck? Contact our support team.</p></div>"
                onDidDismiss={onAlertDismiss}
                subHeader={reduxModal.error.subHeader}
            />
        )
    }
    return null
}
