// react components
import React, {
    useState,
} from 'react'
import {
    IonPopover,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'

// props
type MainFormLabelProps = {
    helperText?: string
    label: string
    name: string
    optional?: boolean
    staffOnly?: boolean
}

// main
export const MainFormLabel: React.FC<MainFormLabelProps> = React.memo(({
    helperText,
    label,
    name,
    optional,
    staffOnly,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [popoverIsOpen, setPopoverIsOpen] = useState<boolean>(false)

    return (
        <div className='main-form-label'>
            <label
                className='main-form-label-wrap'
                htmlFor={name}
            >
                <span>{label}</span>
            </label>
            <div className='main-form-helper-wrap'>
                {optional && (
                    <span className='main-form-label-optional'>
                        {' '}
                        {reduxText[608]}
                    </span>
                )}
                {staffOnly && (
                    <span className='main-form-label-optional'>
                        {' '}
                        {reduxText[609]}
                    </span>
                )}
                {helperText && (
                    <span
                        className='mflw-helper fa fa-question'
                        onClick={() => setPopoverIsOpen(true)}
                    />
                )}
                <IonPopover
                    // @ts-ignore
                    cssClass='main-form-label-popover-web'
                    isOpen={popoverIsOpen}
                    mode='ios'
                    onDidDismiss={() => setPopoverIsOpen(false)}
                >
                    <div className='popover-content'>
                        <div className='mflpw-header'>
                            <p className='mflpw-title'>{label}</p>
                            {optional && <p className='mflpw-optional'>{reduxText[608]}</p>}
                            {staffOnly && <p className='mflpw-staff'>{reduxText[609]}</p>}
                        </div>
                        <p className='mflpw-text'>{helperText}</p>
                    </div>
                </IonPopover>
            </div>
        </div>
    )
})
