// react components
import React from 'react'
import {
    Redirect,
} from 'react-router'

// data
import {
    view_url_property_list,
} from 'data'

// main
export const PropertyListOld: React.FC = () => {

    return <Redirect to={view_url_property_list} />
}
