// react components
import axios from 'axios'

// data
import {
    api_url_mail_welcome,
    reduxAuthState,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
} from 'services'


export function sendWelcomeMail(userId: number, reduxAuth: reduxAuthState, dispatch: any) {
    const getUrl = getApiUrl(`${api_url_mail_welcome}${userId}/`, reduxAuth)
    axios({
        method: 'get',
        url: getUrl,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
        })
        .catch((error) => {
            axiosErrorHandler({
                apiUrl: getUrl,
                component: 'sendWelcomeMail',
                dispatch,
                error,
                reduxAuth,
                reference: 'sendWelcomeMail',
            })
        })
}
