// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
    useIonViewWillLeave,
} from '@ionic/react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Footer,
    Loader,
    SeoBlock,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    ArticleDetailBlock,
    NotFoundPage,
    PropertyDetailBlock,
} from 'pages'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

// props
type MatchParams = {
    id: string
}

type DetailPageContainerProps = {
    apiUrl: string
    component: string
}

// main
export const DetailPageContainer: React.FC<DetailPageContainerProps> = React.memo(({
    apiUrl,
    component,
}) => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)

    useEffect(() => {
        if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
        getData(params.id)
    }, [
        params.id,
        reduxAuth.settings?.id,
    ])

    useEffect(() => {
        if (reduxForm.refresh?.refreshDetail) {
            if (process.env.NODE_ENV === 'development') console.log(reduxForm.refresh?.refreshDetail)
            if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
            getData(params.id)
        }
    }, [reduxForm.refresh?.refreshDetail])

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [errorStatus, setErrorStatus] = useState<number>()
    const [object, setObject] = useState<any>('')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const components: any = {
        ArticleDetailBlock,
        PropertyDetailBlock,
    }
    const ComponentName = components[component]

    useIonViewWillLeave(() => {
        if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
    })

    const contentRef = useRef(null)
    const scrollToTop = () => {
        try {
            // @ts-ignore
            if (contentRef.current && contentRef.current.scrollToTop) contentRef.current.scrollToTop()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPageContainer',
                'scrollToTop',
            ))
        }
    }

    function getData(objectId: string, refresh?: any) {
        try {
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            setAxiosCancelToken(source)
            if (!objectId) return
            setIsLoading(true)
            setErrorStatus(undefined)
            const getUrl = `${apiUrl}${objectId}/` // Keep the last '/' for Safari !!!!!!!
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: getAxiosHeaders(reduxAuth, dispatch, 'DetailPageContainer'),
                method: 'get',
                url: getUrl,
                cancelToken: source.token,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    scrollToTop()
                    setIsLoading(false)
                    if (refresh) {
                        refresh.detail.complete()
                    }
                    setObject(response.data)
                })
                .catch((error) => {
                    setIsLoading(false)
                    setErrorStatus(error.response?.status)
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'DetailPageContainer',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                        skipAlertStatusCode: [404],
                        skipSendErrorStatusCode: [404],
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'DetailPageContainer',
                'getData',
            ))
        }
    }

    if (errorStatus === 404) return <NotFoundPage />

    return (
        <IonPage className='detail-page-container navbar-pt'>
            <SeoBlock data={object.seo_data} />
            <IonContent ref={contentRef}>
                <div className='dpc-content'>
                    {object && (
                        <ComponentName
                            object={object}
                        />
                    )}
                </div>
                <Footer />
                <Loader isOpen={isLoading} />
            </IonContent>
        </IonPage>
    )
})
