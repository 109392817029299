// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    IconBlock,
} from 'components'

// data
import {
    defaultReduxState,
    reduxAuthSetAction,
    reduxModalErrorEventHandler,
    reduxModalLoginShowHide,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type BookmarkButtonProps = {
    app: string
    model: string
    objectId: number
}

// main
export const BookmarkButton: React.FC<BookmarkButtonProps> = React.memo(({
    app,
    model,
    objectId,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const isChecked = objectId && reduxAuth.actions?.bookmark?.includes(objectId)

    function addRemoveBookmark() {
        try {
            if (!reduxAuth.authenticated || !reduxAuth.settings) {
                dispatch(reduxModalLoginShowHide(true))
            }
            else if (isChecked) {
                dispatch(reduxAuthSetAction(
                    'r',
                    'bookmark',
                    objectId,
                ))
                setIsLoading(true)
                const deleteUrl = getApiUrl(`v1/${app}/form/${model}_favourite/delete/${objectId}/`, reduxAuth)
                axios({
                    headers: getAxiosHeaders(reduxAuth, dispatch, 'BookmarkButton'),
                    method: 'delete',
                    url: deleteUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setIsLoading(false)
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        axiosErrorHandler({
                            apiUrl: deleteUrl,
                            component: 'BookmarkButton',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'addRemoveBookmark delete',
                        })
                    })
            }
            else {
                dispatch(reduxAuthSetAction(
                    'a',
                    'bookmark',
                    objectId,
                ))
                setIsLoading(true)
                const createUrl = getApiUrl(`v1/${app}/form/${model}_favourite/create/`, reduxAuth)
                const formData: any = new FormData()
                formData.append(model, objectId)
                formData.append('user', reduxAuth.settings.id)
                axios({
                    data: formData,
                    headers: getAxiosHeaders(reduxAuth, dispatch, 'BookmarkButton'),
                    method: 'post',
                    url: createUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setIsLoading(false)
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        axiosErrorHandler({
                            apiUrl: createUrl,
                            component: 'BookmarkButton',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'addRemoveBookmark post',
                        })
                    })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'BookmarkButton',
                'addRemoveBookmark',
            ))
        }
    }

    const iconClass = {
        checked: 'fa fa-star',
        unchecked: 'fa fa-star-o',
    }

    return (
        <IconBlock
            disabled={isLoading}
            iconClass={isChecked ? iconClass.checked : iconClass.unchecked}
            onClick={addRemoveBookmark}
        />
    )
})
