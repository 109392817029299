// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    IonAlert,
    IonContent,
    IonFooter,
} from '@ionic/react'
import axios, {
    AxiosRequestConfig,
} from 'axios'
import {
    useHistory,
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    Loader,
    LocationInput,
    SelectAsyncInput,
    SelectListInput,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormSetRefresh,
    reduxModalErrorEventHandler,
    select_url_transport_mean,
    select_url_user,
    view_url_property_form,
} from 'data'

// pages
import {
    PropertyLocationFormState,
    PropertyLocationFormStateProps,
} from 'pages'

// serializers
import {
    FormMotherStateProps,
} from 'serializers'

// services
import {
    axiosErrorHandler,
    checkRequiredFields,
    getAxiosHeaders,
    getDetailData,
    getFormData,
    getInputValue,
    getMainErrorMessage,
} from 'services'

// props
type MatchParams = {
    id: string
    transactionId: string
}

// main
export const LocationPropertyPanel: React.FC = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [mainError, setMainError] = useState<string>('')
    const [mother, setMother] = useState<FormMotherStateProps & PropertyLocationFormStateProps>(PropertyLocationFormState(reduxAuth))

    useEffect(() => {
        const defaultFields: PropertyLocationFormStateProps['fields'] = {}
        if (params.id === 'new' && reduxAuth.settings) {
            defaultFields.user = {
                id: reduxAuth.settings.id,
                name: reduxAuth.settings.username,
            }
            defaultFields.transaction = {
                id: Number(params.transactionId),
                name: params.transactionId,
            }
        }
        getDetailData(
            mother,
            params.id,
            reduxAuth,
            setMother,
            setIsLoading,
            dispatch,
            'LocationPropertyPanel',
            defaultFields,
        )
    }, [
        params.id,
        reduxAuth.settings?.id,
    ])

    function handleInputChange(event: any) {
        try {
            setMother(getInputValue(event, mother))
            setMainError('')
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LocationPropertyPanel',
                'handleInputChange',
            ))
        }
    }

    function handleRequired() {
        try {
            const result = checkRequiredFields(mother, reduxText)
            if (result === 'ok') {
                handleSubmit()
            } else {
                setMainError(reduxText[506])
                setMother({
                    ...mother,
                    errors: result,
                })
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LocationPropertyPanel',
                'handleRequired',
            ))
        }
    }

    function handleSubmit() {
        try {
            setIsSaving(true)

            let axiosMethod: AxiosRequestConfig['method'] = 'post'
            let axiosUrl = mother.createUrl
            if (mother.fields?.id) {
                axiosMethod = 'put'
                axiosUrl = `${mother.updateUrl}${mother.fields.id}/`
            }
            const formData: any = getFormData(mother)
            axios({
                data: formData,
                headers: getAxiosHeaders(reduxAuth, dispatch, 'LocationPropertyPanel'),
                method: axiosMethod,
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    history.replace(`${view_url_property_form}${response.data.id}/adresse/`)
                    history.push(`${view_url_property_form}${response.data.id}/info/`)
                    dispatch(reduxFormSetRefresh('refreshProfile'))
                    setIsSaving(false)
                    setMainError('')
                    setMother({
                        ...mother,
                        errors: {},
                        fields: {
                            ...mother.fields,
                            id: response.data.id,
                        },
                    })
                })
                .catch((error) => {
                    setIsSaving(false)
                    setMainError(error.response?.data?.non_field_errors || '')
                    setMother({
                        ...mother,
                        errors: getMainErrorMessage(error, reduxText),
                    })
                    if (!error.response?.data?.non_field_errors) {
                        axiosErrorHandler({
                            apiUrl: axiosUrl,
                            component: 'LocationPropertyPanel',
                            dispatch,
                            error,
                            formFields: JSON.stringify(mother.fields),
                            reduxAuth,
                            reference: 'handleSubmit',
                        })
                    }
                })
        } catch (error) {
            if (process.env.NODE_ENV === 'development') console.log(error)
            setIsSaving(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'LocationPropertyPanel',
                'handleSubmit',
            ))
        }
    }

    // Mother
    const errorsMother = mother.errors
    const fieldsMother = mother.fields

    return (
        <React.Fragment>
            <IonContent>
                <div className='pfw-content'>
                    <div className='pfw-panel'>
                        <div className='intro-property-panel-web'>
                            <div className='ippw-wrap'>
                                <h1 className='ippw-title'>{reduxText[573]}</h1>
                                <p className='ippw-title-helper'>{reduxText[732]}</p>
                            </div>
                            {fieldsMother && (
                                <React.Fragment>
                                    {reduxAuth.settings?.is_staff && (
                                        <SelectAsyncInput
                                            apiUrl={select_url_user}
                                            clearable={false}
                                            error={errorsMother?.user}
                                            helperText={reduxText[721]}
                                            label={reduxText[574]}
                                            name='user'
                                            onChange={handleInputChange}
                                            staffOnly
                                            value={fieldsMother.user || ''}
                                        />
                                    )}
                                    <LocationInput
                                        error={errorsMother?.city_temp}
                                        helperText={reduxText[722]}
                                        label={reduxText[575]}
                                        name='city_temp'
                                        onChange={handleInputChange}
                                        placeholer={reduxText[576]}
                                        value={fieldsMother.city_temp || ''}
                                    />
                                    <SelectListInput
                                        apiUrl={select_url_transport_mean}
                                        clearable={false}
                                        error={errorsMother?.transport_means}
                                        helperText={reduxText[723]}
                                        label={reduxText[577]}
                                        multi
                                        name='transport_means'
                                        onChange={handleInputChange}
                                        value={fieldsMother.transport_means || ''}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </IonContent>
            <IonFooter className='pfw-footer'>
                <div className='pfw-wrap'>
                    <span
                        className='pfw-back'
                        onClick={() => window.history.back()}
                    >
                        <i className='fa fa-chevron-left'></i>{reduxText[567]}
                    </span>
                    <Button
                        onClick={() => handleRequired()}
                        text={reduxText[578]}
                    />
                </div>
            </IonFooter>
            <Loader isOpen={isLoading} />
            {isSaving && (
                <Loader
                    isOpen
                    message={reduxText[579]}
                />
            )}
            <IonAlert
                buttons={[reduxText[510]]}
                header={reduxText[511]}
                isOpen={Boolean(mainError)}
                message={`${mainError}${mainError === 'Network Error' ? reduxText[581] : ''}`}
                onDidDismiss={() => setMainError('')}
            />
        </React.Fragment>
    )
}
