// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    IonAlert,
    IonItem,
    IonList,
    IonPopover,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ImageHelper,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    api_url_property_form_publish,
    api_url_property_form_delete,
    reduxModalErrorEventHandler,
    view_url_property_edit_form,
} from 'data'

// serializers
import {
    PropertyListBlockSerializer,
} from 'serializers'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type PropertyListMediumBlockProps = {
    object: PropertyListBlockSerializer
}

// main
export const PropertyListMediumBlock: React.FC<PropertyListMediumBlockProps> = React.memo(({
    object,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [showPublishActionSheet, setShowPublishActionSheet] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined })

    const [isPublished, setIsPublished] = useState<boolean>(object.published)
    const [isDeleted, setIsDeleted] = useState<boolean>(false)
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState<boolean>(false)

    function handleDelete() {
        try {
            setIsDeleting(true)
            const deleteUrl = getApiUrl(`${api_url_property_form_delete}${object.id}/`, reduxAuth)
            axios({
                headers: getAxiosHeaders(reduxAuth, dispatch, 'PropertyListMediumBlock'),
                method: 'put',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsDeleting(false)
                    setIsDeleted(true)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'PropertyListMediumBlock',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleDelete',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PropertyListMediumBlock',
                'handleDelete',
            ))
        }
    }

    function handlePublish(published?: boolean) {
        try {
            setShowPublishActionSheet({ open: false, event: undefined })
            const formData: any = new FormData()
            formData.append('published', !published)
            const publishedUrl = getApiUrl(`${api_url_property_form_publish}${object.id}/`, reduxAuth)
            axios({
                data: formData,
                headers: getAxiosHeaders(reduxAuth, dispatch, 'PropertyListMediumBlock'),
                method: 'put',
                url: publishedUrl,
            })
                .then((response) => {
                    // console.log(response)
                    setIsPublished(response.data.published)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: publishedUrl,
                        component: 'PropertyListMediumBlock',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handlePublish',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PropertyListMediumBlock',
                'handlePublish',
            ))
        }
    }

    const deleteAlertButtons = [
        {
            role: 'destructive',
            text: reduxText[657],
            handler: () => {
                handleDelete()
            }
        },
        {
            text: reduxText[505],
            role: 'cancel',
        },
    ]

    if (isDeleted) return null

    return (
        <div className='property-list-medium-block-web'>
            <div className='plmbw-image-wrap'>
                <ImageHelper
                    alt={object.title}
                    className='plmbw-image'
                    dominant_color={object.get_dominant_color}
                    src={object.get_image_lq}
                />
                <p className={`plmbw-transaction transaction-${object.transaction}`}>{reduxText[object.transaction === 1 ? 714 : 715]}</p>
            </div>
            <div className='plmbw-info'>
                <p className={`plmbw-status-text status-${object.status}`}>{object.status === 'v' ? reduxText[780] : reduxText[781]}</p>
                <p className='plmbw-name'>{object.title}</p>
                <p className='plmbw-price'>{object.get_price_first}{' € '}{object.get_price_type}</p>
                <p className='plmbw-area'>{object.get_area_total} m<sup>2</sup></p>
                <div className='plmbw-bookmark'>
                    <Button
                        text={reduxText[681]}
                        to={`${view_url_property_edit_form}${object.id}/`}
                    />
                    <Button
                        reverseColor
                        text={reduxText[682]}
                        onClick={() => setIsDeleteAlertOpen(true)}
                    />
                    <Button
                        text={isPublished ? reduxText[688] : reduxText[689]}
                        onClick={(e) => setShowPublishActionSheet({ open: true, event: e.nativeEvent })}
                    />
                    <IonPopover
                        event={showPublishActionSheet.event}
                        isOpen={showPublishActionSheet.open}
                        onDidDismiss={() => setShowPublishActionSheet({ open: false, event: undefined })}
                    >
                        <IonList>
                            <IonItem
                                button
                                onClick={() => handlePublish(isPublished)}
                            >
                                {isPublished ? reduxText[692] : reduxText[693]}
                            </IonItem>
                        </IonList>
                    </IonPopover>
                </div>
            </div>
            {isDeleting && (
                <Loader
                    isOpen
                    message={reduxText[515]}
                />
            )}
            <IonAlert
                buttons={deleteAlertButtons}
                header={reduxText[658]}
                isOpen={isDeleteAlertOpen}
                message={reduxText[659]}
                onDidDismiss={() => setIsDeleteAlertOpen(false)}
            />
        </div>
    )
})
