// Root Url
const rootPath = 'v1/catalog/select/'

// Property Select
export const select_url_activity = `${rootPath}activity/`
export const select_url_component_type = `${rootPath}component_type/`
export const select_url_designation = `${rootPath}designation/`
export const select_url_prestation = `${rootPath}prestation/`
export const select_url_price_type = `${rootPath}price_type/`
export const select_url_property_type = `${rootPath}property_type/`
export const select_url_tool = `${rootPath}tool/`
export const select_url_transaction = `${rootPath}transaction/`
export const select_url_transport_mean = `${rootPath}transport_mean/`
