// Root Url
const rootPath = 'v1/chat/'

// Room Detail
export const api_url_room_detail = `${rootPath}detail/room/`

// Message List
export const api_url_message_list = `${rootPath}list/message/`

// Room List
export const api_url_room_list = `${rootPath}list/room/`
