// react components
import React from 'react'
import {
    IonAlert,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    reduxModalLoginShowHide,
    view_url_auth_login,
} from 'data'

// main
export const LoginAlert: React.FC = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const reduxModal = useSelector((state: defaultReduxState) => state.reduxModal)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    function onAlertDismiss(event: CustomEvent) {
        try {
            dispatch(reduxModalLoginShowHide(false))
            if (event.detail.role === 'login') {
                history.push(view_url_auth_login)
            }
        } catch (error) {
            dispatch(reduxModalLoginShowHide(false))
            dispatch(reduxModalErrorEventHandler(
                error,
                'LoginAlert',
                'onAlertDismiss',
            ))
        }
    }

    return (
        <IonAlert
            header={reduxText[645]}
            isOpen={reduxModal.login.isOpen}
            message={reduxText[646]}
            onDidDismiss={onAlertDismiss}
            buttons={[
                {
                    text: reduxText[648],
                    role: 'cancel',
                },
                {
                    text: reduxText[647],
                    role: 'login',
                },
            ]}
        />
    )
}
