// data
import {
    REDUX_MODAL_ERROR_EVENT_HANDLER,
    REDUX_MODAL_ERROR_SHOW_HIDE,
    REDUX_MODAL_LOGIN_SHOW_HIDE,
} from './mb_actions'
import {
    reduxModalState,
} from './mb_state'

// Initial State
const initialState: reduxModalState = {
    error: {
        header: '',
        isOpen: false,
        noMessage: false,
        subHeader: '',
    },
    errorEventHandler: {
        component: '',
        error: undefined,
        info: '',
    },
    login: {
        isOpen: false,
    },
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case REDUX_MODAL_ERROR_EVENT_HANDLER: {
            return {
                ...state,
                errorEventHandler: action.payload,
            }
        }
        case REDUX_MODAL_ERROR_SHOW_HIDE: {
            return {
                ...state,
                error: action.payload,
            }
        }
        case REDUX_MODAL_LOGIN_SHOW_HIDE: {
            return {
                ...state,
                login: {
                    isOpen: action.payload,
                },
            }
        }
        default:
            return state
    }
}
