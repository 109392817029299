// react components
import React, {
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'
import {
    Redirect,
    useLocation,
} from 'react-router-dom'

// components
import {
    Footer,
    SeoBlock,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    LoginBlock,
    SignupBlock,
} from 'pages'

// main
export const AuthPage: React.FC = () => {

    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [loginEmptyFields, setLoginEmptyFields] = useState<number>(0)
    const [signupEmptyFields, setSignupEmptyFields] = useState<number>(0)

    if (reduxAuth.authenticated) {
        return <Redirect to='/' />
    }
    return (
        <IonPage className='auth-page-web navbar-pt'>
            <SeoBlock pageId={20} />
            <IonContent>
                <div className='apw-content mb-container'>
                    <div className='apw-left'>
                        <LoginBlock
                            emptyFields={loginEmptyFields}
                            setEmptyField={setSignupEmptyFields}
                        />
                    </div>
                    {!location.hash && (
                        <div className='apw-right'>
                            <SignupBlock
                                emptyFields={signupEmptyFields}
                                setEmptyField={setLoginEmptyFields}
                            />
                        </div>
                    )}
                </div>
                <Footer />
            </IonContent>
        </IonPage>
    )
}
