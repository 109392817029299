// react components
import React from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    NavLink,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxFormSetIsNewsletterOpen,
    view_url_contact_form,
    view_url_main_bareme,
    view_url_main_cgv,
    view_url_main_rgpd,
    view_url_main_service,
    view_url_profile_detail,
    view_url_property_form,
    view_url_property_list,
} from 'data'

// main
export const Footer: React.FC = () => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <div className='mb-footer-web'>
            <div className='fw-block-1'>
                <p className='fw-title'>{reduxText[454]}</p>
                <p className='fw-text pre-line'>{reduxText[455]}</p>
                <p
                    className='fw-newsletter-action'
                    onClick={() => dispatch(reduxFormSetIsNewsletterOpen(true))}
                >
                    {reduxText[713]}
                </p>
                {/* <p className='fw-title'>{reduxText[458]}</p>
                <div className='fw-social-links'>
                    <a
                        href={reduxText[476]}
                        rel='noopener noreferrer'
                        target='_blank'
                    >
                        <ImageHelper
                            alt=''
                            className='fw-social-image'
                            dominant_color={undefined}
                            src={reduxText[473]}
                        />
                    </a>
                    <a
                        href={reduxText[475]}
                        rel='noopener noreferrer'
                        target='_blank'
                    >
                        <ImageHelper
                            alt=''
                            className='fw-social-image'
                            dominant_color={undefined}
                            src={reduxText[474]}
                        />
                    </a>
                    <a
                        href={reduxText[477]}
                        rel='noopener noreferrer'
                        target='_blank'
                    >
                        <ImageHelper
                            alt=''
                            className='fw-social-image'
                            dominant_color={undefined}
                            src={reduxText[472]}
                        />
                    </a>
                </div> */}
            </div>
            <div className='fw-block-2'>
                <p className='fw-title'>{reduxText[459]}</p>
                <NavLink
                    className='fw-link'
                    exact
                    to={view_url_property_list}
                >
                    {reduxText[460]}
                </NavLink>
                <NavLink
                    className='fw-link'
                    exact
                    to={view_url_contact_form}
                >
                    {reduxText[461]}
                </NavLink>
                <NavLink
                    className='fw-link'
                    exact
                    to={view_url_contact_form}
                >
                    {reduxText[462]}
                </NavLink>
            </div>
            <div className='fw-block-3'>
                <p className='fw-title'>{reduxText[466]}</p>
                <NavLink
                    className='fw-link'
                    exact
                    to={view_url_main_service}
                >
                    {reduxText[463]}
                </NavLink>
                <NavLink
                    className='fw-link'
                    exact
                    to={view_url_main_bareme}
                >
                    {reduxText[467]}
                </NavLink>
                <NavLink
                    className='fw-link'
                    exact
                    to={view_url_main_cgv}
                >
                    {reduxText[468]}
                </NavLink>
                <NavLink
                    className='fw-link'
                    exact
                    to={view_url_main_rgpd}
                >
                    {reduxText[469]}
                </NavLink>
            </div>
            <div className='fw-block-4'>
                <p className='fw-title'>{reduxText[882]}</p>
                <NavLink
                    className='fw-link'
                    exact
                    to={view_url_profile_detail}
                >
                    {reduxText[464]}
                </NavLink>
                <NavLink
                    className='fw-link'
                    exact
                    to={`${view_url_property_form}new/intro/1/`}
                >
                    {reduxText[465]}
                </NavLink>
                <NavLink
                    className='fw-link'
                    exact
                    to={`${view_url_property_form}new/intro/2/`}
                >
                    {reduxText[470]}
                </NavLink>
                <NavLink
                    className='fw-link'
                    exact
                    to={view_url_contact_form}
                >
                    {reduxText[471]}
                </NavLink>
            </div>
        </div>
    )
}
