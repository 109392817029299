// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Redirect,
    useLocation,
} from 'react-router-dom'

// components
import {
    Button,
    ErrorInput,
    Footer,
    Loader,
    SeoBlock,
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
    api_url_auth_password_reset_confirm,
    reduxModalErrorEventHandler,
    view_url_auth_login,
} from 'data'

// services 
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// main
export const PasswordResetConfirmPage: React.FC = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    type errorsType = {
        non_field_errors?: string
        password2?: string
        new_password?: string
    }
    type fieldsType = {
        password2: string
        new_password: string
    }
    const errorsInitial = {
        non_field_errors: '',
        password2: '',
        new_password: '',
    }
    const fieldsInitial = {
        password2: '',
        new_password: '',
    }

    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setErrors({ ...errors, [name]: '' })
            setFields({ ...fields, [name]: event.value })
            setButtonDisabled(false)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PasswordResetConfirmPage',
                'handleInputChange',
            ))
        }
    }

    function onSubmit() {
        try {
            if (process.env.NODE_ENV === 'development') console.log('onSubmit')
            if (fields.new_password !== fields.password2) {
                setButtonDisabled(true)
                setErrors({ ...errors, password2: reduxText[783] })
            } else {
                setButtonDisabled(true)
                setIsLoading(true)

                // Construct FormData
                const formData = new FormData()

                // Fields
                formData.append('uid', location.hash.split('/')[2])
                formData.append('token', location.hash.split('/')[3])
                formData.append('new_password', fields.new_password)
                const createUrl = getApiUrl(api_url_auth_password_reset_confirm, reduxAuth)
                axios({
                    data: formData,
                    headers: getAxiosHeaders(reduxAuth, dispatch, 'PasswordResetConfirmPage'),
                    method: 'post',
                    url: createUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setFields(fieldsInitial)
                        setIsLoading(false)
                        setIsSuccess(true)
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        if (error.response) {
                            setErrors(error.response.data)
                        }
                        axiosErrorHandler({
                            apiUrl: createUrl,
                            component: 'PasswordResetConfirmPage',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'onSubmit',
                            skip401: true,
                        })
                    })
            }
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'PasswordResetConfirmPage',
                'onSubmit',
            ))
        }
    }

    if (reduxAuth.authenticated) {
        return <Redirect to='/' />
    }

    return (
        <IonPage className='auth-page-web navbar-pt'>
            <SeoBlock pageId={19} />
            <Loader isOpen={isLoading} />
            <IonContent>
                <div className='apw-content mb-container'>
                    <div className='apw-left'>
                        <div className='login-block-web'>
                            <h1 className='lbw-title'>{reduxText[784]}</h1>
                            {isSuccess && (
                                <div className='success-registration'>
                                    <div className='success-message pre-line'>
                                        <p>{reduxText[785]}</p>
                                    </div>
                                    <Button
                                        to={view_url_auth_login}
                                        text={reduxText[786]}
                                    />
                                </div>
                            )}
                            {!isSuccess && (
                                <React.Fragment>
                                    <TextInput
                                        error={errors.new_password}
                                        label={reduxText[480]}
                                        name='new_password'
                                        onChange={handleInputChange}
                                        type='password'
                                        value={fields.new_password}
                                    />
                                    <TextInput
                                        error={errors.password2}
                                        label={reduxText[495]}
                                        name='password2'
                                        onChange={handleInputChange}
                                        type='password'
                                        value={fields.password2}
                                    />
                                    <ErrorInput error={errors.non_field_errors} />
                                    <Button
                                        disabled={buttonDisabled}
                                        onClick={onSubmit}
                                        text={reduxText[664]}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
                <Footer />
            </IonContent>
        </IonPage>
    )
}
