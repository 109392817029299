// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    IonContent,
    IonItem,
    IonPage,
    IonToggle,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Redirect,
} from 'react-router'

// components
import {
    Footer,
    Loader,
    SeoBlock,
} from 'components'

// data
import {
    defaultReduxState,
    api_url_profile_detail,
    api_url_settings_mail_notification_form_update,
    api_url_settings_newsletter_form_update,
    reduxModalErrorEventHandler,
    view_url_auth_login,
} from 'data'

// pages
import {
    ProfileHeader,
    ProfileForm,
} from 'pages'

// serializers
import {
    ProfileDetailSerializer,
} from 'serializers'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// main
export const ProfileDetail: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
        getData()
    }, [reduxAuth.settings?.id])

    useEffect(() => {
        if (reduxForm.refresh?.refreshDetail) {
            if (process.env.NODE_ENV === 'development') console.log(reduxForm.refresh?.refreshDetail)
            if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
            getData()
        }
    }, [reduxForm.refresh?.refreshDetail])

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [object, setObject] = useState<ProfileDetailSerializer | undefined>(undefined)

    function getData(refresh?: any) {
        try {
            if (!reduxAuth.settings?.id) {
                setIsLoading(false)
                return
            }
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            setAxiosCancelToken(source)
            setIsLoading(true)
            const getUrl = getApiUrl(`${api_url_profile_detail}${reduxAuth.settings.id}/`, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: getAxiosHeaders(reduxAuth, dispatch, 'ProfileDetail'),
                method: 'get',
                url: getUrl,
                cancelToken: source.token,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    if (refresh) {
                        refresh.detail.complete()
                    }
                    setObject(response.data)
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'ProfileDetail',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetail',
                'getData',
            ))
        }
    }

    function updateMailNotification(value: boolean) {
        try {
            const axiosUrl = getApiUrl(`${api_url_settings_mail_notification_form_update}${reduxAuth.settings?.id}/`, reduxAuth)
            const formData: any = new FormData()
            formData.append('accept_mail_notification', value)
            axios({
                data: formData,
                headers: getAxiosHeaders(reduxAuth, dispatch, 'ProfileDetail'),
                method: 'put',
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'ProfileDetail',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'updateMailNotification',
                    })
                })
        } catch (error) {
            if (process.env.NODE_ENV === 'development') console.log(error)
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetail',
                'updateMailNotification',
            ))
        }
    }

    function updateNewsletter(value: boolean) {
        try {
            const axiosUrl = getApiUrl(`${api_url_settings_newsletter_form_update}${reduxAuth.settings?.id}/`, reduxAuth)
            const formData: any = new FormData()
            formData.append('accept_newsletter', value)
            axios({
                data: formData,
                headers: getAxiosHeaders(reduxAuth, dispatch, 'ProfileDetail'),
                method: 'put',
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: axiosUrl,
                        component: 'ProfileDetail',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'updateNewsletter',
                    })
                })
        } catch (error) {
            if (process.env.NODE_ENV === 'development') console.log(error)
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfileDetail',
                'updateNewsletter',
            ))
        }
    }

    if (!reduxAuth.authenticated) {
        return <Redirect to={view_url_auth_login} />
    }
    return (
        <IonPage className='profile-detail-web navbar-pt'>
            <SeoBlock pageId={7} />
            <IonContent>
                <ProfileHeader />
                <div className='pdw-content mb-container'>
                    <div className='pdw-left'>
                        <div className='pdw-title-wrap'>
                            <p className='pdw-title'>{reduxText[663]}</p>
                        </div>
                        <ProfileForm />
                    </div>
                    <div className='pdw-right'>
                        <div className='pdw-title-wrap'>
                            <p className='pdw-title'>{reduxText[665]}</p>
                        </div>
                        <div className='pdw-settings'>
                            {object && (
                                <React.Fragment>
                                    <IonItem className='pdw-settings-item'>
                                        <IonToggle
                                            checked={object?.accept_newsletter}
                                            color='secondary'
                                            onIonChange={(e) => updateNewsletter(e.detail.checked)}
                                        />
                                        <p className='pdw-settings-text'>{reduxText[666]}</p>
                                    </IonItem>
                                    <IonItem className='pdw-settings-item'>
                                        <IonToggle
                                            checked={object?.accept_mail_notification}
                                            color='secondary'
                                            onIonChange={(e) => updateMailNotification(e.detail.checked)}
                                        />
                                        <p className='pdw-settings-text'>{reduxText[667]}</p>
                                    </IonItem>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
                <Footer />
            </IonContent>
            <Loader isOpen={isLoading} />
        </IonPage>
    )
}
