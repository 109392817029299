// react components
import React from 'react'
import {
    IonInput,
} from '@ionic/react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    ErrorInput,
    MainFormLabel,
} from 'components'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// props
type TextInputProps = {
    autofocus?: boolean
    disabled?: boolean
    error: string | undefined
    helperText?: string
    inputmode?: 'text' | 'tel' | 'url' | 'email' | 'search' | 'none' | 'numeric' | 'decimal'
    label?: string
    max?: string
    maxlength?: number
    min?: string
    name: string
    onChange: React.Dispatch<any>
    optional?: boolean
    pattern?: string
    placeholder?: string
    staffOnly?: boolean
    step?: string
    type?: 'number' | 'time' | 'text' | 'tel' | 'url' | 'email' | 'search' | 'date' | 'password'
    value: string | number
}

// main
export const TextInput: React.FC<TextInputProps> = React.memo(({
    autofocus,
    disabled,
    error,
    helperText,
    inputmode,
    label,
    max,
    maxlength,
    min,
    name,
    onChange,
    optional,
    pattern,
    placeholder,
    staffOnly,
    step,
    type,
    value,
}) => {

    const dispatch = useDispatch()

    function onInputChange(value: string | null | undefined) {
        try {
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TextInput',
                'onInputChange',
            ))
        }
    }

    return (
        <div className='text-input-web'>
            {label && (
                <MainFormLabel
                    helperText={helperText}
                    label={label}
                    name={name}
                    optional={optional}
                    staffOnly={staffOnly}
                />
            )}
            <div className='form-input-wrap'>
                <div className='fiw-input'>
                    <IonInput
                        autofocus={autofocus}
                        className={`tiw-input ${disabled ? 'disabled' : ''}`}
                        disabled={disabled}
                        inputmode={inputmode || 'text'}
                        max={max}
                        maxlength={maxlength || 191}
                        min={min}
                        name={name}
                        onIonChange={(e) => onInputChange(e.detail.value)}
                        pattern={pattern}
                        placeholder={placeholder}
                        step={step}
                        type={type || 'text'}
                        value={value}
                    />
                </div>
                {helperText && (
                    <div className='fiw-helper'>
                        <p className='pre-line'>{helperText}</p>
                    </div>
                )}
            </div>
            <ErrorInput error={error} />
        </div>
    )
})
