// constants
export const REDUX_MODAL_ERROR_EVENT_HANDLER = 'REDUX_MODAL_ERROR_EVENT_HANDLER'
export const REDUX_MODAL_ERROR_SHOW_HIDE = 'REDUX_MODAL_ERROR_SHOW_HIDE'
export const REDUX_MODAL_LOGIN_SHOW_HIDE = 'REDUX_MODAL_LOGIN_SHOW_HIDE'

// actions
export function reduxModalErrorEventHandler(error: any, component: string, info: string, skip?: boolean) {
    return {
        type: REDUX_MODAL_ERROR_EVENT_HANDLER,
        payload: {
            component,
            error,
            info,
            skip,
        },
    }
}

export function reduxModalErrorShowHide(
    boolean: boolean,
    header?: string,
    subHeader?: string,
    noMessage?: boolean,
) {
    return {
        type: REDUX_MODAL_ERROR_SHOW_HIDE,
        payload: {
            header: header,
            isOpen: boolean,
            noMessage: noMessage,
            subHeader: subHeader,
        },
    }
}

export function reduxModalLoginShowHide(boolean: boolean) {
    return {
        type: REDUX_MODAL_LOGIN_SHOW_HIDE,
        payload: boolean,
    }
}
