// Root Url
const rootPath = 'v1/auth/'

// Auth API Views
export const api_url_auth_password_reset_confirm = `${rootPath}users/reset_password_confirm/`
export const api_url_auth_password_reset = `${rootPath}users/reset_password/`
export const api_url_auth_token_convert = `${rootPath}convert-token/`
export const api_url_auth_token_obtain = `${rootPath}token/obtain/`
export const api_url_auth_user_activation = `${rootPath}users/activation/`
export const api_url_auth_user_create = `${rootPath}users/`
