// react components
import React from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Footer,
    SeoBlock,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// main
export const RgpdPage: React.FC = () => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <IonPage className='bareme-page-web navbar-pt'>
            <SeoBlock pageId={24} />
            <IonContent>
                <div
                    className='bpw-content mb-container'
                    dangerouslySetInnerHTML={{ __html: reduxText[779] }}
                />
                <Footer />
            </IonContent>
        </IonPage>
    )
}
