// react components
import React from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    Footer,
    ImageHelper,
    SeoBlock,
} from 'components'

// data
import {
    defaultReduxState,
    view_url_main_home,
} from 'data'

// main
export const NotFoundPage: React.FC = () => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <IonPage className='navbar-pt'>
            <SeoBlock pageId={17} />
            <IonContent className='not-found-page-web'>
                <div
                    className='nfpw-content'
                    style={{
                        backgroundImage: `url(${reduxText[594]})`,
                    }}
                >
                    <div className='nfpw-content-wrap'>
                        <h1 className='nfpw-title'>{reduxText[590]}</h1>
                        <ImageHelper
                            className='nfpw-image'
                            dominant_color={reduxText[59300]}
                            src={reduxText[593]}
                            alt=''
                        />
                        <p className='nfpw-text'>{reduxText[591]}</p>
                        <Button
                            text={reduxText[592]}
                            to={view_url_main_home}
                        />
                    </div>
                </div>
                <Footer />
            </IonContent>
        </IonPage>
    )
}
