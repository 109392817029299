// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonAlert,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    NavLink,
    useLocation,
} from 'react-router-dom'

// components
import {
    ImageHelper,
} from 'components'

// data
import {
    defaultReduxState,
    reduxAuthLogout,
    view_url_article_list,
    view_url_contact_form,
    view_url_main_amenagement,
    view_url_main_cuisine_partagee,
    view_url_main_dark_kitchen,
    view_url_main_home,
    view_url_main_laboratoire_patisserie,
    view_url_main_local_avec_extraction,
    view_url_main_service,
    view_url_main_traiteur,
    view_url_main_transformation_alimentaire,
    view_url_profile_detail,
    view_url_profile_property_favourite_list,
    view_url_profile_property_list,
    view_url_property_form,
    view_url_property_list,
    view_url_room_list,
} from 'data'

// main
export const Header: React.FC = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLogoutOpen, setIsLogoutOpen] = useState<boolean>(false)
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

    useEffect(() => {
        setIsMenuOpen(false)
    }, [
        location,
    ])

    if (['deposer-annonce'].includes(location.pathname.split('/')[1]) && reduxAuth.authenticated) {
        if (['intro', 'adresse', 'info', 'images', 'preview'].includes(location.pathname.split('/')[3])) {
            if (!(location.pathname.split('/')[2] === 'new' && !location.pathname.split('/')[4])) {
                return null
            }
        }
    }

    if (['modification'].includes(location.pathname.split('/')[1]) && reduxAuth.authenticated) {
        return null
    }

    if (['profil'].includes(location.pathname.split('/')[1]) && ['message'].includes(location.pathname.split('/')[2]) && reduxAuth.authenticated) {
        return null
    }

    // Extra
    const logoutAlertButtons = [
        {
            text: reduxText[743],
            role: 'cancel',
        },
        {
            text: reduxText[742],
            handler: () => {
                dispatch(reduxAuthLogout())
            }
        },
    ]

    return (
        <div className='mb-header'>
            <div className='mh-wrap'>
                <div className='mh-left'>
                    <NavLink
                        exact
                        to={view_url_main_home}
                    >
                        <ImageHelper
                            alt='Mylab Logo'
                            className='mh-logo'
                            dominant_color={reduxText[40000]}
                            src='https://media.mylab.immo/global/main-logo.svg'
                        />
                    </NavLink>
                </div>
                <div className='mh-right'>
                    <div className={`mh-page-wrap${isMenuOpen ? ' is-open' : ''}`}>
                        <div className='mh-dropdown'>
                            <span className='mh-page-link mh-button'>{reduxText[407]}</span>
                            <div className='mh-dropdown-content'>
                                <div className='mh-dropdown-wrap'>
                                    <NavLink
                                        className='mh-page-link'
                                        exact
                                        to={`${view_url_property_form}new/intro/1/`}
                                    >
                                        {reduxText[496]}
                                    </NavLink>
                                    <NavLink
                                        className='mh-page-link'
                                        exact
                                        to={`${view_url_property_form}new/intro/2/`}
                                    >
                                        {reduxText[497]}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <NavLink
                            className='mh-page-link'
                            exact
                            to={view_url_property_list}
                        >
                            {reduxText[408]}
                        </NavLink>
                        <div className='mh-dropdown'>
                            <span className='mh-page-link hidden-mobile'>{reduxText[695]}</span>
                            <div className='mh-dropdown-content'>
                                <div className='mh-dropdown-wrap'>
                                    <NavLink
                                        className='mh-page-link'
                                        exact
                                        to={view_url_main_service}
                                    >
                                        {reduxText[863]}
                                    </NavLink>
                                    <NavLink
                                        className='mh-page-link'
                                        exact
                                        to={view_url_main_amenagement}
                                    >
                                        {reduxText[409]}
                                    </NavLink>
                                    <NavLink
                                        className='mh-page-link'
                                        exact
                                        to={view_url_main_dark_kitchen}
                                    >
                                        {reduxText[864]}
                                    </NavLink>
                                    <NavLink
                                        className='mh-page-link'
                                        exact
                                        to={view_url_main_laboratoire_patisserie}
                                    >
                                        {reduxText[865]}
                                    </NavLink>
                                    <NavLink
                                        className='mh-page-link'
                                        exact
                                        to={view_url_main_local_avec_extraction}
                                    >
                                        {reduxText[881]}
                                    </NavLink>
                                    <NavLink
                                        className='mh-page-link'
                                        exact
                                        to={view_url_main_traiteur}
                                    >
                                        {reduxText[906]}
                                    </NavLink>
                                    <NavLink
                                        className='mh-page-link'
                                        exact
                                        to={view_url_main_transformation_alimentaire}
                                    >
                                        {reduxText[908]}
                                    </NavLink>
                                    <NavLink
                                        className='mh-page-link'
                                        exact
                                        to={view_url_main_cuisine_partagee}
                                    >
                                        {reduxText[907]}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <NavLink
                            className='mh-page-link'
                            exact
                            to={view_url_article_list}
                        >
                            {reduxText[410]}
                        </NavLink>
                        <NavLink
                            className='mh-page-link'
                            exact
                            to={view_url_contact_form}
                        >
                            {reduxText[411]}
                        </NavLink>
                        {reduxAuth.authenticated
                            ? (
                                <div className='mh-profile-wrap'>
                                    <div className='mh-dropdown'>
                                        <ImageHelper
                                            alt=''
                                            className='mh-profile-pic'
                                            dominant_color={reduxAuth.settings?.dominant_color}
                                            src={reduxAuth.settings?.image}
                                        />
                                        <div className='mh-dropdown-content'>
                                            <div className='mh-dropdown-wrap'>
                                                <NavLink
                                                    className='mh-page-link'
                                                    exact
                                                    to={view_url_profile_property_list}
                                                >
                                                    {reduxText[622]}
                                                </NavLink>
                                                <NavLink
                                                    className='mh-page-link'
                                                    exact
                                                    to={view_url_profile_property_favourite_list}
                                                >
                                                    {reduxText[623]}
                                                </NavLink>
                                                <NavLink
                                                    className='mh-page-link'
                                                    exact
                                                    to={view_url_room_list}
                                                >
                                                    {reduxText[624]}
                                                </NavLink>
                                                <NavLink
                                                    className='mh-page-link'
                                                    exact
                                                    to={view_url_profile_detail}
                                                >
                                                    {reduxText[625]}
                                                </NavLink>
                                                <span
                                                    className='mh-page-link'
                                                    onClick={() => setIsLogoutOpen(true)}
                                                >
                                                    {reduxText[626]}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <NavLink
                                        className='mh-page-link hidden-mobile'
                                        to={view_url_profile_property_list}
                                    >
                                        {reduxAuth.settings?.first_name}
                                    </NavLink>
                                    <IonAlert
                                        buttons={logoutAlertButtons}
                                        header={reduxText[740]}
                                        isOpen={isLogoutOpen}
                                        message={reduxText[741]}
                                        onDidDismiss={() => setIsLogoutOpen(false)}
                                    />
                                </div>
                            ) : (
                                <NavLink
                                    className='mh-page-link'
                                    to={view_url_profile_property_list}
                                >
                                    {reduxText[412]}
                                </NavLink>
                            )}
                    </div>
                    <button
                        className={`mh-burger hamburger hamburger--squeeze bs-hamburger${isMenuOpen ? ' is-active' : ''}`}
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        type='button'
                    >
                        <span className='hamburger-box bs-hamburger-box'>
                            <span className='hamburger-inner bs-hamburger-inner' />
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}
