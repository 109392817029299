// data
import {
    api_url_profile_form_create,
    api_url_profile_form_detail,
    api_url_profile_form_update,
    reduxAuthState,
} from 'data'

// serializers
import {
    ProfileDetailFormSerializer,
    ProfileDetailFormSerializerError,
} from 'serializers'

// services
import {
    getApiUrl,
} from 'services'

// props
export type ProfileMotherFormStateProps = {
    errors: ProfileDetailFormSerializerError
    fields: ProfileDetailFormSerializer | undefined
}

// main
export function ProfileMotherFormState(reduxAuth: reduxAuthState) {
    return {
        // Settings
        model: 'profile',

        // Urls
        createUrl: getApiUrl(api_url_profile_form_create, reduxAuth),
        detailUrl: getApiUrl(api_url_profile_form_detail, reduxAuth),
        updateUrl: getApiUrl(api_url_profile_form_update, reduxAuth),

        // Fields
        errors: {},
        fields: undefined,

        // Required fields
        requiredAttribute: [
            'first_name',
            'user',
        ],
        requiredForeignKey: [],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'company',
            'first_name',
            'image_doka_output_data',
            'last_name',
            'phone_number',
            'user',
            'website',
        ],
        formFile: [
            'image',
        ],
        formForeignKey: [],
        formJson: [],
        formManyToMany: [],
    }
}
