// main
export function getInputValue(event: any, formInfo: any) {
    const name = event.name
    if (['image'].includes(event.name)) {
        return {
            ...formInfo,
            errors: {
                ...formInfo.errors,
                [name]: '',
                [`${name}_doka_output_data`]: '',
                [`${name}_original`]: '',
                main_error: '',
            },
            fields: {
                ...formInfo.fields,
                [`${name}_cropping`]: '',
                [`${name}_doka_output_data`]: JSON.stringify(event.doka_output_data),
                [`${name}_original`]: event.value,
                [name]: event.doka_image,
                get_image_hq: event.get_image_hq,
                get_image_lq: event.get_image_lq,
                get_image_mq: event.get_image_mq,
            },
        }
    } else {
        return {
            ...formInfo,
            errors: {
                ...formInfo.errors,
                [name]: '',
                main_error: '',
            },
            fields: {
                ...formInfo.fields,
                [name]: event.value,
            },
        }
    }
}
