// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'

// data
import {
    errorMessageToSkip,
    reduxAuthState,
} from 'data'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'


export function getInfiniteList(
    apiUrl: string,
    reduxAuth: reduxAuthState,
    dispatch: React.Dispatch<any>,
    items: any[],
    setItems: React.Dispatch<any>,
    setNextUrl: React.Dispatch<any>,
    axiosCancelToken: CancelTokenSource | undefined,
    setAxiosCancelToken: React.Dispatch<any>,
    setDisableInfiniteScroll: React.Dispatch<any>,
    setIsLoading: React.Dispatch<any>,
    setHasMore: React.Dispatch<any>,
    component: string,
    changingView: boolean,
    custumFunction?: any,
    ids_in?: number[],
) {
    if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
    setDisableInfiniteScroll(true)

    if (!apiUrl) return

    setIsLoading(true)

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    setAxiosCancelToken(source)

    if (changingView) {
        setItems([])
        setNextUrl('')
        setHasMore(true)
    }

    if (process.env.NODE_ENV === 'development') console.log(apiUrl, component)

    const formData: any = new FormData()
    if (ids_in) formData.append('ids_in', ids_in.join(','))
    axios({
        data: formData,
        headers: getAxiosHeaders(reduxAuth, dispatch, component),
        method: ids_in ? 'post' : 'get',
        url: apiUrl,
        cancelToken: source.token,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            if (changingView) {
                setItems(response.data.results)
            } else {
                setItems([...items, ...response.data.results])
            }
            setHasMore(response.data.next !== null)
            setIsLoading(false)
            setNextUrl(response.data.next)
            setDisableInfiniteScroll(response.data.next === null)
            if (custumFunction) custumFunction()
        })
        .catch((error) => {
            setIsLoading(false)
            if (errorMessageToSkip.includes(error.message)) {
                setDisableInfiniteScroll(false)
            } else if (errorMessageToSkip.includes(error)) {
                setDisableInfiniteScroll(false)
            } else {
                setDisableInfiniteScroll(true)
            }
            axiosErrorHandler({
                apiUrl,
                component,
                dispatch,
                reduxAuth,
                reference: 'getInfiniteList',
                error,
            })
        })
}
