// react components
import React from 'react'
import {
    IonToast,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxFormSetIsSuccessToastOpen,
} from 'data'

// main
export const FormSuccessToast: React.FC = () => {

    const dispatch = useDispatch()
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <IonToast
            color='secondary'
            duration={3000}
            isOpen={reduxForm.isSuccessToastOpen || false}
            message={reduxForm.isSuccessToastMessage || reduxText[644]}
            onDidDismiss={() => dispatch(reduxFormSetIsSuccessToastOpen(false))}
            buttons={[
                {
                    text: reduxText[510],
                    role: 'cancel',
                }
            ]}
        />
    )
}
