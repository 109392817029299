// react components
import React from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    Footer,
    ImageHelper,
    SeoBlock,
} from 'components'

// data
import {
    defaultReduxState,
    view_url_contact_form,
} from 'data'

// main
export const AmenagementPage: React.FC = () => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <IonPage className='navbar-pt'>
            <SeoBlock pageId={12} />
            <IonContent className='amenagement-page-web'>
                <div className='apw-cover'>
                    <ImageHelper
                        alt=''
                        className='apw-cover-image'
                        dominant_color={reduxText[52000]}
                        src={reduxText[520]}
                    />
                    <div className='apw-cover-text'>
                        <div className='mb-container'>
                            <h1 className='apw-text1'>{reduxText[518]}</h1>
                            <p className='apw-text2'>{reduxText[519]}</p>
                        </div>
                    </div>
                </div>
                <div className='mb-container'>
                    <div className='apw-head-block'>
                        <div className='apw-head-image-wrap'>
                            <ImageHelper
                                alt=''
                                className='apw-head-image'
                                dominant_color={reduxText[52100]}
                                src={reduxText[521]}
                            />
                        </div>
                        <div className='apw-head-text'>
                            <p className='apw-head-name'>{reduxText[522]}</p>
                            <Button
                                href='http://mylab-travaux.com/'
                                text={reduxText[523]}
                            />
                        </div>
                    </div>
                    <div className='apw-service-block apw-service-block2'>
                        <div className='apw-service-image'>
                            <ImageHelper
                                alt=''
                                dominant_color={reduxText[52600]}
                                src={reduxText[526]}
                            />
                        </div>
                        <div className='apw-service-text'>
                            <p className='apw-service-name'>{reduxText[524]}</p>
                            <p className='apw-service-description'>{reduxText[525]}</p>
                        </div>
                    </div>
                    <div className='apw-service-block'>
                        <div className='apw-service-image'>
                            <ImageHelper
                                alt=''
                                dominant_color={reduxText[52700]}
                                src={reduxText[527]}
                            />
                        </div>
                        <div className='apw-service-text'>
                            <p className='apw-service-name'>{reduxText[528]}</p>
                            <p className='apw-service-description'>{reduxText[529]}</p>
                        </div>
                    </div>
                    <div className='apw-service-block apw-service-block2'>
                        <div className='apw-service-image'>
                            <ImageHelper
                                alt=''
                                dominant_color={reduxText[53200]}
                                src={reduxText[532]}
                            />
                        </div>
                        <div className='apw-service-text'>
                            <p className='apw-service-name'>{reduxText[530]}</p>
                            <p className='apw-service-description'>{reduxText[531]}</p>
                        </div>
                    </div>
                    <div className='apw-newsletter-wrap'>
                        <h2 className='apw-text'>{reduxText[533]}</h2>
                        <ImageHelper
                            alt=''
                            className='apw-blog-image'
                            dominant_color={reduxText[53400]}
                            src={reduxText[534]}
                        />
                        <Button
                            text={reduxText[535]}
                            to={view_url_contact_form}
                        />
                    </div>
                </div>
                <Footer />
            </IonContent>
        </IonPage>
    )
}
