// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonLoading,
} from '@ionic/react'

// props
type LoaderProps = {
    isOpen: boolean
    message?: string
}

// main
export const Loader: React.FC<LoaderProps> = React.memo(({
    isOpen,
    message,
}) => {

    if (!isOpen) return null

    return (
        <LoaderHelper
            message={message}
        />
    )
})

// props
type LoaderHelperProps = {
    message?: string
}

// main
const LoaderHelper: React.FC<LoaderHelperProps> = React.memo(({
    message,
}) => {

    const [showLoader, setShowLoader] = useState(false)

    let wto: any

    useEffect(() => {
        clearTimeout(wto)
        wto = setTimeout(() => {
            setShowLoader(true)
        }, 1000)
        return () => {
            clearTimeout(wto)
        }
    }, [])

    if (!showLoader) return null

    return (
        <IonLoading
            isOpen
            message={message}
        />
    )
})
