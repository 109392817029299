// data
import {
    reduxTextDataState,
} from 'data'

// main
export function checkRequiredFields(formInfoObject: any, reduxText: reduxTextDataState) {
    const errorArray: any[] = []
    const errorObject: any = {}

    // ForeignKey
    if (formInfoObject.requiredForeignKey) { // Handle foreignKey field required
        formInfoObject.requiredForeignKey.map((field: string) => {
            if (!formInfoObject.fields[field]) {
                errorArray.push(field)
                errorObject[field] = reduxText[589]
            }
            return false
        })
    }

    // ManyToMany
    if (formInfoObject.requiredManyToMany) {
        formInfoObject.requiredManyToMany.map((field: string) => {
            if (!formInfoObject.fields[field] || formInfoObject.fields[field].length === 0) {
                errorArray.push(field)
                errorObject[field] = reduxText[589]
            }
            return false
        })
    }

    // Attributes
    if (formInfoObject.requiredAttribute) {
        formInfoObject.requiredAttribute.map((field: string) => {
            if (!formInfoObject.fields[field]) {
                errorArray.push(field)
                errorObject[field] = reduxText[589]
            }
            return false
        })
    }

    // Boolean
    if (formInfoObject.requiredBoolean) {
        formInfoObject.requiredBoolean.map((field: string) => {
            if (formInfoObject.fields[field] !== true && formInfoObject.fields[field] !== false) {
                errorArray.push(field)
                errorObject[field] = reduxText[589]
            }
            return false
        })
    }

    if (process.env.NODE_ENV === 'development') console.log(errorArray)

    // Output
    if (errorArray.length === 0) {
        return 'ok'
    } else {
        return errorObject
    }
}
