export const view_url_main_amenagement = '/amenagement/'
export const view_url_main_bareme = '/bareme/'
export const view_url_main_cgv = '/cgv/'
export const view_url_main_cuisine_partagee = '/cuisine-partagee/'
export const view_url_main_dark_kitchen = '/dark-kitchen/'
export const view_url_main_home = '/'
export const view_url_main_laboratoire_patisserie = '/laboratoire-patisserie/'
export const view_url_main_local_avec_extraction = '/local-avec-extraction/'
export const view_url_main_rgpd = '/rgpd/'
export const view_url_main_service = '/nos-services/'
export const view_url_main_traiteur = '/traiteur/'
export const view_url_main_transformation_alimentaire = '/transformation-alimentaire/'
