export function setCookie(name: string, value: any, ttl?: number) {
    if (ttl) {
        const now = new Date()
        const item = {
            value: value,
            expiry: now.getTime() + ttl
        }
        localStorage.setItem(name, JSON.stringify(item))
    } else {
        let value2 = value
        if (typeof value === 'object') {
            value2 = JSON.stringify(value)
        }
        localStorage.setItem(name, value2)
    }
}
