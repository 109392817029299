// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonAlert,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    axiosErrorHandlerErrorBoundary,
} from 'services'

// main
export const ErrorEventHandler: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxModal = useSelector((state: defaultReduxState) => state.reduxModal)

    const [showAlert, setShowAlert] = useState<boolean>(false)

    useEffect(() => {
        if (reduxModal.errorEventHandler?.error) {
            if (!reduxModal.errorEventHandler?.skip) setShowAlert(true)
            if (process.env.NODE_ENV === 'development') console.log(reduxModal.errorEventHandler?.error, reduxModal.errorEventHandler?.info)
            if (!reduxModal.errorEventHandler?.skip) {
                axiosErrorHandlerErrorBoundary(
                    reduxModal.errorEventHandler.error.toString(),
                    reduxAuth,
                    reduxModal.errorEventHandler?.component || '',
                    reduxModal.errorEventHandler?.info || '',
                )
            }
        }
    }, [reduxModal.errorEventHandler?.error])

    function onAlertDismiss() {
        dispatch(reduxModalErrorEventHandler(undefined, '', ''))
        setShowAlert(false)
    }

    if (showAlert) {
        return (
            <IonAlert
                buttons={['OK']}
                header='Oops! Quelque chose a mal tourné'
                isOpen
                message="<div><p>Si vous voyer ce message, essayez:</p><p>* De rafraîchir la page.</p><p>* Essayez de vous déconnecter et de vous reconnecter.</p><p>* Toujours pas de chance? Contactez nous via le formulaire de contact.</p></div>"
                onDidDismiss={onAlertDismiss}
                subHeader='Que faire ?'
            />
        )
    }
    return null
}
