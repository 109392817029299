// react components
import React from 'react'
import {
    IonPage,
    IonHeader,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'
import {
    Redirect,
    useParams,
} from 'react-router-dom'

// components
import {
    SeoBlock,
} from 'components'

// data
import {
    defaultReduxState,
    view_url_auth_login,
} from 'data'

// pages
import {
    ImagePropertyPanel,
    IntroPropertyPanel,
    LocationPropertyPanel,
    MainPropertyPanel,
    NotFoundPage,
    PreviewPropertyPanel,
} from 'pages'

// props
type MatchParams = {
    id: string
    panelValue: string
    transactionId: string
}

// main
export const PropertyForm: React.FC = () => {

    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const panelValue = params.panelValue

    if (!reduxAuth.authenticated) {
        return <Redirect to={view_url_auth_login} />
    }
    if (!['intro', 'adresse', 'info', 'images', 'preview'].includes(params.panelValue)) {
        return <NotFoundPage />
    }
    if (params.id === 'new' && !params.transactionId) {
        return <NotFoundPage />
    }
    const headerText: any = {
        adresse: reduxText[570],
        images: reduxText[569],
        info: reduxText[571],
        intro: reduxText[516],
        preview: reduxText[572],
    }
    const seoId: any = {
        adresse: 14,
        images: 16,
        info: 15,
        intro: params.transactionId === '1' ? 8 : 9,
        preview: 21,
    }
    return (
        <IonPage className='property-form-web'>
            <SeoBlock pageId={seoId[panelValue]} />
            <IonHeader className='pfw-header'>
                <p className='pfw-text'>{headerText[panelValue]}</p>
                <div className='pfw-border'>
                    <div className={`pfw-progress ${panelValue}`} />
                </div>
            </IonHeader>
            {panelValue === 'intro' && (
                <IntroPropertyPanel />
            )}
            {panelValue === 'adresse' && (
                <LocationPropertyPanel />
            )}
            {panelValue === 'info' && (
                <MainPropertyPanel />
            )}
            {panelValue === 'images' && (
                <ImagePropertyPanel />
            )}
            {panelValue === 'preview' && (
                <PreviewPropertyPanel />
            )}
        </IonPage>
    )
}
