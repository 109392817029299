// react components
import moment from 'moment'

// main
export function getDateWithType(date?: string, date_type_choice?: string) {

    moment.locale('fr') // default the locale to French
    const localLocale = moment(date)

    if (date_type_choice === 'y') {
        return `${localLocale.format('YYYY')}`
    }
    if (date_type_choice === 'd') {
        return `${localLocale.format('ddd, D MMM YYYY')}`
    }
    if (date_type_choice === 't') {
        return `${localLocale.format('llll')}`
    }
    if (date_type_choice === 'n') {
        return `${localLocale.fromNow()}`
    }
    return `${localLocale.format('MMMM YYYY')}`
}
