// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Redirect,
} from 'react-router'

// components
import {
    Footer,
    InfiniteScrollHelper,
    SeoBlock,
} from 'components'

// data
import {
    defaultReduxState,
    api_url_property_list_favourite,
    reduxModalErrorEventHandler,
    view_url_auth_login,
} from 'data'

// pages
import {
    ProfileHeader,
    PropertyListBigBlock,
} from 'pages'

// serializers
import {
    PropertyListBlockSerializer,
} from 'serializers'

// services
import {
    getInfiniteList,
    getApiUrl,
} from 'services'

// main
export const ProfilePropertyFavouriteList: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    useEffect(() => {
        onGetListData(getApiUrl(api_url_property_list_favourite, reduxAuth), true)
    }, [reduxAuth.settings?.id])

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(true)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [items, setItems] = useState<PropertyListBlockSerializer[]>([])
    const [nextUrl, setNextUrl] = useState<string>('')

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteList(
                apiUrl,
                reduxAuth,
                dispatch,
                items,
                setItems,
                setNextUrl,
                axiosCancelToken,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setIsLoading,
                setHasMore,
                'ProfilePropertyFavouriteList',
                changingView,
            )
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfilePropertyFavouriteList',
                'onGetListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ProfilePropertyFavouriteList',
                'onSearchNext',
            ))
        }
    }

    if (!reduxAuth.authenticated) {
        return <Redirect to={view_url_auth_login} />
    }
    return (
        <IonPage className='profile-property-list-web navbar-pt'>
            <SeoBlock pageId={5} />
            <IonContent>
                <ProfileHeader />
                <div className='pplw-content mb-container'>
                    {items.map((item) => {
                        return (
                            <PropertyListBigBlock
                                key={item.id}
                                object={item}
                            />
                        )
                    })}
                    <InfiniteScrollHelper
                        active={!disableInfiniteScroll}
                        endText
                        hasMore={hasMore}
                        isLoading={isLoading}
                        items={items}
                        noMoreText={690}
                        onChange={onSearchNext}
                    />
                </div>
                <Footer />
            </IonContent>
        </IonPage>
    )
}
