// react components
import {
    combineReducers,
} from 'redux'
import {
    connect,
} from 'react-redux'

// data
import {
    reduxAuthState,
    reduxFormState,
    reduxModalState,
    reduxTextState,
} from 'data'

// reducers
import reduxAuth from './redux/auth/mb_reducer'
import reduxForm from './redux/form/mb_reducer'
import reduxModal from './redux/modal/mb_reducer'
import reduxText from './redux/text/mb_reducer'

const rootReducer = combineReducers({
    reduxAuth,
    reduxForm: reduxForm,
    reduxModal: reduxModal,
    reduxText: reduxText,
})

export default rootReducer

export type defaultReduxState = {
    reduxAuth: reduxAuthState
    reduxForm: reduxFormState
    reduxModal: reduxModalState
    reduxText: reduxTextState
}

// containers
function mapStateToProps(state: any) {
    return {
        reduxAuth: state.reduxAuth,
        reduxForm: state.reduxForm,
        reduxModal: state.reduxModal,
        reduxText: state.reduxText.data,
    }
}

export const MainReduxContainer = connect(mapStateToProps)
