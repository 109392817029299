// react components
import React from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    Footer,
    ImageHelper,
    SeoBlock,
} from 'components'

// data
import {
    defaultReduxState,
    view_url_contact_form,
    view_url_main_amenagement,
    view_url_property_list,
} from 'data'

// main
export const ServicePage: React.FC = () => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <IonPage className='navbar-pt'>
            <SeoBlock pageId={13} />
            <IonContent className='partner-page-web'>
                <div className='ppw-cover'>
                    <ImageHelper
                        alt=''
                        className='ppw-cover-image'
                        dominant_color={reduxText[69600]}
                        src={reduxText[696]}
                    />
                    <div className='ppw-cover-text'>
                        <div className='mb-container'>
                            <h1 className='ppw-text1'>{reduxText[697]}</h1>
                            <p className='ppw-text2'>{reduxText[698]}</p>
                        </div>
                    </div>
                </div>
                <div className='mb-container'>
                    <div className='ppw-head-block'>
                        <div className='ppw-head-image-wrap'>
                            <ImageHelper
                                alt=''
                                className='ppw-head-image'
                                dominant_color={reduxText[69900]}
                                src={reduxText[699]}
                            />
                        </div>
                        <div className='ppw-head-text'>
                            <p className='ppw-head-name'>{reduxText[700]}</p>
                            <Button
                                to={view_url_contact_form}
                                text={reduxText[701]}
                            />
                        </div>
                    </div>
                    <div className='ppw-service-block ppw-service-block2'>
                        <div className='ppw-service-image'>
                            <ImageHelper
                                alt=''
                                dominant_color={reduxText[70200]}
                                src={reduxText[702]}
                            />
                        </div>
                        <div className='ppw-service-text'>
                            <p className='ppw-service-name'>{reduxText[703]}</p>
                            <p className='ppw-service-description'>{reduxText[704]}</p>
                            <Button
                                text={reduxText[897]}
                                to={view_url_property_list}
                            />
                        </div>
                    </div>
                    <div className='ppw-service-block'>
                        <div className='ppw-service-image'>
                            <ImageHelper
                                alt=''
                                dominant_color={reduxText[70500]}
                                src={reduxText[705]}
                            />
                        </div>
                        <div className='ppw-service-text'>
                            <p className='ppw-service-name'>{reduxText[706]}</p>
                            <p className='ppw-service-description'>{reduxText[707]}</p>
                            <Button
                                text={reduxText[898]}
                                to={view_url_contact_form}
                            />
                        </div>
                    </div>
                    <div className='ppw-service-block ppw-service-block2'>
                        <div className='ppw-service-image'>
                            <ImageHelper
                                alt=''
                                dominant_color={reduxText[70800]}
                                src={reduxText[708]}
                            />
                        </div>
                        <div className='ppw-service-text'>
                            <p className='ppw-service-name'>{reduxText[709]}</p>
                            <p className='ppw-service-description'>{reduxText[710]}</p>
                            <Button
                                text={reduxText[899]}
                                to={view_url_main_amenagement}
                            />
                        </div>
                    </div>
                    <div className='ppw-newsletter-wrap'>
                        <h2 className='ppw-text'>{reduxText[711]}</h2>
                        <ImageHelper
                            alt=''
                            className='ppw-blog-image'
                            dominant_color={reduxText[53400]}
                            src={reduxText[534]}
                        />
                        <Button
                            text={reduxText[535]}
                            to={view_url_contact_form}
                        />
                    </div>
                </div>
                <Footer />
            </IonContent>
        </IonPage>
    )
}
