// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    Link,
} from 'react-router-dom'

// components
import {
    BookmarkButton,
    ImageHelper,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    PropertyListBlockSerializer,
} from 'serializers'

// props
type PropertyListBigBlockProps = {
    object: PropertyListBlockSerializer
}

// main
export const PropertyListBigBlock: React.FC<PropertyListBigBlockProps> = React.memo(({
    object,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <div className='property-list-big-block-web'>
            <Link
                className='plbbw-image-wrap'
                to={object.get_absolute_url}
            >
                <div className='plbbw-ratio-helper-web'>
                    <div
                        className='plbbw-rhw-image-wrap'
                    >
                        <ImageHelper
                            alt={object.title}
                            className='plbbw-rhw-image'
                            dominant_color={object.get_dominant_color}
                            src={object.get_image_lq}
                        />
                    </div>
                </div>
                <p className={`plbbw-transaction transaction-${object.transaction}`}>{reduxText[object.transaction === 1 ? 714 : 715]}</p>
                <div className='plbbw-hover' />
            </Link>
            <div className='plbbw-info'>
                <Link to={object.get_absolute_url}>
                    <p className='plbbw-name'>{object.title}</p>
                </Link>
                <p className='plbbw-price'>{object.get_price_first}{' € '}{object.get_price_type}</p>
                <p className='plbbw-area'>{object.get_area_total} m<sup>2</sup></p>
                <div className='plbbw-bookmark'>
                    <BookmarkButton
                        app='catalog'
                        model='property'
                        objectId={object.id}
                    />
                </div>
            </div>
        </div>
    )
})
