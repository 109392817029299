// react components
import React from 'react'

// components
import {
    ImageHelper,
} from 'components'

// serializers
import {
    MessageListBlockSerializer,
} from 'serializers'

// services
import {
    getDateWithType,
} from 'services'

// props
type MessageListBlockProps = {
    object: MessageListBlockSerializer
}

// main
export const MessageListBlock: React.FC<MessageListBlockProps> = React.memo(({
    object,
}) => {

    return (
        <div className='message-list-block-web'>
            <p className='mlbw-time'>{getDateWithType(object.sent_at, 't')}</p>
            <div className='mlbw-body-div'>
                <ImageHelper
                    alt={object.sender?.first_name}
                    className='mlbw-image'
                    src={object.sender?.userprofile?.get_image_lq}
                    dominant_color={object.sender?.userprofile?.get_dominant_color}
                />
                <div className='mlbw-body-wrap'>
                    <p className='mlbw-name'>{object.sender?.first_name}</p>
                    <p className='mlbw-body'>{object.body}</p>
                </div>
            </div>
        </div>
    )
})
