// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import Select from 'react-select'

// components
import {
    ErrorInput,
    MainFormLabel,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type SelectListInputProps = {
    apiUrl?: string | undefined
    clearable: boolean
    defaultMenuIsOpen?: boolean
    disabled?: boolean
    error: string | undefined
    helperText?: string
    label?: string
    multi?: boolean
    name: string
    onChange: React.Dispatch<any>
    optional?: boolean
    options?: any[]
    placeholder?: string
    staffOnly?: boolean
    value: any
}

// main
export const SelectListInput: React.FC<SelectListInputProps> = React.memo(({
    apiUrl,
    clearable,
    defaultMenuIsOpen,
    disabled,
    error,
    helperText,
    label,
    multi,
    name,
    onChange,
    optional,
    options,
    placeholder,
    staffOnly,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    const [newOptions, setNewOptions] = useState<any[]>(options || [])
    const [newValue, setValue] = useState<any>(value)

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        setValue(value)
    }, [value])

    useEffect(() => {
        if (options) setNewOptions(options)
    }, [options])

    function onInputChange(value: any) {
        try {
            if (value === newValue) return
            setValue(value)
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SelectListInput',
                'onInputChange',
            ))
        }
    }

    function getData() {
        try {
            if (options) return
            if (!apiUrl) return
            const listArray: any = []
            const getUrl = getApiUrl(apiUrl, reduxAuth)
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: getAxiosHeaders(reduxAuth, dispatch, 'SelectListInput'),
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (Array.isArray(response.data)) {
                        response.data.map((val: any) => {
                            listArray.push(val)
                            return false
                        })
                        setNewOptions(listArray)
                    }
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'SelectListInput',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'useEffect',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SelectListInput',
                'getData',
            ))
        }
    }

    const lightCustomStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            boxShadow: 0,
            borderColor: state.isFocused
                ? '#ed6363'
                : provided.borderColor,
            ':hover': {
                borderColor: state.isFocused
                    ? '#ed6363'
                    : provided.borderColor,
            }
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            zIndex: 9999,
        }),
        menuList: (provided: any, state: any) => ({
            ...provided,
            zIndex: 9999,
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            background: state.isSelected ? '#ed6363' : '',
            color: state.isSelected ? 'white' : '',
            ':hover': {
                ...provided[':hover'],
                cursor: 'pointer',
            },
        }),
    }

    return (
        <div className={`select-input-web ${disabled ? 'disabled' : ''}`}>
            {label && (
                <MainFormLabel
                    helperText={helperText}
                    label={label}
                    name={name}
                    optional={optional}
                    staffOnly={staffOnly}
                />
            )}
            <div className='form-input-wrap'>
                <div className='fiw-input'>
                    <Select
                        closeMenuOnSelect={!multi}
                        defaultMenuIsOpen={defaultMenuIsOpen}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        isClearable={clearable}
                        isDisabled={disabled}
                        isMulti={multi}
                        menuPlacement='auto'
                        name={name}
                        onChange={onInputChange}
                        options={newOptions}
                        placeholder={placeholder}
                        styles={lightCustomStyles}
                        value={newValue}
                    />
                </div>
                {helperText && (
                    <div className='fiw-helper'>
                        <p className='pre-line'>{helperText}</p>
                    </div>
                )}
            </div>
            <ErrorInput error={error} />
        </div>
    )
})
