// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    DokaModal,
} from '../../../@static/vendors/react-doka'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// props
type ImageCropInputPropertyProps = {
    disableDoka: () => void
    doka_output_data: any
    handleSubmit: React.Dispatch<any>
    original: string
    value: any
}

// main
export const ImageCropInputProperty: React.FC<ImageCropInputPropertyProps> = React.memo(({
    disableDoka,
    doka_output_data,
    handleSubmit,
    original,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [outputData, setOutputData] = useState<any>({})
    const [src, setSrc] = useState<any>(value)

    useEffect(() => {
        if (original) {
            setSrc(original)
            if (doka_output_data) {
                try {
                    setOutputData(JSON.parse(doka_output_data))
                } catch (error) {
                    if (process.env.NODE_ENV === 'development') console.log(error)
                }
            }
        }
    }, [original])

    function handleDokaCancel() {
        try {
            disableDoka()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImageCropInputProperty',
                'handleDokaCancel',
            ))
        }
    }

    function handleDokaConfirm(output: any) {
        try {
            handleSubmit(output)
            disableDoka()
            setOutputData(output.data)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImageCropInputProperty',
                'handleDokaConfirm',
            ))
        }
    }

    return (
        <DokaModal
            color={outputData.color}  // TO_CHECK
            crop={outputData.crop}
            filter={outputData.filter}  // TO_CHECK
            markup={outputData.markup}  // TO_CHECK
            onCancel={handleDokaCancel}
            onConfirm={handleDokaConfirm}
            outputData
            src={src}
            utils={['crop', 'filter', 'color', 'markup']}
            markupFontFamily={['Futura, Helvetica, Arial, Verdana']}
            markupFontFamilyOptions={[
                // ['Georgia', 'Georgia, serif'],
                ['Palatino Linotype', '\'Palatino Linotype\', \'Book Antiqua\', Palatino, serif'],
                // ['Times New Roman', '\'Times New Roman\', Times, serif'],
                // ['Futura', '\'FuturaPT-Book\', \'sans-serif\''],
                ['Arial', 'Arial, Helvetica, \'sans-serif\''],
                ['Arial Black', '\'Arial Black\', Gadget, \'sans-serif\''],
                ['Comic Sans MS', '\'Comic Sans MS\', cursive, \'sans-serif\''],
                ['Impact', 'Impact, Charcoal, \'sans-serif\''],
                // ['Lucida Sans Unicode', '\'Lucida Sans Unicode\', \'Lucida Grande\', \'sans-serif\''],
                ['Tahoma', 'Tahoma, Geneva, \'sans-serif\''],
                // ['Trebuchet MS', '\'Trebuchet MS\', Helvetica, \'sans-serif\''],
                ['Verdana', 'Verdana, Geneva, \'sans-serif\''],
                ['Courier New MS', '\'Courier New\', Courier, monospace'],
                ['Lucida Console', 'Monaco, monospace'],
            ]}
            cropAspectRatioOptions={[
                {
                    label: reduxText[610],
                    value: null
                },
                {
                    label: reduxText[611],
                    value: '1:1'
                },
                {
                    label: `${reduxText[612]} 5/4`,
                    value: '5:4'
                },
                {
                    label: `${reduxText[612]} 4/3`,
                    value: '4:3'
                },
                {
                    label: `${reduxText[612]} 3/2`,
                    value: '3:2'
                },
                {
                    label: `${reduxText[612]} 5/3`,
                    value: '5:3'
                },
                {
                    label: `${reduxText[612]} 16/9`,
                    value: '16:9'
                },
                {
                    label: `${reduxText[612]} 3/1`,
                    value: '3:1'
                },
                {
                    label: `${reduxText[613]} 5/4`,
                    value: '4:5'
                },
                {
                    label: `${reduxText[613]} 4/3`,
                    value: '3:4'
                },
                {
                    label: `${reduxText[613]} 3/2`,
                    value: '2:3'
                },
                {
                    label: `${reduxText[613]} 5/3`,
                    value: '3:5'
                },
                {
                    label: `${reduxText[613]} 16/9`,
                    value: '9:16'
                },
                {
                    label: `${reduxText[613]} 3/1`,
                    value: '1:3'
                }
            ]}
        />
    )
})
