// react components
import React from 'react'

// props
type ErrorInputProps = {
    error: string | undefined
}

// main
export const ErrorInput: React.FC<ErrorInputProps> = React.memo(({
    error,
}) => {

    if (error) {
        return (
            <span className='error-input-web'>{error}</span>
        )
    }
    return null
})
