// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    Link,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    view_url_article_list,
} from 'data'

// serializers
import {
    ArticleDetailBlockSerializer,
} from 'serializers'

// props
type ArticleDetailBlockProps = {
    object: ArticleDetailBlockSerializer
}

// main
export const ArticleDetailBlock: React.FC<ArticleDetailBlockProps> = React.memo(({
    object,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <div className='article-detail-block-web mb-container'>
            <Link
                className='adbw-back'
                to={view_url_article_list}
            >
                <span>{reduxText[545]}</span>
            </Link>
            <h1 className='adbw-name'>{object.title}</h1>
            <div
                className='adbw-content'
                dangerouslySetInnerHTML={{ __html: object.content }}
            />
        </div>
    )
})
