// react components
import React from 'react'
import {
    IonTextarea,
} from '@ionic/react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    ErrorInput,
    MainFormLabel,
} from 'components'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// props
type TextAreaInputProps = {
    disabled?: boolean
    error: string | undefined
    helperText?: string
    label?: string
    maxlength?: number
    name: string
    onChange: React.Dispatch<any>
    optional?: boolean
    placeholder?: string
    staffOnly?: boolean
    value: string
}

// main
export const TextAreaInput: React.FC<TextAreaInputProps> = React.memo(({
    disabled,
    error,
    helperText,
    label,
    maxlength,
    name,
    onChange,
    optional,
    placeholder,
    staffOnly,
    value,
}) => {

    const dispatch = useDispatch()

    function onInputChange(value: string | null | undefined) {
        try {
            onChange({
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TextAreaInput',
                'onInputChange',
            ))
        }
    }

    return (
        <div className='text-input-web'>
            {label && (
                <MainFormLabel
                    helperText={helperText}
                    label={label}
                    name={name}
                    optional={optional}
                    staffOnly={staffOnly}
                />
            )}
            <div className='form-input-wrap'>
                <div className='fiw-input'>
                    <IonTextarea
                        autoGrow
                        className='tiw-input'
                        disabled={disabled}
                        maxlength={maxlength || 5000}
                        name={name}
                        onIonChange={(e) => onInputChange(e.detail.value)}
                        placeholder={placeholder}
                        value={value}
                    />
                </div>
                {helperText && (
                    <div className='fiw-helper'>
                        <p className='pre-line'>{helperText}</p>
                    </div>
                )}
            </div>
            <ErrorInput error={error} />
        </div>
    )
})
