// react components
import React from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Footer,
    ImageHelper,
    SeoBlock,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    PropertyListFiltered,
} from 'pages'

// main
export const DarkKitchenPage: React.FC = () => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <IonPage className='main-page-web navbar-pt'>
            <SeoBlock pageId={25} />
            <IonContent>
                <div className='mpw-content mb-container'>
                    <div className='mpw-title-text-block'>
                        <h1 className='mb-main-title'>{reduxText[829]}</h1>
                        <p className='mb-main-text'>{reduxText[830]}</p>
                    </div>
                    <div className='property-section'>
                        <div className='mpw-title-text-block'>
                            <h2 className='mb-main-title'>{reduxText[889]}</h2>
                        </div>
                        <PropertyListFiltered activity={4} />
                    </div>
                    <div className='mpw-image-text-block'>
                        <div className='mpw-image-wrap'>
                            <ImageHelper
                                alt=''
                                className='mpw-image'
                                dominant_color={reduxText[83100]}
                                src={reduxText[831]}
                            />
                        </div>
                        <div className='mpw-text-wrap'>
                            <h2 className='mb-main-title split'>{reduxText[832]}</h2>
                            <p className='mb-main-text'>{reduxText[833]}</p>
                        </div>
                    </div>
                    <div className='mpw-title-text-block'>
                        <h2 className='mb-main-title'>{reduxText[834]}</h2>
                        <p className='mb-main-text'>{reduxText[835]}</p>
                    </div>
                    <div className='mpw-image-text-block reverse'>
                        <div className='mpw-image-wrap'>
                            <ImageHelper
                                alt=''
                                className='mpw-image'
                                dominant_color={reduxText[84400]}
                                src={reduxText[844]}
                            />
                        </div>
                        <div className='mpw-text-wrap'>
                            <h2 className='mb-main-title split'>{reduxText[836]}</h2>
                            <p className='mb-main-text'>{reduxText[837]}</p>
                        </div>
                    </div>
                    <div className='mpw-title-text-block'>
                        <h2 className='mb-main-title'>{reduxText[838]}</h2>
                        <p className='mb-main-text'>{reduxText[839]}</p>
                    </div>
                    <div className='mpw-image-text-block'>
                        <div className='mpw-image-wrap'>
                            <ImageHelper
                                alt=''
                                className='mpw-image'
                                dominant_color={reduxText[84500]}
                                src={reduxText[845]}
                            />
                        </div>
                        <div className='mpw-text-wrap'>
                            <h2 className='mb-main-title split'>{reduxText[840]}</h2>
                            <p className='mb-main-text'>{reduxText[841]}</p>
                        </div>
                    </div>
                    <div className='mpw-title-text-block'>
                        <h2 className='mb-main-title'>{reduxText[842]}</h2>
                        <p className='mb-main-text'>{reduxText[843]}</p>
                    </div>
                </div>
                <Footer />
            </IonContent>
        </IonPage>
    )
}
