// react components
import React from 'react'
import axios from 'axios'
import moment from 'moment'

// data
import {
    api_url_settings_last_login_form_update,
    reduxAuthFetchSettings,
    reduxAuthState,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
} from 'services'

// main
export function getAxiosHeaders(reduxAuth: reduxAuthState, dispatch: React.Dispatch<any>, getOrigin: string) {
    if (reduxAuth.authenticated && moment(reduxAuth.settings?.last_login).add(1, 'hours').utc().format() < moment.utc().format()) {
        const patchUrl = getApiUrl(`${api_url_settings_last_login_form_update}${reduxAuth.settings?.id}/`, reduxAuth)
        axios({
            headers: reduxAuth.axiosConfig.headers,
            method: 'patch',
            url: patchUrl,
        })
            .then((response) => {
                if (process.env.NODE_ENV === 'development') console.log(response)
                dispatch(reduxAuthFetchSettings(reduxAuth, dispatch))
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') console.log(error)
                axiosErrorHandler({
                    apiUrl: patchUrl,
                    component: 'getAxiosHeaders',
                    dispatch,
                    error,
                    reduxAuth,
                    reference: getOrigin,
                })
            })
    }
    return reduxAuth.axiosConfig.headers
}
