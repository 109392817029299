// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    IonCheckbox,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ErrorInput,
    Loader,
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
    api_url_auth_user_create,
    reduxModalErrorEventHandler,
} from 'data'

// pages
import {
    GoogleLoginBlock,
} from 'pages'

// services 
import {
    axiosErrorHandler,
    getApiUrl,
} from 'services'

// props
type SignupBlockProps = {
    emptyFields: number
    setEmptyField: any
}

// main
export const SignupBlock: React.FC<SignupBlockProps> = React.memo(({
    emptyFields,
    setEmptyField,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        setFields(fieldsInitial)
    }, [emptyFields])

    type errorsType = {
        email?: string
        non_field_errors?: string
        password2?: string
        password?: string
        username?: string
    }
    type fieldsType = {
        email: string
        password2: string
        password: string
        username: string
    }
    const errorsInitial = {
        email: '',
        non_field_errors: '',
        password2: '',
        password: '',
        username: '',
    }
    const fieldsInitial = {
        email: '',
        password2: '',
        password: '',
        username: '',
    }

    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)
    const [termAccepted, setTermAccepted] = useState<boolean>(false)
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setErrors({ ...errors, [name]: '' })
            setFields({ ...fields, [name]: event.value })
            setButtonDisabled(false)
            setEmptyField(Math.random())
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'SignupBlock',
                'handleInputChange',
            ))
        }
    }

    function onSubmit() {
        try {
            if (process.env.NODE_ENV === 'development') console.log('onSubmit')
            if (!fields.username) {
                setErrors({ ...errors, username: reduxText[589] })
            } else if (!fields.password) {
                setErrors({ ...errors, password: reduxText[589] })
            } else if (fields.username !== fields.email) {
                setButtonDisabled(true)
                setErrors({ ...errors, email: reduxText[782] })
            } else if (fields.password !== fields.password2) {
                setButtonDisabled(true)
                setErrors({ ...errors, password2: reduxText[783] })
            } else {
                setButtonDisabled(true)
                setIsLoading(true)

                // Construct FormData
                const formData = new FormData()

                // Fields
                formData.append('email', fields.email)
                formData.append('password', fields.password)
                formData.append('username', fields.username)
                const createUrl = getApiUrl(api_url_auth_user_create, reduxAuth)
                axios({
                    data: formData,
                    method: 'post',
                    url: createUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setFields(fieldsInitial)
                        setIsLoading(false)
                        setIsSuccess(true)
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        if (error.response) {
                            setErrors(error.response.data)
                        }
                        axiosErrorHandler({
                            apiUrl: createUrl,
                            component: 'SignupBlock',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'onSubmit',
                            skip401: true,
                        })
                    })
            }
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'SignupBlock',
                'onSubmit',
            ))
        }
    }

    return (
        <div className='login-block-web'>
            {isSuccess && (
                <div className='lbw-success-block'>
                    <h1 className='lbw-title'>{reduxText[483]}</h1>
                    <p>{reduxText[633]}</p>
                </div>
            )}
            {!isSuccess && (
                <React.Fragment>
                    <h1 className='lbw-title'>{reduxText[483]}</h1>
                    <GoogleLoginBlock setIsLoading={setIsLoading} />
                    <div className='lbw-separation'>
                        <div className='lbw-separation-line'>
                            <hr />
                        </div>
                        <p>{reduxText[488]}</p>
                        <div className='lbw-separation-line'>
                            <hr />
                        </div>
                    </div>
                    <TextInput
                        error={errors.username}
                        placeholder={reduxText[479]}
                        name='username'
                        onChange={handleInputChange}
                        type='email'
                        value={fields.username}
                    />
                    <TextInput
                        error={errors.email}
                        name='email'
                        onChange={handleInputChange}
                        placeholder={reduxText[494]}
                        type='email'
                        value={fields.email}
                    />
                    <TextInput
                        error={errors.password}
                        name='password'
                        onChange={handleInputChange}
                        placeholder={reduxText[480]}
                        type='password'
                        value={fields.password}
                    />
                    <TextInput
                        error={errors.password2}
                        name='password2'
                        onChange={handleInputChange}
                        placeholder={reduxText[495]}
                        type='password'
                        value={fields.password2}
                    />
                    {errors.non_field_errors && (
                        <ErrorInput
                            error={`${errors.non_field_errors} - ${reduxText[493]}`}
                        />
                    )}
                    <div className='lbw-terms'>
                        <IonCheckbox
                            mode='md'
                            onIonChange={(e) => setTermAccepted(e.detail.checked)}
                        />
                        <div className='lbw-terms-text' dangerouslySetInnerHTML={{ __html: reduxText[486] }} />
                    </div>
                    <Button
                        disabled={!(termAccepted && !buttonDisabled)}
                        onClick={onSubmit}
                        text={reduxText[487]}
                    />
                </React.Fragment>
            )}
            <Loader isOpen={isLoading} />
        </div>
    )
})
