// react components
import React from 'react'
import {
    IonContent,
    IonFooter,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'
import {
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
} from 'components'

// data
import {
    defaultReduxState,
    view_url_property_form,
} from 'data'

// props
type MatchParams = {
    id: string
    transactionId: string
}

// main
export const IntroPropertyPanel: React.FC = () => {

    const params = useParams<MatchParams>()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <React.Fragment>
            <IonContent>
                <div className='pfw-content'>
                    <div className='pfw-panel'>
                        <div className='intro-property-panel-web'>
                            <h1 className='ippw-title'>{reduxText[params.transactionId === '1' ? 498 : 595]}</h1>
                            <p className='ippw-description pre-line'>{reduxText[params.transactionId === '1' ? 499 : 596]}</p>
                        </div>
                    </div>
                </div>
            </IonContent>
            <IonFooter className='pfw-footer'>
                <div className='pfw-wrap'>
                    <span
                        className='pfw-back'
                        onClick={() => window.history.back()}
                    >
                        <i className='fa fa-chevron-left'></i>{reduxText[567]}
                    </span>
                    <Button
                        text={reduxText[568]}
                        to={`${view_url_property_form}${params.id}/adresse/${params.transactionId}/`}
                    />
                </div>
            </IonFooter>
        </React.Fragment>
    )
}
