// react components
import React, {
    useEffect,
    useState,
    useRef,
} from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import axios, {
    CancelTokenSource,
} from 'axios'
import L from 'leaflet'
import {
    FeatureGroup,
    Map,
    Popup,
    TileLayer,
} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import Search from 'react-leaflet-search'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Link,
} from 'react-router-dom'

// components
import {
    Button,
    Footer,
    ImageHelper,
    SelectListInput,
    SeoBlock,
} from 'components'

// data
import {
    api_url_article_list_home,
    api_url_property_list_home,
    api_url_property_list_map,
    defaultReduxState,
    map_attribution,
    map_url,
    reduxFormSetIsNewsletterOpen,
    reduxModalErrorEventHandler,
    select_url_property_type,
    select_url_transaction,
    view_url_article_list,
    view_url_contact_form,
    view_url_main_dark_kitchen,
    view_url_main_laboratoire_patisserie,
    view_url_main_local_avec_extraction,
    view_url_property_form,
    view_url_property_list,
} from 'data'

// pages
import {
    ArticleListBlock,
    PropertyListBigBlock,
    PropertyListMapBlock,
} from 'pages'

// serializers
import {
    ArticleListBlockSerializer,
    MainIdNameSerializer,
    PropertyListBlockSerializer,
    PropertyListMapSerializer,
} from 'serializers'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getMapList,
} from 'services'

// main
export const HomePage: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        getArticles()
        getProperties()
    }, [])

    type fieldsType = {
        property_type: MainIdNameSerializer | undefined
        transaction: MainIdNameSerializer | undefined
    }
    const fieldsInitial = {
        property_type: undefined,
        transaction: undefined,
    }

    const clusterRef = useRef(null)
    const groupRef = useRef<FeatureGroup>(null)
    const mapRef = useRef<Map>(null)

    const mylabMarker = L.icon({
        iconUrl: 'https://media.mylab.immo/global/map/marker.png',
        iconSize: [32, 42],
        iconAnchor: [16, 42],
        popupAnchor: [0, -42],
    })
    const mylabMarkerRound = L.icon({
        iconUrl: 'https://media.mylab.immo/global/map/marker-round.png',
        iconSize: [100, 100],
        iconAnchor: [50, 50],
        popupAnchor: [0, -50],
    })

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [articles, setArticles] = useState<ArticleListBlockSerializer[]>([])
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [properties, setProperties] = useState<PropertyListBlockSerializer[]>([])
    const [mapItem, setMapItem] = useState<PropertyListMapSerializer | undefined>(undefined)
    const [initialMapLoad, setInitialMapLoad] = useState<boolean>(true)

    useEffect(() => {
        onGetMapData()
    }, [
        fields,
        reduxAuth.settings?.id,
    ])

    function addMarkersCheck(data: PropertyListMapSerializer[]) {
        if (initialMapLoad) {
            setTimeout(function () {
                setInitialMapLoad(false)
                setTimeout(function () {
                    addMarkers(data)
                }, 1000)
            }, 1000)
        } else {
            addMarkers(data)
        }
    }

    function addMarkers(data: PropertyListMapSerializer[], notFit?: boolean, round?: boolean) {
        try {
            // @ts-ignore
            if (notFit) clusterRef.current?.leafletElement.clearLayers()
            const markers = L.markerClusterGroup()
            data.map((val) => {
                if (val.lat_lng) {
                    const marker = L.marker(
                        [Number(val.lat_lng.split(',')[0]), Number(val.lat_lng.split(',')[1])],
                        {
                            icon: round ? mylabMarkerRound : mylabMarker,
                        },
                    ).on(
                        'click',
                        () => handleMarkersClick(val),
                    )
                    // @ts-ignore
                    marker.ec_id = val.id
                    markers.addLayer(marker)
                }
                return false
            })
            // @ts-ignore
            clusterRef.current?.leafletElement.addLayers(markers)
            if (!notFit) handleFitBounds(data)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'HomePage',
                'addMarkers',
            ))
        }
    }

    function getArticles() {
        try {
            const getUrl = getApiUrl(api_url_article_list_home, reduxAuth)
            axios({
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setArticles(response.data)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'HomePage',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getArticles',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'HomePage',
                'getArticles',
            ))
        }
    }

    function getProperties() {
        try {
            const getUrl = getApiUrl(api_url_property_list_home, reduxAuth)
            axios({
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setProperties(response.data)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'HomePage',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getProperties',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'HomePage',
                'getProperties',
            ))
        }
    }

    function handleFitBounds(data: PropertyListMapSerializer[]) {
        try {
            if (data.length > 0) {
                const map = mapRef.current?.leafletElement
                const group = groupRef.current?.leafletElement
                if (group) map?.fitBounds(group?.getBounds())
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'HomePage',
                'handleFitBounds',
            ))
        }
    }

    function handleMarkersClick(marker: PropertyListMapSerializer) {
        try {
            setMapItem(marker)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainer',
                'handleMarkersClick',
            ))
        }
    }

    function handleInputChange(event: any) {
        try {
            setFields({
                ...fields,
                [event.name]: event.value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'HomePage',
                'handleInputChange',
            ))
        }
    }

    function onGetMapData() {
        try {
            let axiosUrl = getApiUrl(`${api_url_property_list_map}?`, reduxAuth)
            if (fields.transaction?.id) axiosUrl += `&transaction=${fields.transaction.id}`
            if (fields.property_type?.id) axiosUrl += `&property_type=${fields.property_type.id}`

            // @ts-ignore
            clusterRef.current?.leafletElement.clearLayers()
            getMapList(
                axiosUrl,
                reduxAuth,
                dispatch,
                addMarkersCheck,
                axiosCancelToken,
                setAxiosCancelToken,
                'HomePage',
            )
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'HomePage',
                'getListData',
            ))
        }
    }

    return (
        <IonPage className='main-page-web home navbar-pt'>
            <SeoBlock pageId={1} />
            <IonContent>
                <div className='mpw-content'>
                    <div className='banner-section'>
                        <ImageHelper
                            alt=''
                            className='banner-cover-image'
                            dominant_color={reduxText[6400]}
                            src={reduxText[64]}
                        />
                        <div className='banner-cover-text'>
                            <div className='mb-container'>
                                <p className='banner-text1'>{reduxText[413]}</p>
                                <p className='banner-text2'>{reduxText[414]}</p>
                                <div className='banner-button-wrap'>
                                    <Button
                                        text={reduxText[415]}
                                        to={view_url_contact_form}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mb-container'>
                        <div className='mpw-title-text-block'>
                            <h1 className='mb-main-title'>{reduxText[756]}</h1>
                            <p className='mb-main-text'>{reduxText[825]}</p>
                        </div>
                    </div>
                    <div className='offer-section'>
                        <div className='offer-item'>
                            <div>
                                <p className='offer-item-text1'>{reduxText[416]}</p>
                                <p className='offer-item-text2'>{reduxText[417]}</p>
                            </div>
                            <p className='offer-item-text3'>{reduxText[418]}</p>
                            <div>
                                <ImageHelper
                                    alt=''
                                    className='offer-item-image'
                                    dominant_color={reduxText[43400]}
                                    src={reduxText[434]}
                                />
                                <Button
                                    text={reduxText[419]}
                                    to={`${view_url_property_form}new/intro/1/`}
                                />
                            </div>
                        </div>
                        <div className='offer-item'>
                            <div>
                                <p className='offer-item-text1'>{reduxText[420]}</p>
                                <p className='offer-item-text2'>{reduxText[421]}</p>
                            </div>
                            <p className='offer-item-text3'>{reduxText[422]}</p>
                            <div>
                                <ImageHelper
                                    alt=''
                                    className='offer-item-image'
                                    dominant_color={reduxText[43300]}
                                    src={reduxText[433]}
                                />
                                <Button
                                    text={reduxText[438]}
                                    to={`${view_url_property_form}new/intro/2/`}
                                />
                            </div>
                        </div>
                        <div className='offer-item offer-item-3'>
                            <div>
                                <p className='offer-item-text1'>{reduxText[423]}</p>
                                <p className='offer-item-text2'>{reduxText[424]}</p>
                            </div>
                            <p className='offer-item-text3'>{reduxText[425]}</p>
                            <div>
                                <ImageHelper
                                    alt=''
                                    className='offer-item-image'
                                    dominant_color={reduxText[43200]}
                                    src={reduxText[432]}
                                />
                                <Button
                                    reverseColor
                                    text={reduxText[426]}
                                    to={view_url_contact_form}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mb-container'>
                        <div className='map-section'>
                            <div className='mpw-title-text-block'>
                                <h2 className='mb-main-title'>{reduxText[427]}</h2>
                                <p className='mb-main-text'>{reduxText[826]}</p>
                            </div>
                            <div className='map-block'>
                                <div className='map-filters'>
                                    <div>
                                        <SelectListInput
                                            apiUrl={select_url_property_type}
                                            clearable
                                            error={undefined}
                                            name='property_type'
                                            onChange={handleInputChange}
                                            placeholder={reduxText[584]}
                                            value={fields.property_type || ''}
                                        />
                                        <SelectListInput
                                            apiUrl={select_url_transaction}
                                            clearable
                                            error={undefined}
                                            name='transaction'
                                            onChange={handleInputChange}
                                            placeholder={reduxText[670]}
                                            value={fields.transaction || ''}
                                        />
                                        <Button
                                            text={reduxText[429]}
                                            to={view_url_property_list}
                                        />
                                    </div>
                                </div>
                                <div className='map mb-leaflet'>
                                    {!initialMapLoad && (
                                        <Map
                                            boxZoom
                                            center={{ lat: 0, lng: 0 }}
                                            maxZoom={16}
                                            minZoom={1}
                                            preferCanvas
                                            ref={mapRef}
                                            scrollWheelZoom={false}
                                            zoom={2}
                                        >
                                            <TileLayer
                                                attribution={map_attribution}
                                                tileSize={512}
                                                url={map_url}
                                                zoomOffset={-1}
                                            />
                                            <FeatureGroup ref={groupRef}>
                                                <MarkerClusterGroup
                                                    ref={clusterRef}
                                                    chunkedLoading
                                                    maxClusterRadius={50}
                                                    onMarkerClick={(marker: PropertyListMapSerializer) => handleMarkersClick(marker)}
                                                    removeOutsideVisibleBounds
                                                />
                                                <Popup>
                                                    {mapItem && (
                                                        <PropertyListMapBlock
                                                            object={mapItem}
                                                        />
                                                    )}
                                                </Popup>
                                            </FeatureGroup>
                                            <Search
                                                className='tlcw-map-searchbox'
                                                closeResultsOnClick={true}
                                                inputPlaceholder={reduxText[774]}
                                                position='topleft'
                                                showMarker={false}
                                                zoom={10}
                                            />
                                        </Map>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='property-section'>
                            <div className='mpw-title-text-block'>
                                <h2 className='mb-main-title'>{reduxText[430]}</h2>
                                <p className='mb-main-text'>{reduxText[827]}</p>
                            </div>
                            <div className='property-list'>
                                {properties.map((val) => (
                                    <PropertyListBigBlock
                                        key={val.id}
                                        object={val}
                                    />
                                ))}
                            </div>
                            <Button
                                text={reduxText[431]}
                                to={view_url_property_list}
                            />
                        </div>
                        <div className='service-section'>
                            <div className='mpw-image-text-block'>
                                <div className='mpw-image-wrap'>
                                    <ImageHelper
                                        alt=''
                                        className='mpw-image'
                                        dominant_color={reduxText[43500]}
                                        src={reduxText[435]}
                                    />
                                </div>
                                <div className='mpw-text-wrap purchase'>
                                    <h2 className='mb-main-title split'>{reduxText[439]}</h2>
                                    <p className='mb-main-text'>{reduxText[440]}</p>
                                    <Button
                                        text={reduxText[441]}
                                        to={view_url_property_list}
                                    />
                                </div>
                            </div>
                            <div className='mpw-title-text-block block2'>
                                <h2 className='mb-main-title'>{reduxText[442]}</h2>
                            </div>
                            <div className='mpw-image-text-block reverse'>
                                <div className='mpw-image-wrap'>
                                    <ImageHelper
                                        alt=''
                                        className='mpw-image'
                                        dominant_color={reduxText[43600]}
                                        src={reduxText[436]}
                                    />
                                </div>
                                <div className='mpw-text-wrap'>
                                    <p className='mb-main-text'>{reduxText[443]}</p>
                                </div>
                            </div>
                            <p className='mb-main-text'>{reduxText[828]}</p>
                            <div className='mpw-image-text-block hpw-service-block3'>
                                <div className='mpw-image-wrap'>
                                    <ImageHelper
                                        alt=''
                                        className='mpw-image'
                                        dominant_color={reduxText[43700]}
                                        src={reduxText[437]}
                                    />
                                </div>
                                <div className='mpw-text-wrap travaux'>
                                    <h2 className='mb-main-title split'>{reduxText[445]}</h2>
                                    <p className='mb-main-text'>{reduxText[446]}</p>
                                    <Button
                                        href='http://mylab-travaux.com/'
                                        text={reduxText[447]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='page-section'>
                            <div className='mpw-title-text-block'>
                                <h2 className='mb-main-title'>{reduxText[884]}</h2>
                            </div>
                            <div className='image-square-list'>
                                <div className='isl-wrap'>
                                    <Link
                                        className='isl-image-wrap'
                                        to={view_url_main_local_avec_extraction}
                                    >
                                        <div className='isl-ratio-helper'>
                                            <div className='isl-rhw-image-wrap'>
                                                <ImageHelper
                                                    alt=''
                                                    className='isl-rhw-image'
                                                    dominant_color={reduxText[90100]}
                                                    src={reduxText[901]}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                    <div className='isl-text-wrap'>
                                        <Link
                                            className='isl-title'
                                            to={view_url_main_local_avec_extraction}
                                        >
                                            {reduxText[885]}
                                        </Link>
                                        <p className='isl-description'>{reduxText[886]}</p>
                                    </div>
                                </div>
                                <div className='isl-wrap'>
                                    <Link
                                        className='isl-image-wrap'
                                        to={view_url_main_laboratoire_patisserie}
                                    >
                                        <div className='isl-ratio-helper'>
                                            <div className='isl-rhw-image-wrap'>
                                                <ImageHelper
                                                    alt=''
                                                    className='isl-rhw-image'
                                                    dominant_color={reduxText[90200]}
                                                    src={reduxText[902]}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                    <div className='isl-text-wrap'>
                                        <Link
                                            className='isl-title'
                                            to={view_url_main_laboratoire_patisserie}
                                        >
                                            {reduxText[904]}
                                        </Link>
                                        <p className='isl-description'>{reduxText[887]}</p>
                                    </div>
                                </div>
                                <div className='isl-wrap'>
                                    <Link
                                        className='isl-image-wrap'
                                        to={view_url_main_dark_kitchen}
                                    >
                                        <div className='isl-ratio-helper'>
                                            <div className='isl-rhw-image-wrap'>
                                                <ImageHelper
                                                    alt=''
                                                    className='isl-rhw-image'
                                                    dominant_color={reduxText[90300]}
                                                    src={reduxText[903]}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                    <div className='isl-text-wrap'>
                                        <Link
                                            className='isl-title'
                                            to={view_url_main_dark_kitchen}
                                        >
                                            {reduxText[905]}
                                        </Link>
                                        <p className='isl-description'>{reduxText[888]}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='blog-section'>
                            <p className='mb-main-title'>{reduxText[448]}</p>
                            <div className='hpw-blog-list'>
                                {articles.map((val) => (
                                    <ArticleListBlock
                                        key={val.id}
                                        object={val}
                                    />
                                ))}
                            </div>
                            <Button
                                text={reduxText[449]}
                                to={view_url_article_list}
                            />
                        </div>
                        <div className='newsletter-section'>
                            <p className='newsletter-text'>{reduxText[450]}</p>
                            <p className='newsletter-description'>{reduxText[451]}</p>
                            <ImageHelper
                                alt=''
                                className='newsletter-image'
                                dominant_color={reduxText[45200]}
                                src={reduxText[452]}
                            />
                            <Button
                                onClick={() => dispatch(reduxFormSetIsNewsletterOpen(true))}
                                text={reduxText[453]}
                            />
                        </div>
                    </div>
                </div>
                <Footer />
            </IonContent>
        </IonPage>
    )
}
