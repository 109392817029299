// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDropzone,
} from 'react-dropzone'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    DokaModal,
    toURL,
} from '../../../@static/vendors/react-doka'

// components
import {
    ErrorInput,
    MainFormLabel,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
} from 'data'

// props
type ImageCropInputProps = {
    doka_output_data: any
    error: string | undefined
    helperText?: string
    label?: string
    name: string
    onChange: React.Dispatch<any>
    optional?: boolean
    original: string
    staffOnly?: boolean
    value: any
}

// main
export const ImageCropInput: React.FC<ImageCropInputProps> = React.memo(({
    doka_output_data,
    error,
    helperText,
    label,
    name,
    onChange,
    optional,
    original,
    staffOnly,
    value,
}) => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const mask = (root: any, setInnerHTML: any) => {
        // https://pqina.nl/doka/docs/patterns/api/doka-instance/#setting-the-crop-mask
        setInnerHTML(
            root,
            `
				<mask id="my-mask">
					<rect x="0" y="0" width="100%" height="100%" fill="white"/>
					<circle cx="50%" cy="50%" r="50%" fill="black"/>
				</mask>
				<rect fill="rgba(255,255,255,.3125)" x="0" y="0" width="100%" height="100%" mask="url(#my-mask)"/>
				<circle cx="50%" cy="50%" r="50%" fill="transparent" stroke-width="1" stroke="#fff"/>
			`,
        )
    }

    const [enabled, setEnabled] = useState<boolean>(false)
    const [outputData, setOutputData] = useState<any>({})
    const [result, setResult] = useState<any>(undefined)
    const [src, setSrc] = useState<any>(value)
    const [status, setStatus] = useState<string>('add')

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
        },
        maxSize: 50000000,
        onDrop: acceptedFiles => {
            const files = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))
            setEnabled(true)
            onChange({
                doka_image: acceptedFiles[0],
                doka_output_data: '',
                name: name,
                value: acceptedFiles[0],
            })
            setSrc(files[0]?.preview)
        }
    })

    useEffect(() => {
        if (original) {
            setSrc(original)
            if (doka_output_data) {
                try {
                    setOutputData(JSON.parse(doka_output_data))
                } catch (error) {
                    if (process.env.NODE_ENV === 'development') console.log(error)
                }
            }
            setStatus('edit')
        }
    }, [original])

    function handleToggle() {
        try {
            setEnabled(!enabled)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImageCropInput',
                'handleToggle',
            ))
        }
    }

    function handleDokaConfirm(output: any) {
        try {
            setEnabled(false)
            setResult(output.file)
            setOutputData(output.data)
            setStatus('edit')
            // @ts-ignore
            const previewImage = output.file ? toURL(output.file) : (value.preview || value)
            onChange({
                doka_image: output.file,
                doka_output_data: output.data,
                get_image_hq: previewImage,
                get_image_lq: previewImage,
                get_image_mq: previewImage,
                name: name,
                value: value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImageCropInput',
                'handleDokaConfirm',
            ))
        }
    }

    function handleDokaCancel() {
        try {
            setEnabled(false)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImageCropInput',
                'handleDokaCancel',
            ))
        }
    }

    return (
        <div className='image-crop-input-web'>
            {label && (
                <MainFormLabel
                    helperText={helperText}
                    label={label}
                    name={name}
                    optional={optional}
                    staffOnly={staffOnly}
                />
            )}
            {enabled && (
                <DokaModal
                    color={outputData.color}  // TO_CHECK
                    crop={outputData.crop}
                    cropAspectRatio={1}
                    cropMask={mask}
                    filter={outputData.filter}  // TO_CHECK
                    markup={outputData.markup}  // TO_CHECK
                    onCancel={handleDokaCancel}
                    onConfirm={handleDokaConfirm}
                    outputData
                    src={src}
                    utils={['crop', 'filter', 'color', 'markup']}
                    markupFontFamily={['Futura, Helvetica, Arial, Verdana']}
                    markupFontFamilyOptions={[
                        // ['Georgia', 'Georgia, serif'],
                        ['Palatino Linotype', '\'Palatino Linotype\', \'Book Antiqua\', Palatino, serif'],
                        // ['Times New Roman', '\'Times New Roman\', Times, serif'],
                        // ['Futura', '\'FuturaPT-Book\', \'sans-serif\''],
                        ['Arial', 'Arial, Helvetica, \'sans-serif\''],
                        ['Arial Black', '\'Arial Black\', Gadget, \'sans-serif\''],
                        ['Comic Sans MS', '\'Comic Sans MS\', cursive, \'sans-serif\''],
                        ['Impact', 'Impact, Charcoal, \'sans-serif\''],
                        // ['Lucida Sans Unicode', '\'Lucida Sans Unicode\', \'Lucida Grande\', \'sans-serif\''],
                        ['Tahoma', 'Tahoma, Geneva, \'sans-serif\''],
                        // ['Trebuchet MS', '\'Trebuchet MS\', Helvetica, \'sans-serif\''],
                        ['Verdana', 'Verdana, Geneva, \'sans-serif\''],
                        ['Courier New MS', '\'Courier New\', Courier, monospace'],
                        ['Lucida Console', 'Monaco, monospace'],
                    ]}
                    cropAspectRatioOptions={[
                        {
                            label: reduxText[610],
                            value: null
                        },
                        {
                            label: reduxText[611],
                            value: '1:1'
                        },
                        {
                            label: `${reduxText[612]} 5/4`,
                            value: '5:4'
                        },
                        {
                            label: `${reduxText[612]} 4/3`,
                            value: '4:3'
                        },
                        {
                            label: `${reduxText[612]} 3/2`,
                            value: '3:2'
                        },
                        {
                            label: `${reduxText[612]} 5/3`,
                            value: '5:3'
                        },
                        {
                            label: `${reduxText[612]} 16/9`,
                            value: '16:9'
                        },
                        {
                            label: `${reduxText[612]} 3/1`,
                            value: '3:1'
                        },
                        {
                            label: `${reduxText[613]} 5/4`,
                            value: '4:5'
                        },
                        {
                            label: `${reduxText[613]} 4/3`,
                            value: '3:4'
                        },
                        {
                            label: `${reduxText[613]} 3/2`,
                            value: '2:3'
                        },
                        {
                            label: `${reduxText[613]} 5/3`,
                            value: '3:5'
                        },
                        {
                            label: `${reduxText[613]} 16/9`,
                            value: '9:16'
                        },
                        {
                            label: `${reduxText[613]} 3/1`,
                            value: '1:3'
                        }
                    ]}
                />
            )}
            {status === 'edit' && (
                <div className='iciw-image-wrap'>
                    {result
                        ? (
                            <img
                                alt=''
                                className='iciw-image profile-pic'
                                // @ts-ignore
                                src={toURL(result)}
                            />
                        ) : (
                            <img
                                alt=''
                                className='iciw-image profile-pic'
                                src={value.preview || value}
                            />
                        )}
                </div>
            )}
            {status === 'add' && (
                <div {...getRootProps({ className: 'iciw-dropzone-input-web' })}>
                    <div className='iciw-input'>
                        <span className='fa fa-cloud-upload' />
                    </div>
                    <input {...getInputProps()} />
                </div>
            )}
            {status === 'edit' && (
                <div className='iciw-buttons'>
                    <div {...getRootProps({ className: 'iciw-dropzone-input-web' })}>
                        <span className='iciw-button-link'>{reduxText[679]}</span>
                        <input {...getInputProps()} />
                    </div>
                    <div className='iciw-dropzone-input-web'>
                        <span
                            className='iciw-button-link'
                            onClick={handleToggle}
                        >
                            {reduxText[680]}
                        </span>
                    </div>
                </div>
            )}
            <ErrorInput error={error} />
        </div>
    )
})
