// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ErrorInput,
    Footer,
    Loader,
    SeoBlock,
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
    api_url_auth_password_reset,
    reduxModalErrorEventHandler,
} from 'data'

// services 
import {
    axiosErrorHandler,
    getApiUrl,
} from 'services'

// main
export const PasswordResetPage: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    type errorsType = {
        detail?: string
        non_field_errors?: string
        email?: string
    }
    type fieldsType = {
        email: string
    }
    const errorsInitial = {
        non_field_errors: '',
        email: '',
    }
    const fieldsInitial = {
        email: '',
    }

    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)

    function handleInputChange(event: any) {
        try {
            const name = event.name
            setButtonDisabled(false)
            setErrors({ ...errors, [name]: '' })
            setFields({ ...fields, [name]: event.value })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PasswordResetPage',
                'handleInputChange',
            ))
        }
    }

    function onSubmit() {
        try {
            if (process.env.NODE_ENV === 'development') console.log('onSubmit')
            if (!fields.email) {
                setErrors({ ...errors, email: reduxText[589] })
            } else {
                setButtonDisabled(true)
                setIsLoading(true)

                // Construct FormData
                const formData = new FormData()

                // Fields
                formData.append('email', fields.email)
                const createUrl = getApiUrl(api_url_auth_password_reset, reduxAuth)
                axios({
                    data: formData,
                    method: 'post',
                    url: createUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setFields(fieldsInitial)
                        setIsLoading(false)
                        setIsSuccess(true)
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        if (error.response) {
                            setErrors(error.response.data)
                        }
                        axiosErrorHandler({
                            apiUrl: createUrl,
                            component: 'PasswordResetPage',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'onSubmit',
                        })
                    })
            }
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'PasswordResetPage',
                'onSubmit',
            ))
        }

    }

    return (
        <IonPage className='auth-page-web navbar-pt'>
            <SeoBlock pageId={18} />
            <Loader isOpen={isLoading} />
            <IonContent>
                <div className='apw-content mb-container'>
                    <div className='apw-left'>
                        <div className='login-block-web'>
                            <h1 className='lbw-title'>{reduxText[797]}</h1>
                            <TextInput
                                error={errors.email}
                                label={reduxText[788]}
                                name='email'
                                onChange={handleInputChange}
                                value={fields.email}
                            />
                            {errors.non_field_errors && (
                                <ErrorInput
                                    error={errors.non_field_errors || errors.detail}
                                />
                            )}
                            <Button
                                disabled={buttonDisabled}
                                onClick={onSubmit}
                                text={reduxText[787]}
                            />
                            {isSuccess && (
                                <div className='success-registration'>
                                    <div className='success-message pre-line'>
                                        <p>{reduxText[789]}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Footer />
            </IonContent>
        </IonPage>
    )
}
