// react components
import React, {
    useState,
} from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
} from 'data'


// props
type ImageHelperProps = {
    alt: string | undefined
    className?: string
    dominant_color: string | undefined
    minHeight?: string
    onClick?: any
    src: string | undefined
}

// main
export const ImageHelper: React.FC<ImageHelperProps> = React.memo(({
    alt,
    className,
    dominant_color,
    minHeight,
    onClick,
    src,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [styles, setStyles] = useState<any>({
        backgroundColor: `rgb${dominant_color}`,
        minHeight: minHeight || undefined,
        objectPosition: '-100vw',
    })

    return (
        <img
            alt={src ? (alt || 'Mylab Immo') : ''}
            className={`${className || ''}`}
            onClick={onClick}
            onContextMenu={(e) => e.preventDefault()}
            onDragStart={(e) => e.preventDefault()}
            onLoad={() => setStyles(undefined)}
            onError={(e: any) => {
                setStyles({
                    backgroundColor: `rgb${dominant_color}`,
                    minHeight: minHeight || undefined,
                    objectPosition: '-100vw',
                })
                e.target.alt = ''
            }}
            src={src || reduxText[712]}
            style={styles}
        />
    )
})
