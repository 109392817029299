// react components
import React, {
    useState,
} from 'react'
import {
    IonAlert,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    NavLink,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    reduxAuthLogout,
    view_url_profile_detail,
    view_url_profile_property_favourite_list,
    view_url_profile_property_list,
    view_url_room_list,
} from 'data'

// main
export const ProfileHeader: React.FC = () => {

    const dispatch = useDispatch()
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLogoutOpen, setIsLogoutOpen] = useState<boolean>(false)

    // Extra
    const logoutAlertButtons = [
        {
            text: reduxText[743],
            role: 'cancel',
        },
        {
            text: reduxText[742],
            handler: () => {
                dispatch(reduxAuthLogout())
            }
        },
    ]

    return (
        <div className='profile-header-web'>
            <NavLink
                className='phw-link'
                exact
                to={view_url_profile_property_list}
            >
                {reduxText[622]}
            </NavLink>
            <NavLink
                className='phw-link'
                exact
                to={view_url_profile_property_favourite_list}
            >
                {reduxText[623]}
            </NavLink>
            <NavLink
                className='phw-link'
                exact
                to={view_url_room_list}
            >
                {reduxText[624]}
            </NavLink>
            <NavLink
                className='phw-link'
                exact
                to={view_url_profile_detail}
            >
                {reduxText[625]}
            </NavLink>
            <span
                className='phw-link'
                onClick={() => setIsLogoutOpen(true)}
            >
                {reduxText[626]}
            </span>
            <IonAlert
                buttons={logoutAlertButtons}
                header={reduxText[740]}
                isOpen={isLogoutOpen}
                message={reduxText[741]}
                onDidDismiss={() => setIsLogoutOpen(false)}
            />
        </div>
    )
}
