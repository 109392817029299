// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    IonContent,
    IonFooter,
} from '@ionic/react'
import axios from 'axios'
import {
    useHistory,
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    api_url_property_detail,
    api_url_property_form_publish,
    reduxFormSetRefresh,
    reduxFormSetSuccessModal,
    reduxModalErrorEventHandler,
    view_url_profile_property_list,
    view_url_property_detail,
    view_url_property_form,
} from 'data'

// pages
import {
    PropertyDetailBlock,
} from 'pages'

// serializers
import {
    PropertyDetailBlockSerializer,
} from 'serializers'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
} from 'services'

// props
type MatchParams = {
    id: string
}

// main
export const PreviewPropertyPanel: React.FC = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [object, setObject] = useState<PropertyDetailBlockSerializer | undefined>(undefined)

    useEffect(() => {
        if (params.id === 'new') {
            history.replace(`${view_url_property_form}${params.id}/intro/`)
        }
        getData()
    }, [
        params.id,
        reduxAuth.settings?.id,
    ])

    function getData() {
        try {
            setIsLoading(true)
            const getUrl = getApiUrl(`${api_url_property_detail}${params.id}/`, reduxAuth) // Keep the last '/' for Safari !!!!!!!
            if (process.env.NODE_ENV === 'development') console.log(getUrl)
            axios({
                headers: getAxiosHeaders(reduxAuth, dispatch, 'PreviewPropertyPanel'),
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setIsLoading(false)
                    setObject(response.data)
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'PreviewPropertyPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getData',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'PreviewPropertyPanel',
                'getData',
            ))
        }
    }

    function handlePublish(active: boolean) {
        try {
            if (active) {
                const formData: any = new FormData()
                formData.append('published', active)
                const activeUrl = getApiUrl(`${api_url_property_form_publish}${params.id}/`, reduxAuth)
                axios({
                    data: formData,
                    headers: getAxiosHeaders(reduxAuth, dispatch, 'PreviewPropertyPanel'),
                    method: 'put',
                    url: activeUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        history.push(`${view_url_property_detail}${params.id}/`)
                        dispatch(reduxFormSetRefresh('refreshDetail'))
                        dispatch(reduxFormSetRefresh('refreshProfile'))
                        dispatch(reduxFormSetSuccessModal(true, `${reduxText[752]} ${reduxAuth.settings?.first_name}`, reduxText[753], reduxText[749], reduxText[746]))
                    })
                    .catch((error) => {
                        axiosErrorHandler({
                            apiUrl: activeUrl,
                            component: 'PreviewPropertyPanel',
                            dispatch,
                            error,
                            reduxAuth,
                            reference: 'handlePublish',
                        })
                    })
            } else {
                history.push(view_url_profile_property_list)
                dispatch(reduxFormSetRefresh('refreshDetail'))
                dispatch(reduxFormSetRefresh('refreshProfile'))
            }

        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PreviewPropertyPanel',
                'handlePublish',
            ))
        }
    }

    return (
        <React.Fragment>
            <IonContent>
                {object && (
                    <PropertyDetailBlock
                        object={object}
                        preview
                    />
                )}
            </IonContent>
            <IonFooter className='pfw-footer'>
                <div className='pfw-wrap'>
                    <span
                        className='pfw-back'
                        onClick={() => window.history.back()}
                    >
                        <i className='fa fa-chevron-left'></i>{reduxText[567]}
                    </span>
                    <div className='pfw-button-wrap'>
                        <Button
                            onClick={() => handlePublish(false)}
                            text={reduxText[620]}
                        />
                        <Button
                            onClick={() => handlePublish(true)}
                            text={reduxText[621]}
                        />
                    </div>
                </div>
            </IonFooter>
            <Loader isOpen={isLoading} />
        </React.Fragment>
    )
}
