// react components
import {
    GoogleAuth,
} from '@codetrix-studio/capacitor-google-auth'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import localforage from 'localforage'

// data
import {
    REDUX_AUTH_FETCH_ACTIONS,
    REDUX_AUTH_FETCH_SETTINGS,
    REDUX_AUTH_LOGIN_FULFILLED,
    REDUX_AUTH_LOGIN_REJECTED,
    REDUX_AUTH_GOOGLE_AUTH_FULFILLED,
    REDUX_AUTH_GOOGLE_AUTH_REJECTED,
    REDUX_AUTH_LOGOUT,
    REDUX_AUTH_SET_ACTION,
} from './mb_actions'
import {
    reduxAuthState,
} from './mb_state'
import {
    api_url_settings_last_login_form_update,
} from 'data'

// services
import {
    deleteCookie,
    getCookie,
} from 'services'

// constants
let apiUrlTemp: string
if (process.env.NODE_ENV !== 'production') {
    apiUrlTemp = '//localhost:8004/'
    // apiUrlTemp = 'https://api.mylab.immo/'
} else {
    apiUrlTemp = 'https://api.mylab.immo/'
}

const initialState: reduxAuthState = {
    access: getCookie('mb_reduxAuthAccess', 'json') || {},
    actions: {
        bookmark: [],
    },
    apiRootUrl: getCookie('mb_reduxAuthApiRootUrl', 'string') || apiUrlTemp,
    authenticated: getCookie('mb_reduxAuthAuthenticated', 'boolean') || false,
    axiosConfig: getCookie('mb_reduxAuthAxiosConifg', 'json') || {},
    errors: {},
    settings: getCookie('mb_reduxAuthSettings', 'json') || undefined,
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case REDUX_AUTH_FETCH_ACTIONS:
            return {
                ...state,
                actions: action.payload,
            }
        case REDUX_AUTH_FETCH_SETTINGS:
            return {
                ...state,
                settings: action.payload,
            }
        case REDUX_AUTH_LOGIN_FULFILLED:
            axios({
                headers: { Authorization: `JWT ${action.payload.access}` },
                method: 'patch',
                url: `${state.apiRootUrl}${api_url_settings_last_login_form_update}${action.settings.id}/`,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                })
                .catch((error) => {
                    if (process.env.NODE_ENV === 'development') console.log(error)
                })
            return {
                ...state,
                access: {
                    mb_token_type: 'django',
                    token: action.payload.access,
                    ...jwtDecode<any>(action.payload.access),
                },
                authenticated: true,
                axiosConfig: {
                    headers: {
                        Authorization: `JWT ${action.payload.access}`,
                    },
                },
                errors: {},
                settings: action.settings,
            }
        case REDUX_AUTH_LOGIN_REJECTED:
            if (process.env.NODE_ENV === 'development') console.log(action)
            return {
                ...state,
                access: {},
                authenticated: false,
                axiosConfig: {},
                errors: {
                    isError: true,
                    loginAuthError: action.payload.response?.data,
                    appleAuthError: false,
                    googleAuthError: false,
                },
                settings: undefined,
            }
        case REDUX_AUTH_GOOGLE_AUTH_FULFILLED:

            // Update last login
            axios({
                headers: { Authorization: `Bearer ${action.payload.token}` },
                method: 'patch',
                url: `${state.apiRootUrl}${api_url_settings_last_login_form_update}${action.payload.settings.id}/`,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                })
                .catch((error) => {
                    if (process.env.NODE_ENV === 'development') console.log(error)
                })
            return {
                ...state,
                access: {
                    mb_token_type: 'google',
                    exp: action.payload.expires,
                    token: action.payload.token,
                },
                authenticated: true,
                axiosConfig: {
                    headers: {
                        Authorization: `Bearer ${action.payload.token}`,
                    },
                },
                errors: action.payload.response || { non_field_errors: action.payload.statusText },
                settings: action.payload.settings,
            }
        case REDUX_AUTH_GOOGLE_AUTH_REJECTED:
            return {
                ...state,
                access: {},
                authenticated: false,
                axiosConfig: {},
                errors: {
                    isError: true,
                    googleAuthError: action.payload,
                },
                settings: undefined,
            }
        case REDUX_AUTH_LOGOUT:
            if (state.access?.mb_token_type === 'google') {
                GoogleAuth.signOut()
            }
            deleteCookie('mb_reduxAuthAccess')
            deleteCookie('mb_reduxAuthAuthenticated')
            deleteCookie('mb_reduxAuthAxiosConifg')
            deleteCookie('mb_reduxAuthSettings')
            if (action.payload) {
                localStorage.clear()
                localforage.clear().then(() => {
                    window.location.replace('/')
                }).catch((err) => {
                    console.log(err)
                })
            }
            return {
                ...state,
                access: {},
                authenticated: false,
                axiosConfig: {},
                errors: {},
                settings: undefined,
            }
        case REDUX_AUTH_SET_ACTION: {
            let newArray: string[] = []
            if (action.payload.action === 'a') {
                // @ts-ignore
                newArray = state.actions[action.payload.type]
                newArray.push(action.payload.objectId)
            } else {
                // @ts-ignore
                state.actions[action.payload.type].map((val) => {
                    if (val !== action.payload.objectId) newArray.push(val)
                    return false
                })
            }
            return {
                ...state,
                actions: {
                    ...state.actions,
                    [action.payload.type]: newArray,
                },
            }
        }
        default:
            return state
    }
}
