// react components
import React from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useSelector,
} from 'react-redux'

// components
import {
    Footer,
    ImageHelper,
    SeoBlock,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// pages
import {
    PropertyListFiltered,
} from 'pages'

// main
export const LocalAvecExtractionPage: React.FC = () => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <IonPage className='main-page-web navbar-pt'>
            <SeoBlock pageId={27} />
            <IonContent>
                <div className='mpw-content mb-container'>
                    <div className='mpw-title-text-block'>
                        <h1 className='mb-main-title'>{reduxText[866]}</h1>
                        <p className='mb-main-text'>{reduxText[867]}</p>
                    </div>
                    <div className='property-section'>
                        <div className='mpw-title-text-block'>
                            <h2 className='mb-main-title'>{reduxText[893]}</h2>
                        </div>
                        <PropertyListFiltered prestation={3} />
                    </div>
                    <div className='mpw-image-text-block'>
                        <div className='mpw-image-wrap'>
                            <ImageHelper
                                alt=''
                                className='mpw-image'
                                dominant_color={reduxText[87800]}
                                src={reduxText[878]}
                            />
                        </div>
                        <div className='mpw-text-wrap'>
                            <h2 className='mb-main-title split'>{reduxText[868]}</h2>
                            <p className='mb-main-text'>{reduxText[869]}</p>
                        </div>
                    </div>
                    <div className='mpw-title-text-block'>
                        <h2 className='mb-main-title'>{reduxText[870]}</h2>
                        <p className='mb-main-text'>{reduxText[871]}</p>
                    </div>
                    <div className='mpw-image-text-block reverse'>
                        <div className='mpw-image-wrap'>
                            <ImageHelper
                                alt=''
                                className='mpw-image'
                                dominant_color={reduxText[87900]}
                                src={reduxText[879]}
                            />
                        </div>
                        <div className='mpw-text-wrap'>
                            <h2 className='mb-main-title split'>{reduxText[872]}</h2>
                            <p className='mb-main-text'>{reduxText[873]}</p>
                        </div>
                    </div>
                    <div className='mpw-title-text-block'>
                        <h2 className='mb-main-title'>{reduxText[874]}</h2>
                        <p className='mb-main-text'>{reduxText[875]}</p>
                    </div>
                    <div className='mpw-image-text-block'>
                        <div className='mpw-image-wrap'>
                            <ImageHelper
                                alt=''
                                className='mpw-image'
                                dominant_color={reduxText[88000]}
                                src={reduxText[880]}
                            />
                        </div>
                        <div className='mpw-text-wrap'>
                            <h2 className='mb-main-title split'>{reduxText[876]}</h2>
                            <p className='mb-main-text'>{reduxText[877]}</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </IonContent>
        </IonPage>
    )
}
