// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
} from 'components'

// data
import {
    api_url_property_list,
    defaultReduxState,
    reduxModalErrorEventHandler,
    view_url_property_list,
} from 'data'

// pages
import {
    PropertyListBigBlock,
} from 'pages'

// serializers
import {
    PropertyListBlockSerializer,
} from 'serializers'

// services
import {
    axiosErrorHandler,
    getApiUrl,
} from 'services'

// props
type PropertyListFilteredProps = {
    activity?: number
    prestation?: number
}

// main
export const PropertyListFiltered: React.FC<PropertyListFilteredProps> = React.memo(({
    activity,
    prestation,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [properties, setProperties] = useState<PropertyListBlockSerializer[]>([])

    useEffect(() => {
        getProperties()
    }, [])

    function getProperties() {
        try {
            let getUrl = getApiUrl(`${api_url_property_list}?is_mobile=true&limit=2`, reduxAuth)
            if (activity) getUrl += `&activities=${activity}`
            if (prestation) getUrl += `&prestations=${prestation}`
            axios({
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setProperties(response.data.results)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'PropertyListFiltered',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getProperties',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PropertyListFiltered',
                'getProperties',
            ))
        }
    }

    let view_url = `${view_url_property_list}?`
    if (activity) view_url += `activities=${activity}&`
    if (prestation) view_url += `prestations=${prestation}&`

    return (
        <React.Fragment>
            <div className='property-list'>
                {properties.map((val) => (
                    <PropertyListBigBlock
                        key={val.id}
                        object={val}
                    />
                ))}
            </div>
            <Button
                text={reduxText[890]}
                to={view_url}
            />
        </React.Fragment>
    )
})
