// react components
import React, {
    useRef,
    useState,
} from 'react'
import Geosuggest from 'react-geosuggest'
import {
    IonChip,
    IonIcon,
    IonLabel,
} from '@ionic/react'
import {
    useDispatch,
} from 'react-redux'

// components
import {
    ErrorInput,
    MainFormLabel,
} from 'components'

// data
import {
    reduxModalErrorEventHandler,
} from 'data'

// serializers
import {
    CityTempSerializer,
} from 'serializers'

// props
type LocationInputProps = {
    error: string | undefined
    helperText?: string
    label?: string
    multi?: boolean
    name: string
    onChange: React.Dispatch<any>
    optional?: boolean
    placeholer?: string
    staffOnly?: boolean
    value: CityTempSerializer[] | ''
}

// main
export const LocationInput: React.FC<LocationInputProps> = React.memo(({
    error,
    helperText,
    label,
    multi,
    name,
    onChange,
    optional,
    placeholer,
    staffOnly,
    value,
}) => {

    const dispatch = useDispatch()
    const geosuggestEl = useRef(null)

    const [newValue, setValue] = useState<CityTempSerializer[]>(value || [])

    function onClear() {
        // @ts-ignore
        if (geosuggestEl && geosuggestEl.current) geosuggestEl.current.clear()
    }

    function onSelectRemove(placeId: any) {
        try {
            const toSendValue: CityTempSerializer[] = []
            newValue.map((val) => {
                if (val.placeId !== placeId) toSendValue.push(val)
                return false
            })
            setValue(toSendValue)
            onChange({
                name: name,
                value: toSendValue,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'LocationInput',
                'onSelectRemove',
            ))
        }
    }

    function onSuggestSelect(response: any) {
        if (process.env.NODE_ENV === 'development') console.log(response)
        const gmaps: google.maps.places.PlaceResult = response ? response.gmaps : ''
        if (gmaps.address_components) {
            let cityName
            let countryCode
            let countryName
            let postalCode
            let route
            let streetNumber
            const location = (gmaps.geometry && gmaps.geometry.location) as google.maps.LatLng
            gmaps.address_components.map((object) => {
                if (object.types[0] === 'country') {
                    countryCode = object.short_name
                    countryName = object.long_name
                }
                if (object.types[0] === 'locality') {
                    cityName = object.short_name
                }
                if (object.types[0] === 'postal_code') {
                    postalCode = object.short_name
                }
                if (object.types[0] === 'route') {
                    route = object.short_name
                }
                if (object.types[0] === 'street_number') {
                    streetNumber = object.short_name
                }
                return false
            })
            const latValue = location.lat()
            const lngValue = location.lng()
            const toReturnObject: CityTempSerializer = {
                cityName: cityName,
                countryCode: countryCode,
                countryName: countryName,
                description: response ? response.description : '',
                latValue: latValue,
                lngValue: lngValue,
                placeId: gmaps.place_id || '',
                postalCode: postalCode,
                route: route,
                streetNumber: streetNumber,
            }

            if (multi) {
                let toSendValue: CityTempSerializer[] = []
                const index = newValue.findIndex((x: CityTempSerializer) => x.placeId === gmaps.place_id)
                if (index === -1) {
                    toSendValue = [...newValue, toReturnObject]
                    setValue(toSendValue)
                }
                onChange({
                    name: name,
                    value: toSendValue,
                })
            } else {
                setValue([toReturnObject])
                onChange({
                    name: name,
                    value: [toReturnObject],
                })
            }
        }
        onClear()
    }

    return (
        <div className='select-input-web'>
            {label && (
                <MainFormLabel
                    helperText={helperText}
                    label={label}
                    name={name}
                    optional={optional}
                    staffOnly={staffOnly}
                />
            )}
            <div className='form-input-wrap'>
                <div className='fiw-input'>
                    <Geosuggest
                        ref={geosuggestEl}
                        placeholder={placeholer}  // TO_CHECK
                        onSuggestSelect={onSuggestSelect}
                        // @ts-ignore
                        placeDetailFields={['address_components', 'place_id']}
                    />
                    {value && value.length > 0 && (
                        <div className='total-location-chips'>
                            {value.map((data, i) => (
                                <IonChip key={i}>
                                    <IonLabel>{data.description}</IonLabel>
                                    <IonIcon
                                        icon='close-circle'
                                        onClick={() => onSelectRemove(data.placeId)}
                                    />
                                </IonChip>
                            ))}
                        </div>
                    )}
                </div>
                {helperText && (
                    <div className='fiw-helper'>
                        <p className='pre-line'>{helperText}</p>
                    </div>
                )}
            </div>
            <ErrorInput error={error} />
        </div>
    )
})
