// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'

// data
import {
    reduxAuthState,
} from 'data'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

// main
export function getDetailObject(
    apiUrl: string,
    reduxAuth: reduxAuthState,
    dispatch: React.Dispatch<any>,
    setObject: React.Dispatch<any>,
    axiosCancelToken: CancelTokenSource | undefined,
    setAxiosCancelToken: React.Dispatch<any>,
    component: string,
) {

    if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')

    if (!apiUrl) return

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    setAxiosCancelToken(source)

    if (process.env.NODE_ENV === 'development') console.log(apiUrl)

    axios({
        headers: getAxiosHeaders(reduxAuth, dispatch, `${component} getDetailObject`),
        method: 'get',
        url: apiUrl,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            setObject(response.data)
        })
        .catch((error) => {
            axiosErrorHandler({
                apiUrl,
                component,
                dispatch,
                error,
                reduxAuth,
                reference: 'getDetailObject',
            })
        })
}
