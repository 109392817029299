// react components
import React from 'react'
import L from 'leaflet'
import {
    Map,
    TileLayer,
    Marker,
} from 'react-leaflet'

// data
import {
    map_attribution,
    map_url,
} from 'data'

// serializers
import {
    PropertyDetailBlockSerializer,
} from 'serializers'

// props
type PropertyDetailMapBlockProps = {
    markerData: PropertyDetailBlockSerializer
}

// main
export const PropertyDetailMapBlock: React.FC<PropertyDetailMapBlockProps> = React.memo(({
    markerData,
}) => {

    const mylabMarkerRound = L.icon({
        iconUrl: 'https://media.mylab.immo/global/map/marker-round.png',
        iconSize: [100, 100],
        iconAnchor: [50, 50],
        popupAnchor: [0, -50],
    })

    return (
        <Map
            boxZoom
            center={{
                lat: markerData.lat_lng ? Number(markerData.lat_lng.split(',')[0]) : 0,
                lng: markerData.lat_lng ? Number(markerData.lat_lng.split(',')[1]) : 0,
            }}
            maxZoom={16}
            minZoom={1}
            preferCanvas
            scrollWheelZoom={false}
            zoom={14}
        >
            <TileLayer
                attribution={map_attribution}
                tileSize={512}
                url={map_url}
                zoomOffset={-1}
            />
            {markerData.lat_lng && (
                <Marker
                    icon={mylabMarkerRound}
                    position={{
                        lat: Number(markerData.lat_lng.split(',')[0]),
                        lng: Number(markerData.lat_lng.split(',')[1]),
                    }}
                />
            )}
        </Map>
    )
})
