// Root Url
const rootPath = 'v1/catalog/form/'

// Property
export const api_url_property_form_create = `${rootPath}property/create/`
export const api_url_property_form_delete = `${rootPath}property/delete/`
export const api_url_property_form_detail = `${rootPath}property/detail/`
export const api_url_property_form_publish = `${rootPath}property/publish/`
export const api_url_property_form_update = `${rootPath}property/update/`

// Component
export const api_url_component_form_create = `${rootPath}component/create/`
export const api_url_component_form_detail = `${rootPath}component/detail/`
export const api_url_component_form_list = `${rootPath}component/list/`
export const api_url_component_form_patch = `${rootPath}component/patch/`
export const api_url_component_form_update = `${rootPath}component/update/`

// Image
export const api_url_image_form_create = `${rootPath}image/create/`
export const api_url_image_form_detail = `${rootPath}image/detail/`
export const api_url_image_form_list = `${rootPath}image/list/`
export const api_url_image_form_patch = `${rootPath}image/patch/`
export const api_url_image_form_update = `${rootPath}image/update/`

// Price
export const api_url_price_form_create = `${rootPath}price/create/`
export const api_url_price_form_detail = `${rootPath}price/detail/`
export const api_url_price_form_list = `${rootPath}price/list/`
export const api_url_price_form_patch = `${rootPath}price/patch/`
export const api_url_price_form_update = `${rootPath}price/update/`
