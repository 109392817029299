// data
import {
    REDUX_FORM_SET_IS_NEWSLETTER_OPEN,
    REDUX_FORM_SET_IS_SUCCESS_TOAST_OPEN,
    REDUX_FORM_SET_REFRESH,
    REDUX_FORM_SET_SUCCESS_MODAL,
} from './mb_actions'
import {
    reduxFormState,
} from './mb_state'

// initial state
const initialState: reduxFormState = {
    isNewsletterOpen: false,
    isSuccessToastOpen: false,
    isSuccessToastMessage: '',
    refresh: {
        refreshDetail: 0,
        refreshProfile: 0,
    },
    successModal: {},
}

export default (state = initialState, action: any) => {
    switch (action.type) {
        case REDUX_FORM_SET_IS_NEWSLETTER_OPEN: {
            return {
                ...state,
                isNewsletterOpen: action.payload,
            }
        }
        case REDUX_FORM_SET_IS_SUCCESS_TOAST_OPEN: {
            return {
                ...state,
                isSuccessToastOpen: action.payload.boolean,
                isSuccessToastMessage: action.payload.message,
            }
        }
        case REDUX_FORM_SET_REFRESH: {
            return {
                ...state,
                refresh: {
                    ...state.refresh,
                    [action.payload.refreshType]: action.payload.refreshValue,
                }
            }
        }
        case REDUX_FORM_SET_SUCCESS_MODAL: {
            return {
                ...state,
                successModal: action.payload,
            }
        }
        default:
            return state
    }
}
