// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    ItemReorderEventDetail,
} from '@ionic/core'
import {
    IonAlert,
    IonButtons,
    IonContent,
    IonFooter,
    IonItem,
    IonReorder,
    IonReorderGroup,
    IonThumbnail,
} from '@ionic/react'
import {
    arrayMoveImmutable,
} from 'array-move'
import axios from 'axios'
import {
    DropzoneComponent,
} from 'react-dropzone-component'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useParams,
} from 'react-router-dom'

// components
import {
    Button,
    IconBlock,
    ImageCropInputProperty,
    ImageHelper,
    Loader,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandler,
    view_url_property_form,
} from 'data'

// pages
import {
    ImageFormState,
    ImageFormStateProps,
} from 'pages'

// serializers
import {
    FormInlineStateProps,
} from 'serializers'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
    getMainErrorMessage,
} from 'services'

// props
type MatchParams = {
    id: string
}

// main
export const ImagePropertyPanel: React.FC = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [dokaEnabled, setDokaEnabled] = useState<boolean>(false)
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [items, setItems] = useState<{ id: number, get_image_lq: string }[]>([])
    const [mainError, setMainError] = useState<string>('')
    const [mother, setMother] = useState<FormInlineStateProps & ImageFormStateProps>(ImageFormState(reduxAuth))
    const [object, setObject] = useState<any>(undefined)

    useEffect(() => {
        if (params.id === 'new') {
            history.replace(`${view_url_property_form}${params.id}/intro/`)
        } else {
            getListData()
        }
    }, [
        params.id,
        reduxAuth.settings?.id,
    ])

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        try {
            onSortEnd(event)
            event.detail.complete()
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImagePropertyPanel',
                'doReorder',
            ))
        }
    }

    function getListData() {
        try {
            const getUrl = `${mother.listUrl}${params.id}/`
            axios({
                headers: getAxiosHeaders(reduxAuth, dispatch, 'ImagePropertyPanel'),
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setItems(response.data)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'ImagePropertyPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'getListData',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImagePropertyPanel',
                'getListData',
            ))
        }
    }

    function handleSubmit(output: any) {
        try {

            setIsSaving(true)

            const axiosUrl = `${mother.updateUrl}${mother.fields?.id}/`
            // Construct FormData
            const formData = new FormData()
            let filetoUploadName = output.file.name
            if (filetoUploadName.length > 100) {
                filetoUploadName = filetoUploadName.slice(filetoUploadName.length - 100)
            }
            formData.append('image', output.file, filetoUploadName)
            formData.append('image_doka_output_data', JSON.stringify(output.data))
            axios({
                data: formData,
                headers: getAxiosHeaders(reduxAuth, dispatch, 'ImagePropertyPanel'),
                method: 'put',
                url: axiosUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    getListData()
                    setIsSaving(false)
                    setMainError('')
                    setMother({
                        ...mother,
                        errors: {},
                        fields: undefined,
                    })
                })
                .catch((error) => {
                    setIsSaving(false)
                    setMainError(error.response?.data?.non_field_errors || '')
                    setMother({
                        ...mother,
                        errors: getMainErrorMessage(error, reduxText),
                        fields: undefined,
                    })
                    if (!error.response?.data?.non_field_errors) {
                        axiosErrorHandler({
                            apiUrl: axiosUrl,
                            component: 'ImagePropertyPanel',
                            dispatch,
                            error,
                            formFields: JSON.stringify(mother.fields),
                            reduxAuth,
                            reference: 'handleSubmit',
                        })
                    }
                })
        } catch (error) {
            if (process.env.NODE_ENV === 'development') console.log(error)
            setIsSaving(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImagePropertyPanel',
                'handleSubmit',
            ))
        }
    }

    function handleInlineDelete() {
        try {

            if (!object) return
            const deleteUrl = `${mother.updateUrl}${object.id}/`
            axios({
                headers: getAxiosHeaders(reduxAuth, dispatch, 'ImagePropertyPanel'),
                method: 'delete',
                url: deleteUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    getListData()
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: deleteUrl,
                        component: 'ImagePropertyPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleInlineDelete',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImagePropertyPanel',
                'handleInlineDelete',
            ))
        }
    }

    function handleInlineRetrieve(clickedObject: any) {
        try {
            console.log(clickedObject);
            setObject(clickedObject)
            if (!clickedObject) return
            const getUrl = `${mother.detailInlineUrl}${clickedObject.id}/`
            axios({
                headers: getAxiosHeaders(reduxAuth, dispatch, 'ImagePropertyPanel'),
                method: 'get',
                url: getUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setMother({
                        ...mother,
                        fields: response.data,
                    })
                    setDokaEnabled(true)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'ImagePropertyPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleInlineRetrieve',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImagePropertyPanel',
                'handleInlineRetrieve',
            ))
        }
    }

    function onSortEnd(event: any) {
        try {
            const item = items[event.detail.from]
            setItems(arrayMoveImmutable(items, event.detail.from, event.detail.to))
            const formData = new FormData()
            formData.append('position', event.detail.to)
            formData.append('property', params.id)
            const putUrl = `${mother.patchUrl}${item.id}/`
            axios({
                data: formData,
                headers: getAxiosHeaders(reduxAuth, dispatch, 'ImagePropertyPanel'),
                method: 'put',
                url: putUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: putUrl,
                        component: 'ImagePropertyPanel',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onSortEnd',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'ImagePropertyPanel',
                'onSortEnd',
            ))
        }
    }

    // Mother
    const fieldsMother = mother.fields

    // Extra
    let myDropzone: any
    function initCallback(dropzone: any) {
        myDropzone = dropzone
    }
    const config = {
        iconFiletypes: ['.jpg', '.jpeg', '.png', '.gif'],
        postUrl: mother.createUrl,
        showFiletypeIcon: true,
    }
    const eventHandlers = {
        init: (e: any) => initCallback(e),
        complete: (file: any) => {
            myDropzone.removeFile(file)
            getListData()
        }
    }
    const djsConfig = {
        acceptedFiles: 'image/jpeg,image/png,image/gif',
        dictDefaultMessage: reduxText[614],
        headers: reduxAuth.axiosConfig?.headers,
        maxFilesize: 50,
        maxThumbnailFilesize: 50,
        parallelUploads: 1,
        paramName: 'image',
        timeout: null,
        params: {
            active: true,
            property: params.id,
        },
    }

    const deleteAlertButtons = [
        {
            role: 'destructive',
            text: reduxText[615],
            handler: () => {
                handleInlineDelete()
            }
        },
        {
            text: reduxText[616],
            role: 'cancel',
        },
    ]

    return (
        <React.Fragment>
            <IonContent>
                <div className='pfw-content'>
                    <div className='pfw-panel'>
                        <div className='intro-property-panel-web'>
                            <div className='ippw-wrap'>
                                <h1 className='ippw-title'>{reduxText[617]}</h1>
                                <p className='ippw-title-helper'>{reduxText[732]}</p>
                            </div>
                            <div className='form-input-wrap'>
                                <div className='multiple-image-input-web'>
                                    <DropzoneComponent
                                        config={config}
                                        djsConfig={djsConfig}
                                        eventHandlers={eventHandlers}
                                    />
                                </div>
                                <div className='fiw-helper'>
                                    <p className='pre-line'>{reduxText[731]}</p>
                                </div>
                            </div>
                            {fieldsMother && dokaEnabled && (
                                <ImageCropInputProperty
                                    doka_output_data={fieldsMother.image_doka_output_data || ''}
                                    handleSubmit={handleSubmit}
                                    original={fieldsMother.image_original || ''}
                                    value={fieldsMother.image || ''}
                                    disableDoka={() => setDokaEnabled(false)}
                                />
                            )}
                            <div className='pfw-inline-list'>
                                <IonReorderGroup
                                    disabled={false}
                                    onIonItemReorder={doReorder}
                                >
                                    {items.map((val) => {
                                        return (
                                            <IonItem
                                                key={val.id}
                                                detail={false}
                                            >
                                                <IonThumbnail>
                                                    <ImageHelper
                                                        alt=''
                                                        dominant_color={undefined}
                                                        src={val.get_image_lq}
                                                        onClick={() => handleInlineRetrieve(val)}
                                                    />
                                                </IonThumbnail>
                                                <IonButtons slot='end'>
                                                    <IconBlock
                                                        iconClass='fa fa-trash'
                                                        onClick={() => {
                                                            setObject(val)
                                                            setIsDeleteAlertOpen(true)
                                                        }}
                                                    />
                                                    <IconBlock
                                                        iconClass='fa fa-edit'
                                                        onClick={() => handleInlineRetrieve(val)}
                                                    />
                                                    <IonReorder slot='end' />
                                                </IonButtons>
                                            </IonItem>
                                        )
                                    })}
                                </IonReorderGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
            <IonFooter className='pfw-footer'>
                <div className='pfw-wrap'>
                    <span
                        className='pfw-back'
                        onClick={() => window.history.back()}
                    >
                        <i className='fa fa-chevron-left'></i>{reduxText[567]}
                    </span>
                    <Button
                        text='Suivant'
                        to={`${view_url_property_form}${params.id}/preview/`}
                    />
                </div>
            </IonFooter>
            {isSaving && (
                <Loader
                    isOpen
                    message={reduxText[579]}
                />
            )}
            <IonAlert
                buttons={[reduxText[510]]}
                header={reduxText[511]}
                isOpen={Boolean(mainError)}
                message={`${mainError}${mainError === 'Network Error' ? reduxText[581] : ''}`}
                onDidDismiss={() => setMainError('')}
            />
            <IonAlert
                buttons={deleteAlertButtons}
                header={reduxText[618]}
                isOpen={isDeleteAlertOpen}
                message={reduxText[619]}
                onDidDismiss={() => setIsDeleteAlertOpen(false)}
            />
        </React.Fragment>
    )
}
