// react components
import React from 'react'
import axios, {
    CancelTokenSource,
} from 'axios'

// data
import {
    reduxAuthState,
} from 'data'

// services
import {
    axiosErrorHandler,
    getAxiosHeaders,
} from 'services'

// main
export function getMapList(
    apiUrl: string,
    reduxAuth: reduxAuthState,
    dispatch: React.Dispatch<any>,
    addMarkers: React.Dispatch<any>,
    axiosCancelToken: CancelTokenSource | undefined,
    setAxiosCancelToken: React.Dispatch<any>,
    component: string,
    setIsLoading?: React.Dispatch<any>,
    setClusterIds?: any,
) {
    if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')

    if (!apiUrl) return

    if (setIsLoading) setIsLoading(true)

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    setAxiosCancelToken(source)

    if (process.env.NODE_ENV === 'development') console.log(apiUrl, component)

    axios({
        headers: getAxiosHeaders(reduxAuth, dispatch, `${component} getMapList`),
        method: 'get',
        url: apiUrl,
        cancelToken: source.token,
    })
        .then((response) => {
            if (process.env.NODE_ENV === 'development') console.log(response)
            addMarkers(response.data)
            if (setIsLoading) setIsLoading(false)
            if (setClusterIds) {
                const newArray: any = []
                response.data.map((val: any) => {
                    newArray.push(val.parent)
                    return false
                })
                setClusterIds(newArray)
            }
        })
        .catch((error) => {
            if (setIsLoading) setIsLoading(false)
            axiosErrorHandler({
                apiUrl,
                component,
                dispatch,
                error,
                reduxAuth,
                reference: 'getMapList',
            })
        })
}
