// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'
import VisibilitySensor from 'react-visibility-sensor'

// components
import {
    ListSpinner,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// props
type InfiniteScrollHelperProps = {
    active: boolean
    color?: 'tertiary' | 'primary' | 'secondary'
    endText?: boolean
    hasMore: boolean
    isLoading: boolean
    items: any[]
    noMoreText?: number
    offset?: any
    onChange: any
}

// main
export const InfiniteScrollHelper: React.FC<InfiniteScrollHelperProps> = React.memo(({
    active,
    color,
    endText,
    hasMore,
    isLoading,
    items,
    noMoreText,
    offset,
    onChange,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    if (hasMore && items.length > 0) {
        return (
            <VisibilitySensor
                active={active}
                offset={offset || { bottom: -500 }}
                onChange={onChange}
                partialVisibility
            >
                <div className='infinite-scroll-helpe-web-empty'>
                    <ListSpinner isLoading color={color || 'primary'} />
                </div>
            </VisibilitySensor>
        )
    }
    if (items.length === 0 && isLoading) {
        return (
            <div className='infinite-scroll-helpe-web-empty'>
                <ListSpinner isLoading color={color || 'primary'} />
            </div>
        )
    }
    if (!hasMore) {
        if (endText) {
            return (
                <p className='infinite-scroll-helpe-web-no-more-text'>{reduxText[items.length === 0 ? (noMoreText || 772) : 773]}</p>
            )
        }
        if (items.length === 0) {
            return (
                <p className='infinite-scroll-helpe-web-no-more-text'>{reduxText[noMoreText || 772]}</p>
            )
        }
        return (
            <p className='infinite-scroll-helpe-web-no-more-text' />
        )
    }
    return null
})
