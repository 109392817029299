// react components
import React from 'react'
import {
    Redirect,
    useParams,
} from 'react-router-dom'

// data
import {
    view_url_property_detail,
} from 'data'

type MatchParams = {
    slug: string
}

// main
export const PropertyDetailOld: React.FC = () => {

    const params = useParams<MatchParams>()

    const id = params.slug.split('-')[params.slug.split('-').length - 1]

    return <Redirect to={`${view_url_property_detail}${id}/${params.slug}/`} />
}
