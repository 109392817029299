// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonPage,
    IonTextarea,
    IonTitle,
    IonToolbar,
    useIonViewWillLeave,
} from '@ionic/react'
import axios, {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Link,
    Redirect,
    useParams,
} from 'react-router-dom'

// components
import {
    ImageHelper,
    InfiniteScrollHelper,
} from 'components'

// data
import {
    defaultReduxState,
    api_url_chat_message_form_create,
    api_url_chat_message_form_patch,
    api_url_message_list,
    api_url_room_detail,
    reduxModalErrorEventHandler,
    view_url_auth_login,
} from 'data'

// pages
import {
    MessageListBlock,
} from 'pages'

// serializers
import {
    MessageListBlockSerializer,
    RoomDetailBlockSerializer,
} from 'serializers'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getInfiniteList,
} from 'services'

// props
type MatchParams = {
    id: string
}

// main
export const RoomDetail: React.FC = () => {

    const dispatch = useDispatch()
    const params = useParams<MatchParams>()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    useEffect(() => {
        onGetRoomDetail()
    }, [
        params.id,
        reduxAuth.settings?.id,
    ])

    useIonViewWillLeave(() => {
        if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
    })

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(true)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [items, setItems] = useState<MessageListBlockSerializer[]>([])
    const [nextUrl, setNextUrl] = useState<string>('')

    const [disableSend, setDisableSend] = useState<boolean>(true)
    const [newMessage, setNewMessage] = useState<string | null | undefined>('')
    const [roomInfo, setRoomInfo] = useState<RoomDetailBlockSerializer | undefined>(undefined)

    function handleInputChange(value: string | null | undefined) {
        try {
            setDisableSend(false)
            setNewMessage(value)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'RoomDetail',
                'handleInputChange',
            ))
        }
    }

    function handleKeyPress(e: any) {
        if (e.key === 'Enter' && !e.shiftKey) {
            if (newMessage?.trim().length === 0) return
            handleSendMessage()
        }
    }

    function handleSendMessage() {
        try {
            setDisableSend(true)
            if (!newMessage) return
            handlePostMessage(params.id)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'RoomDetail',
                'handleSendMessage',
            ))
        }
    }

    function handlePostMessage(roomId: string) {
        try {
            if (!reduxAuth.settings?.id) return
            if (!newMessage) return
            const formData: any = new FormData()
            formData.append('body', newMessage)
            formData.append('sender', reduxAuth.settings.id)
            formData.append('room', roomId)

            const postUrl = getApiUrl(api_url_chat_message_form_create, reduxAuth)
            axios({
                data: formData,
                headers: getAxiosHeaders(reduxAuth, dispatch, 'RoomDetail'),
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    console.log(response)
                    setNewMessage('')
                    onGetListData(getApiUrl(`${api_url_message_list}${roomId}/`, reduxAuth), true)
                })
                .catch((error) => {
                    setDisableSend(false)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'RoomDetail',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handlePostMessage',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'RoomDetail',
                'handlePostMessage',
            ))
        }
    }

    function onGetRoomDetail() {
        try {
            if (!reduxAuth.settings?.id) return
            if (axiosCancelToken) axiosCancelToken.cancel('axios canceled')
            const CancelToken = axios.CancelToken
            const source = CancelToken.source()
            setAxiosCancelToken(source)

            const getUrl = getApiUrl(`${api_url_room_detail}${params.id}/`, reduxAuth)
            setIsLoading(true)

            axios({
                headers: getAxiosHeaders(reduxAuth, dispatch, 'RoomDetail'),
                method: 'get',
                url: getUrl,
                cancelToken: source.token,
            })
                .then((response) => {
                    console.log(response)
                    onGetListData(getApiUrl(`${api_url_message_list}${params.id}/`, reduxAuth), true)
                    setRoomInfo(response.data)
                    onMakeMessageRead()
                })
                .catch((error) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'RoomDetail',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'onGetRoomDetail',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'RoomDetail',
                'onGetRoomDetail',
            ))
        }
    }

    function onMakeMessageRead() {
        try {
            const getUrl = getApiUrl(`${api_url_chat_message_form_patch}${params.id}/`, reduxAuth)
            axios({
                headers: getAxiosHeaders(reduxAuth, dispatch, 'RoomDetail'),
                method: 'get',
                url: getUrl,
            })
                .then((response2) => {
                    console.log(response2)
                    // fetchNotificationsHelper(reduxAuth, dispatch)
                })
                .catch((error2) => {
                    axiosErrorHandler({
                        apiUrl: getUrl,
                        component: 'RoomDetail',
                        dispatch,
                        error: error2,
                        reduxAuth,
                        reference: 'onMakeMessageRead',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'RoomDetail',
                'onMakeMessageRead',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteList(
                apiUrl,
                reduxAuth,
                dispatch,
                items,
                setItems,
                setNextUrl,
                axiosCancelToken,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setIsLoading,
                setHasMore,
                'RoomDetail',
                changingView,
                onMakeMessageRead,
            )
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'RoomDetail',
                'onGetListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'RoomDetail',
                'onSearchNext',
            ))
        }
    }

    if (!reduxAuth.authenticated) {
        return <Redirect to={view_url_auth_login} />
    }
    return (
        <IonPage className='room-detail-web _lr-hide'>
            {/* TO_DO */}
            {/* <SeoBlock pageId={roomInfo.seo_data} /> */}
            <IonHeader className='rdw-header'>
                <IonToolbar>
                    <IonButtons slot='start'>
                        <IonBackButton
                            defaultHref='/'
                            text={(window.innerWidth <= 767.99) ? '' : reduxText[771]}
                        />
                    </IonButtons>
                    <IonTitle>{roomInfo?.room_profile?.first_name}</IonTitle>
                    <IonButtons slot='end'>
                        <span className='rdw-phone'>{roomInfo?.room_profile?.userprofile?.phone_number}</span>
                    </IonButtons>
                </IonToolbar>
                <div className='rdw-bottom'>
                    {roomInfo && (
                        <React.Fragment>
                            <Link to={roomInfo.property?.get_absolute_url!}>
                                <ImageHelper
                                    alt={roomInfo.property?.title}
                                    className='rdw-image'
                                    dominant_color={roomInfo.property?.get_dominant_color}
                                    src={roomInfo.property?.get_image_mq}
                                />
                            </Link>
                            <div className='rdw-info'>
                                <Link
                                    className='rdw-title'
                                    to={roomInfo.property?.get_absolute_url!}
                                >
                                    {roomInfo.property?.title}
                                </Link>
                                <p className='rdw-price'>{roomInfo.property?.get_price_first}{' € '}{roomInfo.property?.get_price_type}</p>
                                <p className='rdw-area'>{roomInfo.property?.get_area_total} m<sup>2</sup></p>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </IonHeader>
            <IonContent>
                <div className='rdw-content'>
                    {items.map((item) => {
                        return (
                            <MessageListBlock
                                key={item.id}
                                object={item}
                            />
                        )
                    })}
                    <InfiniteScrollHelper
                        active={!disableInfiniteScroll}
                        endText
                        hasMore={hasMore}
                        isLoading={isLoading}
                        items={items}
                        onChange={onSearchNext}
                    />
                </div>
            </IonContent>
            <IonFooter className='rdw-footer'>
                <IonTextarea
                    autoGrow
                    className='rdw-text-area'
                    maxlength={5000}
                    onIonChange={(e) => handleInputChange(e.detail.value)}
                    onKeyPress={(e) => handleKeyPress(e)}
                    placeholder={reduxText[734]}
                    rows={1}
                    value={newMessage}
                />
                <span
                    className={`rdw-send fa fa-paper-plane ${disableSend ? 'disabled' : ''}`}
                    onClick={handleSendMessage}
                />
            </IonFooter>
        </IonPage>
    )
}
