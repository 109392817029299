// react components
import React from 'react'
import axios from 'axios'
import {
    getPlatforms,
    isPlatform,
} from '@ionic/react'
import {
    isMobileOnly,
} from 'react-device-detect'

// data
import {
    app_version,
    errorMessageToSkip,
    reduxAuthLogout,
    reduxAuthState,
    reduxModalErrorShowHide,
} from 'data'

// services
import {
    getApiUrl,
} from 'services'

// main
export function axiosErrorHandler({
    apiUrl,
    component,
    dispatch,
    error,
    formFields,
    reduxAuth,
    reference,
    skip401,
    skipAlert,
    skipAlertStatusCode,
    skipSendError,
    skipSendErrorStatusCode,
}: {
    apiUrl: string
    component: string
    dispatch: React.Dispatch<any>
    error: any
    formFields?: string
    reduxAuth: reduxAuthState
    reference: string
    skip401?: boolean
    skipAlert?: boolean
    skipAlertStatusCode?: number[]
    skipSendError?: boolean
    skipSendErrorStatusCode?: number[]
}) {

    // log
    if (process.env.NODE_ENV === 'development') console.log(error)
    if (process.env.NODE_ENV === 'development') console.log(`${error}`)
    if (process.env.NODE_ENV === 'development') console.log(`${component} - ${reference}`)
    if (process.env.NODE_ENV === 'development') console.log(error.response)
    if (error?.message === 'axios canceled') return

    // const
    const platform = isPlatform('ios') ? 'ios' : (isPlatform('android') ? 'android' : (isMobileOnly ? 'mobile' : 'web'))
    const responseStatus = error.response?.status

    // show alert and don't send
    if (!skipAlert && !(skipAlertStatusCode?.includes(responseStatus))) {
        if (errorMessageToSkip.includes(error?.message)) {
            dispatch(reduxModalErrorShowHide(
                true,
                'Error', // TO_LATER_TEXT
                error.message,
            ))
            return
        }
        if (errorMessageToSkip.includes(`${error}`)) {
            dispatch(reduxModalErrorShowHide(
                true,
                'Error', // TO_LATER_TEXT
                `${error}`,
            ))
            return
        }
    }

    if (!skipAlert && !(skipAlertStatusCode?.includes(responseStatus))) {
        if (responseStatus === 401 && !skip401) {
            if (process.env.NODE_ENV !== 'development') {
                dispatch(reduxAuthLogout())
            }
            dispatch(reduxModalErrorShowHide(
                true,
                'Please sign in again', // TO_LATER_TEXT
            ))
        } else if (responseStatus) {
            if (responseStatus === 400 && error.response.data?.non_field_errors) {
                dispatch(reduxModalErrorShowHide(
                    true,
                    `${responseStatus} - ${error.response.statusText}`,
                    error.response.data.non_field_errors,
                    true,
                ))
            } else {
                dispatch(reduxModalErrorShowHide(
                    true,
                    responseStatus,
                    error.response.statusText,
                ))
            }
        } else if (error.message) {
            dispatch(reduxModalErrorShowHide(
                true,
                'Error',
                error.message,
            ))
        } else {
            dispatch(reduxModalErrorShowHide(
                true,
                'Error',
                `${error}`,
            ))
        }
    }
    if (skipSendError || (skipSendErrorStatusCode?.includes(responseStatus))) return
    if (process.env.NODE_ENV !== 'production') console.log('sending error log')
    // if (process.env.NODE_ENV !== 'production') return
    const createUrl = getApiUrl('v1/main/form/error_history/create/', reduxAuth)
    if (responseStatus) {
        const formData = new FormData()
        formData.append('api_url', apiUrl)
        formData.append('app_version', app_version)
        formData.append('component', component)
        if (formFields) {
            formData.append('data', `${JSON.stringify(error.response.data)} - ${formFields} - 1`)
        } else {
            formData.append('data', `${JSON.stringify(error.response.data)} - 2`)
        }
        formData.append('location', window.location.href)
        formData.append('platform', platform)
        formData.append('reference', reference)
        formData.append('status', responseStatus)
        formData.append('statusText', error && (error.response.statusText || 'Other'))
        formData.append('user_agent', navigator?.userAgent)
        axios({
            data: formData,
            headers: reduxAuth?.axiosConfig?.headers,
            method: 'post',
            url: createUrl,
        })
            .catch((axiosError) => {
                if (process.env.NODE_ENV === 'development') console.log(axiosError.response)
            })
    } else if (error) {
        const formData = new FormData()
        formData.append('api_url', apiUrl)
        formData.append('app_version', app_version)
        formData.append('component', component)
        formData.append('data', `${error} - 3`)
        formData.append('location', window.location.href)
        formData.append('platform', platform)
        formData.append('reference', `${reference}`)
        formData.append('status', '0')
        formData.append('statusText', 'Other')
        formData.append('user_agent', navigator?.userAgent)
        axios({
            data: formData,
            headers: reduxAuth?.axiosConfig?.headers,
            method: 'post',
            url: createUrl,
        })
            .catch((axiosError) => {
                if (process.env.NODE_ENV === 'development') console.log(axiosError.response)
            })
    }
}

export function axiosErrorHandlerErrorBoundary(
    error: any,
    reduxAuth: reduxAuthState,
    component: string,
    info: string,
) {
    // if (process.env.NODE_ENV !== 'production') return
    const platform = isPlatform('ios') ? 'ios' : (isPlatform('android') ? 'android' : 'web')
    const createUrl = getApiUrl('v1/main/form/error_history/create/', reduxAuth)
    if (error) {
        const formData = new FormData()
        formData.append('app_version', app_version)
        formData.append('component', component)
        formData.append('data', `${error} - 4`)
        formData.append('location', window.location.href)
        formData.append('platform', platform)
        formData.append('reference', `${info} - ${getPlatforms()}`)
        formData.append('status', '0')
        formData.append('statusText', 'event handler')
        formData.append('user_agent', navigator?.userAgent)
        axios({
            data: formData,
            headers: reduxAuth?.axiosConfig?.headers,
            method: 'post',
            url: createUrl,
        })
            .catch((axiosError) => {
                if (process.env.NODE_ENV === 'development') console.log(axiosError.response)
            })
    }
}
