// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    Link,
} from 'react-router-dom'

// components
import {
    ImageHelper,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    ArticleListBlockSerializer,
} from 'serializers'

// props
type ArticleListBlockProps = {
    object: ArticleListBlockSerializer
}

// main
export const ArticleListBlock: React.FC<ArticleListBlockProps> = React.memo(({
    object,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <div className='article-list-block-web'>
            <Link to={object.get_absolute_url}>
                <p className='albw-name mb-clamp1'>{object.title}</p>
                <ImageHelper
                    alt=''
                    className='albw-image'
                    dominant_color={object.get_dominant_color}
                    src={object.get_image_lq}
                />
            </Link>
            <p className='albw-abstract mb-clamp1'>{object.abstract}</p>
            <Link
                className='albw-button'
                to={object.get_absolute_url}
            >
                {reduxText[544]}
            </Link>
        </div>
    )
})
