// react components
import React from 'react'
import ReactGA from 'react-ga'
import {
    IonReactRouter,
} from '@ionic/react-router'
import {
    IonApp,
    IonRouterOutlet,
    setupIonicReact,
} from '@ionic/react'
import {
    Provider,
} from 'react-redux'
import {
    Redirect,
} from 'react-router'
import {
    Route,
} from 'react-router-dom'

// components
import {
    ErrorAlert,
    ErrorBoundary,
    ErrorEventHandler,
    FormSuccessModal,
    FormSuccessToast,
    GABlock,
    GlobalHelper,
    Header,
    LoginAlert,
} from 'components'

// data
import {
    view_url_article_detail,
    view_url_article_list,
    view_url_auth_login,
    view_url_auth_password_reset,
    view_url_auth_password_reset_confirm,
    view_url_contact_form,
    view_url_main_amenagement,
    view_url_main_bareme,
    view_url_main_cgv,
    view_url_main_cuisine_partagee,
    view_url_main_dark_kitchen,
    view_url_main_home,
    view_url_main_laboratoire_patisserie,
    view_url_main_local_avec_extraction,
    view_url_main_rgpd,
    view_url_main_service,
    view_url_main_traiteur,
    view_url_main_transformation_alimentaire,
    view_url_profile_detail,
    view_url_profile_property_favourite_list,
    view_url_profile_property_list,
    view_url_property_detail,
    view_url_property_edit_form,
    view_url_property_form,
    view_url_property_list,
    view_url_room_detail,
    view_url_room_list,
} from 'data'

// pages
import {
    // Auth
    AuthPage,
    // AuthPasswordChangeForm,
    PasswordResetConfirmPage,
    PasswordResetPage,
    // Blog
    ArticleDetail,
    ArticleList,
    // Catalog
    PropertyDetail,
    PropertyEditForm,
    PropertyForm,
    PropertyList,
    // Chat
    RoomDetail,
    RoomList,
    // Main
    AmenagementPage,
    BaremePage,
    CgvPage,
    CuisinePartageePage,
    DarkKitchenPage,
    HomePage,
    LaboratoirePatisseriePage,
    LocalAvecExtractionPage,
    RgpdPage,
    ServicePage,
    TraiteurPage,
    TransformationAlimentairePage,
    // Info
    ContactForm,
    NewsletterForm,
    // Userprofile
    ProfileDetail,
    ProfilePropertyFavouriteList,
    ProfilePropertyList,
    // Redirect
    ArticleDetailOld,
    PropertyDetailOld,
    PropertyListOld,
} from 'pages'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Theme variables */
import './@static/index.scss'

/* Leaflet */
import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import configureStore from './data/mb_store'

// Redux Constants
const store = configureStore()

if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('UA-76407339-1')
}

setupIonicReact({
    mode: 'ios',
    spinner: 'crescent',
    swipeBackEnabled: false,
})

// main
const App: React.FC = () => (
    <Provider store={store}>
        <IonApp>
            <IonReactRouter>
                <ErrorBoundary>
                    <Header />
                    <IonRouterOutlet animated={window.innerWidth <= 767.99}>
                        {/* Auth */}
                        <Route exact path={view_url_auth_login} component={AuthPage} />
                        <Route exact path={view_url_auth_password_reset_confirm} component={PasswordResetConfirmPage} />
                        <Route exact path={view_url_auth_password_reset} component={PasswordResetPage} />
                        {/* Blog */}
                        <Route exact path={view_url_article_list} component={ArticleList} />
                        <Route exact path={`${view_url_article_detail}:id/:slug?/`} component={ArticleDetail} />
                        {/* Catalog */}
                        <Route exact path={view_url_property_list} component={PropertyList} />
                        <Route exact path={`${view_url_property_detail}:id/:slug?/`} component={PropertyDetail} />
                        <Route exact path={`${view_url_property_edit_form}:id/`} component={PropertyEditForm} />
                        <Route exact path={`${view_url_property_form}:id/:panelValue?/:transactionId?/`} component={PropertyForm} />
                        {/* Chat */}
                        <Route exact path={view_url_room_list} component={RoomList} />
                        <Route exact path={`${view_url_room_detail}:id/`} component={RoomDetail} />
                        {/* Info */}
                        <Route exact path={view_url_contact_form} component={ContactForm} />
                        {/* Main */}
                        <Route exact path={view_url_main_amenagement} component={AmenagementPage} />
                        <Route exact path={view_url_main_bareme} component={BaremePage} />
                        <Route exact path={view_url_main_cgv} component={CgvPage} />
                        <Route exact path={view_url_main_cuisine_partagee} component={CuisinePartageePage} />
                        <Route exact path={view_url_main_dark_kitchen} component={DarkKitchenPage} />
                        <Route exact path={view_url_main_home} component={HomePage} />
                        <Route exact path={view_url_main_laboratoire_patisserie} component={LaboratoirePatisseriePage} />
                        <Route exact path={view_url_main_local_avec_extraction} component={LocalAvecExtractionPage} />
                        <Route exact path={view_url_main_rgpd} component={RgpdPage} />
                        <Route exact path={view_url_main_service} component={ServicePage} />
                        <Route exact path={view_url_main_traiteur} component={TraiteurPage} />
                        <Route exact path={view_url_main_transformation_alimentaire} component={TransformationAlimentairePage} />
                        {/* Userprofile */}
                        <Route exact path={view_url_profile_detail} component={ProfileDetail} />
                        <Route exact path={view_url_profile_property_favourite_list} component={ProfilePropertyFavouriteList} />
                        <Route exact path={view_url_profile_property_list} component={ProfilePropertyList} />
                        {/* Redirect */}
                        <Route exact path='/annonces/achat-vente/' component={PropertyListOld} />
                        <Route exact path='/annonces/coworking-privatisation/' component={PropertyListOld} />
                        <Route exact path='/annonces/detail/:slug/' component={PropertyDetailOld} />
                        <Route exact path='/blog/:slug/' component={ArticleDetailOld} />
                        {/* Fallback TO_DO */}
                        <Redirect to='/' />
                    </IonRouterOutlet>
                    <ErrorAlert />
                    <ErrorEventHandler />
                    <FormSuccessModal />
                    <FormSuccessToast />
                    <GABlock />
                    <GlobalHelper />
                    <LoginAlert />
                    <NewsletterForm />
                </ErrorBoundary>
            </IonReactRouter>
        </IonApp>
    </Provider>
)

export default App
