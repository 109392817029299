// react components
import React, {
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Link,
} from 'react-router-dom'
import Slider from 'react-slick'

// components
import {
    BookmarkButton,
    Button,
    ImageHelper,
    Loader,
    TextAreaInput,
} from 'components'

// data
import {
    defaultReduxState,
    api_url_chat_message_form_create,
    api_url_chat_room_form_create,
    reduxFormSetRefresh,
    reduxFormSetSuccessModal,
    reduxModalErrorEventHandler,
    view_url_auth_login,
    view_url_contact_form,
    view_url_property_edit_form,
    view_url_property_list,
    view_url_room_detail,
} from 'data'

// pages
import {
    PropertyDetailMapBlock,
} from 'pages'

// serializers
import {
    PropertyDetailBlockSerializer,
} from 'serializers'

// services
import {
    axiosErrorHandler,
    getApiUrl,
    getAxiosHeaders,
    getDateWithType,
} from 'services'

// props
type PropertyDetailBlockProps = {
    object: PropertyDetailBlockSerializer
    preview?: boolean
}

// main
export const PropertyDetailBlock: React.FC<PropertyDetailBlockProps> = React.memo(({
    object,
    preview,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [contactButtonDisabled, setContactButtonDisabled] = useState<boolean>(true)
    const [contactMessage, setContactMessage] = useState<string>('')
    const [contactMessageError, setContactMessageError] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    function handleContactInputChange(event: any) {
        try {
            setContactButtonDisabled(false)
            setContactMessage(event.value)
            setContactMessageError('')
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PropertyDetailBlock',
                'handleContactInputChange',
            ))
        }
    }

    function handleContactSubmit() {
        try {
            if (!contactMessage) {
                setContactButtonDisabled(true)
                return
            }
            setIsLoading(true)
            const createUrl = getApiUrl(api_url_chat_room_form_create, reduxAuth)
            const formData: any = new FormData()
            formData.append('property', object.id)
            axios({
                data: formData,
                headers: getAxiosHeaders(reduxAuth, dispatch, 'PropertyDetailBlock'),
                method: 'post',
                url: createUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    handlePostMessage(response.data.id)
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: createUrl,
                        component: 'PropertyDetailBlock',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleContactSubmit',
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'PropertyDetailBlock',
                'handleContactSubmit',
            ))
        }
    }

    function handlePostMessage(roomId: string) {
        try {
            if (!reduxAuth.settings?.id) return
            const formData: any = new FormData()
            formData.append('body', contactMessage)
            formData.append('sender', reduxAuth.settings.id)
            formData.append('room', roomId)
            const postUrl = getApiUrl(api_url_chat_message_form_create, reduxAuth)
            axios({
                data: formData,
                headers: getAxiosHeaders(reduxAuth, dispatch, 'PropertyDetailBlock'),
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    console.log(response)
                    setContactMessage('')
                    setContactButtonDisabled(false)
                    setIsLoading(false)
                    dispatch(reduxFormSetRefresh('refreshDetail'))
                    dispatch(reduxFormSetSuccessModal(true, reduxText[750], reduxText[751], reduxText[749], reduxText[746]))
                })
                .catch((error) => {
                    setIsLoading(false)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'PropertyDetailBlock',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handlePostMessage',
                    })
                })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PropertyDetailBlock',
                'handlePostMessage',
            ))
        }
    }

    const slickSettings = {
        customPaging: function (i: number) {
            return (
                <ImageHelper
                    alt=''
                    className='pdbw-thumb'
                    dominant_color={`${object.image_set?.[i]?.get_dominant_color}`}
                    src={`${object.image_set?.[i]?.get_image_lq}`}
                />
            )
        },
        dots: true,
        dotsClass: 'slick-dots pdbw-thumbs',
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    return (
        <div className={`property-detail-block-web ${preview ? 'preview' : ''}`}>
            <div className='pdbw-content-wrap mb-container'>
                <div className='pdbw-content'>
                    <Link
                        className='pdbw-back'
                        to={view_url_property_list}
                    >
                        {reduxText[550]}
                    </Link>
                    <p className='pdbw-location'>{object.city}{' '}{object.postal_code}</p>
                    <h1 className='pdbw-name'>{object.title}</h1>
                    <div className='pdbw-slider'>
                        {object.image_set?.length! > 0 && (
                            <Slider {...slickSettings}>
                                {object.image_set?.map((val) => (
                                    <div
                                        key={val.id}
                                        className='pdbw-slide'
                                    >
                                        <ImageHelper
                                            alt=''
                                            dominant_color={val.get_dominant_color}
                                            src={val.get_image_hq}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        )}
                    </div>
                    <p className='pdbw-title'>{reduxText[551]}</p>
                    <p className='pdbw-description'>{object.description}</p>
                    <p className='pdbw-title'>{reduxText[565]}</p>
                    <div className='pdbw-surface-wrap'>
                        <div className='pdbw-surface-header'>
                            <div className='pdbw-surface-header-1'>
                                <p>{reduxText[552]}</p>
                            </div>
                            <div className='pdbw-surface-header-2'>
                                <p>{reduxText[553]}</p>
                            </div>
                            <div className='pdbw-surface-header-3'>
                                <p>{reduxText[554]}</p>
                            </div>
                        </div>
                        {object.component_set?.map((val) => (
                            <div
                                key={val.id}
                                className='pdbw-surface-block'
                            >
                                <div className='pdbw-surface-block-1'>
                                    <p>{val.type?.name}</p>
                                </div>
                                <div className='pdbw-surface-block-2'>
                                    <p>{val.designation?.name}</p>
                                </div>
                                <div className='pdbw-surface-block-3'>
                                    <p>{val.area} m<sup>2</sup></p>
                                </div>
                            </div>
                        ))}
                        <div className='pdbw-surface-footer'>
                            <div className='pdbw-surface-footer-1'>
                                <p>{reduxText[555]}</p>
                            </div>
                            <div className='pdbw-surface-footer-2'>
                                <p>{object.get_area_total} m<sup>2</sup></p>
                            </div>
                        </div>
                    </div>
                    <p className='pdbw-title'>{reduxText[556]}</p>
                    <div className='pdbw-prestations'>
                        {object.prestations_all?.map((val) => (
                            <div key={val.id}>
                                <p className={object.prestations.includes(val.id) ? '' : 'not-checked'}>{val.name}</p>
                            </div>
                        ))}
                    </div>
                    <p className='pdbw-title'>{reduxText[557]}</p>
                    <div className='pdbw-prestations'>
                        {object.tools_all?.map((val) => (
                            <div key={val.id}>
                                <p className={object.tools.includes(val.id) ? '' : 'not-checked'}>{val.name}</p>
                            </div>
                        ))}
                    </div>
                    <p className='pdbw-title'>{reduxText[558]}</p>
                    <div className='pdbw-tarif-wrap'>
                        {object.price_set?.map((val) => (
                            <div
                                key={val.id}
                                className='pdbw-tarif-block'
                            >
                                <div className='pdbw-tarif-block-1'>
                                    <p>{val.type?.name}</p>
                                </div>
                                <div className='pdbw-tarif-block-2'>
                                    <p>{Number(val.amount).toFixed()}{' €'}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <p className='pdbw-title localisation'>{reduxText[559]}</p>
                    <p className='pdbw-transport'>{object.transport_means?.map((val) => (val.name)).join(' - ')}</p>
                    <div className='pdbw-map mb-leaflet'>
                        <PropertyDetailMapBlock markerData={object} />
                    </div>
                </div>
                <div className='pdbw-form'>
                    <div className='pdbw-form-sticky'>
                        <div className='pdbw-form-wrap'>
                            {(reduxAuth.authenticated && object.user !== reduxAuth.settings?.id) && (
                                object.room
                                    ? (
                                        <React.Fragment>
                                            <p className='pdbw-form-title'>{reduxText[735]}</p>
                                            <p className='pdbw-room-title'>{reduxText[736]}{' '}{object.room.last_message?.sender?.first_name}</p>
                                            <p className='pdbw-room-date'>{getDateWithType(object.room.last_message?.sent_at, 't')}</p>
                                            <p className='pdbw-room-body'>{object.room.last_message?.body}</p>
                                            <Link
                                                className='pdbw-room-link'
                                                to={`${view_url_room_detail}${object.room.id}/`}
                                            >
                                                {reduxText[737]}
                                            </Link>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <p className='pdbw-form-title'>{reduxText[560]}</p>
                                            <TextAreaInput
                                                error={contactMessageError}
                                                name='message'
                                                onChange={handleContactInputChange}
                                                placeholder={reduxText[562]}
                                                value={contactMessage}
                                            />
                                            <Button
                                                disabled={contactButtonDisabled}
                                                text={reduxText[566]}
                                                onClick={() => handleContactSubmit()}
                                            />
                                        </React.Fragment>
                                    )
                            )}
                            {(object.user === reduxAuth.settings?.id) && (
                                <React.Fragment>
                                    <p className='pdbw-form-title'>{reduxText[738]}</p>
                                    <Link
                                        className='pdbw-form-connect'
                                        to={`${view_url_property_edit_form}${object.id}/`}
                                    >
                                        {reduxText[739]}
                                    </Link>
                                </React.Fragment>
                            )}
                            {!reduxAuth.authenticated && (
                                <React.Fragment>
                                    <p className='pdbw-form-title'>{reduxText[560]}</p>
                                    <Link
                                        className='pdbw-form-connect'
                                        to={view_url_auth_login}
                                    >
                                        {reduxText[694]}
                                    </Link>
                                </React.Fragment>
                            )}
                        </div>
                        <div className='pdbw-bookmark'>
                            <BookmarkButton
                                app='catalog'
                                model='property'
                                objectId={object.id}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='pdbw-contact-wrap'>
                <h2 className='pdbw-text'>{reduxText[561]}</h2>
                <ImageHelper
                    alt=''
                    className='pdbw-contact-image'
                    dominant_color={reduxText[56400]}
                    src={reduxText[564]}
                />
                <Button
                    text={reduxText[563]}
                    to={view_url_contact_form}
                />
            </div>
            <Loader isOpen={isLoading} />
        </div>
    )
})
