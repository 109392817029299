// react components
import React, {
    useEffect,
} from 'react'
import {
    Device,
} from '@capacitor/device'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxAuthDeviceInfo,
    reduxAuthFetchActions,
    reduxAuthFetchSettings,
    reduxTextFetchMainText,
} from 'data'

// services
import {
    removeParamsFromUrl,
    setCookie,
} from 'services'

// main
export const GlobalHelper: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    useEffect(() => {
        removeParamsFromUrl()
        const setDeviceInfo = async () => {
            const deviceInfo = await Device.getInfo()
            dispatch(reduxAuthDeviceInfo(deviceInfo))
        }
        setDeviceInfo()
    }, [])

    useEffect(() => {
        // @ts-ignore
        dispatch(reduxTextFetchMainText(reduxAuth, dispatch))
    }, [])

    useEffect(() => {
        if (reduxAuth.settings?.id) {
            // @ts-ignore
            dispatch(reduxAuthFetchSettings(reduxAuth, dispatch))
            // @ts-ignore
            dispatch(reduxAuthFetchActions(reduxAuth, dispatch))
        }
    }, [reduxAuth.settings?.id])

    useEffect(() => {
        setCookie('mb_reduxAuthAccess', reduxAuth.access)
    }, [reduxAuth.access])

    useEffect(() => {
        setCookie('mb_reduxAuthApiRootUrl', reduxAuth.apiRootUrl)
    }, [reduxAuth.apiRootUrl])

    useEffect(() => {
        setCookie('mb_reduxAuthAuthenticated', reduxAuth.authenticated)
    }, [reduxAuth.authenticated])

    useEffect(() => {
        setCookie('mb_reduxAuthAxiosConifg', reduxAuth.axiosConfig)
    }, [reduxAuth.axiosConfig])

    useEffect(() => {
        setCookie('mb_reduxAuthSettings', reduxAuth.settings)
    }, [reduxAuth.settings])

    return null
}
