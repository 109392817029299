// react components
import React, {
    useEffect,
    useState,
    useRef,
} from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    CancelTokenSource,
} from 'axios'
import L from 'leaflet'
import {
    FeatureGroup,
    Map,
    Popup,
    ZoomControl,
    TileLayer,
} from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import Search from 'react-leaflet-search'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ImageHelper,
    InfiniteScrollHelper,
    SelectListInput,
    SeoBlock,
} from 'components'

// data
import {
    defaultReduxState,
    api_url_property_list_map,
    api_url_property_list,
    map_attribution,
    map_url,
    reduxModalErrorEventHandler,
    select_url_activity,
    select_url_prestation,
    select_url_property_type,
    select_url_transaction,
    view_url_property_form,
} from 'data'

// pages
import {
    PropertyListMapBlock,
    PropertyListSmallBlock,
} from 'pages'

// serializers
import {
    MainIdNameSerializer,
    PropertyListBlockSerializer,
    PropertyListMapSerializer,
} from 'serializers'

// services
import {
    getApiUrl,
    getInfiniteList,
    getMapList,
} from 'services'

// main
export const PropertyList: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    type fieldsType = {
        activities: MainIdNameSerializer | undefined
        area: {
            id: number
            max_range: number
            min_range: number
        } | undefined
        prestations: MainIdNameSerializer | undefined
        property_type: MainIdNameSerializer | undefined
        transaction: MainIdNameSerializer | undefined
    }
    const fieldsInitial: fieldsType = {
        activities: undefined,
        area: undefined,
        prestations: undefined,
        property_type: undefined,
        transaction: undefined,
    }

    const clusterRef = useRef(null)
    const groupRef = useRef<FeatureGroup>(null)
    const mapRef = useRef<Map>(null)

    const mylabMarker = L.icon({
        iconUrl: 'https://media.mylab.immo/global/map/marker.png',
        iconSize: [32, 42],
        iconAnchor: [16, 42],
        popupAnchor: [0, -42],
    })
    const mylabMarkerRound = L.icon({
        iconUrl: 'https://media.mylab.immo/global/map/marker-round.png',
        iconSize: [100, 100],
        iconAnchor: [50, 50],
        popupAnchor: [0, -50],
    })

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(true)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [items, setItems] = useState<PropertyListBlockSerializer[]>([])
    const [nextUrl, setNextUrl] = useState<string>('')

    const [allItems, setAllItems] = useState<PropertyListMapSerializer[]>([])
    const [allMarkers, setAllMarkers] = useState<any[]>([])
    const [currentZoom, setCurrentZoom] = useState<number>(0)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [initialMapLoad, setInitialMapLoad] = useState<boolean>(true)
    const [isMobileMap, setIsMobileMap] = useState<boolean>(false)
    const [listMapIds, setListMapIds] = useState<number[]>([])
    const [mapItem, setMapItem] = useState<PropertyListMapSerializer | undefined>(undefined)

    useEffect(() => {
        if ((window.innerWidth > 767.99) || isMobileMap) {
            onGetMapData()
        }
    }, [
        isMobileMap,
        fields,
        reduxAuth.settings?.id,
    ])

    useEffect(() => {
        if (window.innerWidth > 767.99) {
            if (listMapIds.length > 0) {
                onGetListData(
                    getApiUrl(api_url_property_list, reduxAuth),
                    true,
                )
            } else {
                setItems([])
            }
        }
    }, [
        listMapIds,
    ])

    useEffect(() => {
        if (!isMobileMap && window.innerWidth <= 767.99) {
            let axiosUrl = getApiUrl(`${api_url_property_list}?is_mobile=true`, reduxAuth)
            if (fields.transaction?.id) axiosUrl += `&transaction=${fields.transaction.id}`
            if (fields.property_type?.id) axiosUrl += `&property_type=${fields.property_type.id}`
            if (fields.prestations?.id) axiosUrl += `&prestations=${fields.prestations.id}`
            if (fields.activities?.id) axiosUrl += `&activities=${fields.activities.id}`
            if (fields.area?.id) axiosUrl += `&range_area_min=${fields.area.min_range}&range_area_max=${fields.area.max_range}`
            onGetListData(axiosUrl, true)
        }
    }, [
        fields,
        isMobileMap,
        reduxAuth.settings?.id,
    ])

    function addMarkersCheck(data: PropertyListMapSerializer[]) {
        setAllItems(data)
        if (initialMapLoad) {
            setTimeout(function () {
                setInitialMapLoad(false)
                setTimeout(function () {
                    addMarkers(data)
                }, 1000)
            }, 1000)
        } else {
            addMarkers(data)
        }
    }

    function addMarkers(data: PropertyListMapSerializer[], notFit?: boolean, round?: boolean) {
        try {
            // @ts-ignore
            if (notFit) clusterRef.current?.leafletElement.clearLayers()
            const markersLayer: L.Marker[] = []
            const markers = L.markerClusterGroup()
            data.map((val) => {
                if (val.lat_lng) {
                    const marker = L.marker(
                        [Number(val.lat_lng.split(',')[0]), Number(val.lat_lng.split(',')[1])],
                        {
                            icon: round ? mylabMarkerRound : mylabMarker,
                        },
                    ).on(
                        'click',
                        () => handleMarkersClick(val),
                    )
                    // @ts-ignore
                    marker.ec_id = val.id
                    markers.addLayer(marker)
                    markersLayer.push(marker)
                }
                return false
            })
            setAllMarkers(markersLayer)
            // @ts-ignore
            clusterRef.current?.leafletElement.addLayers(markers)
            if (!notFit) handleFitBounds(data)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PropertyList',
                'addMarkers',
            ))
        }
    }

    function handleFitBounds(data: PropertyListMapSerializer[]) {
        try {
            if (data.length > 0) {
                const map = mapRef.current?.leafletElement
                const group = groupRef.current?.leafletElement
                if (group) map?.fitBounds(group?.getBounds())
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PropertyList',
                'handleFitBounds',
            ))
        }
    }

    function handleMarkersClick(marker: PropertyListMapSerializer) {
        try {
            setMapItem(marker)
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'TabListContainer',
                'handleMarkersClick',
            ))
        }
    }

    function handleInputChange(event: any) {
        try {
            setFields({
                ...fields,
                [event.name]: event.value,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'HomePage',
                'handleInputChange',
            ))
        }
    }

    function onGetListData(
        apiUrl: string,
        changingView: boolean,
    ) {
        try {
            getInfiniteList(
                apiUrl,
                reduxAuth,
                dispatch,
                items,
                setItems,
                setNextUrl,
                axiosCancelToken,
                setAxiosCancelToken,
                setDisableInfiniteScroll,
                setIsLoading,
                setHasMore,
                'PropertyList',
                changingView,
                undefined,
                listMapIds,
            )
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PropertyList',
                'onGetListData',
            ))
        }
    }

    function onGetMapData() {
        try {
            let axiosUrl = getApiUrl(`${api_url_property_list_map}?`, reduxAuth)
            if (fields.transaction?.id) axiosUrl += `&transaction=${fields.transaction.id}`
            if (fields.property_type?.id) axiosUrl += `&property_type=${fields.property_type.id}`
            if (fields.prestations?.id) axiosUrl += `&prestations=${fields.prestations.id}`
            if (fields.activities?.id) axiosUrl += `&activities=${fields.activities.id}`
            if (fields.area?.id) axiosUrl += `&range_area_min=${fields.area.min_range}&range_area_max=${fields.area.max_range}`

            // @ts-ignore
            clusterRef.current?.leafletElement.clearLayers()
            getMapList(
                axiosUrl,
                reduxAuth,
                dispatch,
                addMarkersCheck,
                axiosCancelToken,
                setAxiosCancelToken,
                'PropertyList',
                setIsLoading,
            )
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PropertyList',
                'getListData',
            ))
        }
    }

    function onSearchNext(isVisible: boolean) {
        try {
            if (disableInfiniteScroll) return
            if (isVisible) {
                onGetListData(nextUrl, false)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'PropertyList',
                'onSearchNext',
            ))
        }
    }

    function handleVisibleMarker(e: any) {
        if (currentZoom < 15 && e.zoom >= 15) {
            addMarkers(allItems, true, true)
        } else if (e.zoom < 15 && currentZoom >= 15) {
            addMarkers(allItems, true)
        }
        setCurrentZoom(e.zoom)
        const map = mapRef.current?.leafletElement
        const contained: any = []
        let shouldRefresh = false
        allMarkers.map((val) => {
            if (map && map.getBounds().contains(val.getLatLng())) {
                contained.push(val.ec_id);
                if (!listMapIds.includes(val.ec_id)) shouldRefresh = true
            }
            return false
        })
        if (listMapIds.length !== contained.length) shouldRefresh = true
        if (shouldRefresh) setListMapIds(contained)
    }

    // Extra
    const areaOptions = [
        {
            id: 1,
            name: '0 à 100 m2',
            min_range: 0,
            max_range: 100,
        },
        {
            id: 2,
            name: '100 à 300 m2',
            min_range: 100,
            max_range: 300,
        },
        {
            id: 3,
            name: '300 - 500 m2',
            min_range: 300,
            max_range: 500,
        },
        {
            id: 4,
            name: '500 et plus m2',
            min_range: 500,
            max_range: 65535,
        },
    ]

    return (
        <IonPage className='property-list-web navbar-pt'>
            <SeoBlock pageId={3} />
            <IonContent>
                <div className='plw-cover'>
                    <ImageHelper
                        alt=''
                        className='plw-cover-image'
                        dominant_color={reduxText[71600]}
                        src={reduxText[716]}
                    />
                    <div className='plw-cover-text'>
                        <div className='mb-container'>
                            <h1 className='plw-text1'>{reduxText[717]}</h1>
                            <p className='plw-text2'>{reduxText[718]}</p>
                            <div className='plw-button-wrap'>
                                <Button
                                    text={reduxText[719]}
                                    to={`${view_url_property_form}new/intro/1/`}
                                />
                                <Button
                                    text={reduxText[720]}
                                    to={`${view_url_property_form}new/intro/2/`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='plw-filters'>
                    <SelectListInput
                        apiUrl={select_url_transaction}
                        clearable
                        error={undefined}
                        name='transaction'
                        onChange={handleInputChange}
                        placeholder={reduxText[670]}
                        value={fields.transaction || ''}
                    />
                    <SelectListInput
                        apiUrl={select_url_property_type}
                        clearable
                        error={undefined}
                        name='property_type'
                        onChange={handleInputChange}
                        placeholder={reduxText[584]}
                        value={fields.property_type || ''}
                    />
                    <SelectListInput
                        apiUrl={select_url_prestation}
                        clearable
                        error={undefined}
                        name='prestations'
                        onChange={handleInputChange}
                        placeholder={reduxText[556]}
                        value={fields.prestations || ''}
                    />
                    <SelectListInput
                        apiUrl={select_url_activity}
                        clearable
                        error={undefined}
                        name='activities'
                        onChange={handleInputChange}
                        placeholder={reduxText[900]}
                        value={fields.activities || ''}
                    />
                    <SelectListInput
                        clearable
                        error={undefined}
                        name='area'
                        onChange={handleInputChange}
                        options={areaOptions}
                        placeholder={reduxText[565]}
                        value={fields.area || ''}
                    />
                </div>
                <div className='plw-infinite'>
                    <div className={`plw-list-wrap${isMobileMap ? ' is-mobile-map' : ''}`}>
                        {items.map((item) => {
                            return (
                                <PropertyListSmallBlock
                                    key={item.id}
                                    object={item}
                                />
                            )
                        })}
                        <InfiniteScrollHelper
                            active={!disableInfiniteScroll}
                            endText
                            hasMore={hasMore}
                            isLoading={isLoading}
                            items={items}
                            onChange={onSearchNext}
                        />
                    </div>
                    <div className={`plw-map-wrap mb-leaflet${isMobileMap ? ' is-mobile-map' : ''}`}>
                        {!initialMapLoad && (
                            <Map
                                boxZoom
                                center={{ lat: 0, lng: 0 }}
                                maxZoom={16}
                                minZoom={1}
                                onViewportChanged={(e) => handleVisibleMarker(e)}
                                preferCanvas
                                ref={mapRef}
                                zoom={2}
                                zoomControl={false}
                            >
                                <TileLayer
                                    attribution={map_attribution}
                                    tileSize={512}
                                    url={map_url}
                                    zoomOffset={-1}
                                />
                                <FeatureGroup ref={groupRef}>
                                    <MarkerClusterGroup
                                        ref={clusterRef}
                                        chunkedLoading
                                        maxClusterRadius={50}
                                        onMarkerClick={(marker: PropertyListMapSerializer) => handleMarkersClick(marker)}
                                        removeOutsideVisibleBounds
                                    />
                                    <Popup>
                                        {mapItem && (
                                            <PropertyListMapBlock
                                                object={mapItem}
                                            />
                                        )}
                                    </Popup>
                                </FeatureGroup>
                                <ZoomControl position='bottomright' />
                                <Search
                                    className='tlcw-map-searchbox'
                                    closeResultsOnClick={true}
                                    inputPlaceholder={reduxText[774]}
                                    openSearchOnLoad
                                    position='topleft'
                                    showMarker={false}
                                    zoom={10}
                                />
                            </Map>
                        )}
                    </div>
                </div>
                <div className='plw-footer-wrap'>
                    <div
                        className='plw-footer'
                        onClick={() => setIsMobileMap(!isMobileMap)}
                    >
                        <ImageHelper
                            alt=''
                            className='plw-footer-img'
                            dominant_color={undefined}
                            src={reduxText[631]}
                        />
                        <span>{isMobileMap ? reduxText[883] : reduxText[632]}</span>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}
