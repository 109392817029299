// react components
import React from 'react'
import {
    IonContent,
    IonModal,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ImageHelper,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormSetSuccessModal,
    reduxModalErrorEventHandler,
} from 'data'

// main
export const FormSuccessModal: React.FC = () => {

    const dispatch = useDispatch()
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    function onButtonClick() {
        try {
            dispatch(reduxFormSetSuccessModal(false))
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'FormSuccessModal',
                'onButtonClick',
            ))
        }
    }

    return (
        <IonModal
            // @ts-ignore
            cssClass='form-success-modal-web'
            isOpen={reduxForm.successModal?.isOpen || false}
            onDidDismiss={() => dispatch(reduxFormSetSuccessModal(false))}
        >
            <IonContent fullscreen>
                <div
                    className='fsmw-backgound'
                    style={{
                        backgroundImage: `url(${reduxText[745]})`,
                    }}
                >
                    <div className='fsmw-content'>
                        <div className='fsmw-body'>
                            <p className='fsmw-text1'>{reduxForm.successModal?.text1}</p>
                            {reduxForm.successModal?.image && (
                                <ImageHelper
                                    alt=''
                                    className='fsmw-image'
                                    src={reduxForm.successModal.image}
                                    dominant_color={undefined}
                                />
                            )}
                            <p className='fsmw-text2'>{reduxForm.successModal?.text2}</p>
                            <Button
                                onClick={() => onButtonClick()}
                                text={reduxForm.successModal?.buttonText || ''}
                            />
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonModal>
    )
}
