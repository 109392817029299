// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    CancelTokenSource,
} from 'axios'
import {
    Helmet,
} from 'react-helmet'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

import {
    defaultReduxState,
    api_url_main_seo_detail,
} from 'data'

// serializers
import {
    SeoBlockSerializer,
} from 'serializers'

// services
import {
    getApiUrl,
    getDetailObject,
} from 'services'

// props
type SeoBlockProps = {
    data?: SeoBlockSerializer
    pageId?: number
}

// main
export const SeoBlock: React.FC<SeoBlockProps> = React.memo(({
    data,
    pageId,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)

    useEffect(() => {
        if (pageId) {
            getDetailObject(
                getApiUrl(`${api_url_main_seo_detail}${pageId}/`, reduxAuth),
                reduxAuth,
                dispatch,
                setPageObject,
                axiosCancelToken,
                setAxiosCancelToken,
                'SeoBlock',
            )
        }
    }, [pageId])

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource | undefined>(undefined)
    const [pageObject, setPageObject] = useState<SeoBlockSerializer | undefined>(undefined)

    if (data) {
        return (
            <Helmet>
                <meta charSet='utf-8' />
                <title>{data.title}</title>
                <link rel='shortcut icon' href={data.favicon || 'https://media.mylab.immo/global/favicon/favicon.ico'} />
                <meta name='description' content={data.description} />
                <meta name='Resource-type' content={data.resource_type} />
                <meta name='keywords' content={data.keywords} />
                <link rel='image_src' href={data.image} />
                {/* Facebook */}
                <meta property='fb:app_id' content={data.fb_app_id} />
                <meta property='og:description' content={data.description} />
                <meta property='og:image' content={data.image} />
                <meta property='og:title' content={data.title} />
                <meta property='og:type' content='website' />
                <meta property='og:site_name' content={data.site_name} />
                {/* <meta property='og:locale' content={`${props.reduxAuth.settings.language}_FR`} /> */}
                <meta name='my:fb' content='on' />
                <meta name='my:google' content='on' />
                <meta name='my:tw' content='on' />
                <meta name='my:in' content='on' />
                {/* Twitter */}
                <meta name='twitter:card' content='summary' />
                {/* <meta name='twitter:site' content={data.twitter_site} /> */}
                <meta name='twitter:title' content={data.title} />
                <meta name='twitter:description' content={data.description} />
                <meta name='twitter:image' content={data.image} />
                {/* Google */}
                <script type='application/ld+json'>
                    {data.google_stuctured_data}
                </script>
            </Helmet>
        )
    }
    if (pageId && pageObject) {
        return (
            <Helmet>
                <meta charSet='utf-8' />
                <title>{pageObject.title}</title>
                <link rel='shortcut icon' href={pageObject.favicon || 'https://media.mylab.immo/global/favicon/favicon.ico'} />
                <meta name='description' content={pageObject.description} />
                <meta name='Resource-type' content={pageObject.resource_type} />
                <meta name='keywords' content={pageObject.keywords} />
                <link rel='image_src' href={pageObject.image} />
                {/* Facebook */}
                <meta property='fb:app_id' content={pageObject.fb_app_id} />
                <meta property='og:description' content={pageObject.description} />
                <meta property='og:image' content={pageObject.image} />
                <meta property='og:title' content={pageObject.title} />
                <meta property='og:type' content='website' />
                <meta property='og:site_name' content={pageObject.site_name} />
                {/* <meta property='og:locale' content={`${props.reduxAuth.settings.language}_FR`} /> */}
                <meta name='my:fb' content='on' />
                <meta name='my:google' content='on' />
                <meta name='my:tw' content='on' />
                <meta name='my:in' content='on' />
                {/* Twitter */}
                <meta name='twitter:card' content='summary' />
                {/* <meta name='twitter:site' content={pageObject.twitter_site} /> */}
                <meta name='twitter:title' content={pageObject.title} />
                <meta name='twitter:description' content={pageObject.description} />
                <meta name='twitter:image' content={pageObject.image} />
                {/* Google */}
                <script type='application/ld+json'>
                    {pageObject.google_stuctured_data}
                </script>
            </Helmet>
        )
    }
    return null
})
