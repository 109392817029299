// react components
import thunk from 'redux-thunk'
import {
    applyMiddleware,
    createStore,
} from 'redux'
import {
    createLogger,
} from 'redux-logger'

// data
import {
    REDUX_AUTH_DEVICE_INFO,
    REDUX_TEXT_FETCH_MAIN_TEXT,
} from 'data'

// reducers
import createRootReducer from './mb_reducer'

const ignored_actions_type_log = [
    REDUX_AUTH_DEVICE_INFO,
    REDUX_TEXT_FETCH_MAIN_TEXT,
]

export default () => {

    let store
    // if (process.env.NODE_ENV === 'development') {
    if (process.env.NODE_ENV === 'test') {
        store = createStore(
            createRootReducer, {},
            applyMiddleware(
                thunk,
                createLogger({
                    predicate: (getState, action) => !ignored_actions_type_log.includes(action.type),
                }),
            ),
        )
    } else {
        store = createStore(
            createRootReducer, {},
            applyMiddleware(
                thunk,
            ),
        )
    }

    return store
}
