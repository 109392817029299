// react components
import React, {
    useState,
} from 'react'
import {
    IonContent,
    IonFooter,
    IonModal,
    IonToolbar,
} from '@ionic/react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    Button,
    ImageHelper,
    Loader,
    TextInput,
} from 'components'

// data
import {
    defaultReduxState,
    api_url_sendinblue_form_create,
    reduxFormSetIsNewsletterOpen,
    reduxModalErrorEventHandler,
} from 'data'

// services
import {
    axiosErrorHandler,
    getApiUrl,
} from 'services'

// main
export const NewsletterForm: React.FC = () => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxForm = useSelector((state: defaultReduxState) => state.reduxForm)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const [newsletterButtonDisabled, setNewsletterButtonDisabled] = useState<boolean>(true)
    const [email, setEmail] = useState<string>('')
    const [emailError, setEmailError] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    function handleNewsletterInputChange(event: any) {
        try {
            setNewsletterButtonDisabled(false)
            setEmail(event.value)
            setEmailError('')
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'NewsletterForm',
                'handleNewsletterInputChange',
            ))
        }
    }

    function handleNewsletterSubmit() {
        try {
            setIsLoading(true)
            const postUrl = getApiUrl(api_url_sendinblue_form_create, reduxAuth)
            const formData = new FormData()
            formData.append('email', email)
            axios({
                data: formData,
                method: 'post',
                url: postUrl,
            })
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') console.log(response)
                    setEmail('')
                    setEmailError('')
                    setNewsletterButtonDisabled(false)
                    setIsLoading(false)
                    dispatch(reduxFormSetIsNewsletterOpen(false))
                })
                .catch((error) => {
                    setIsLoading(false)
                    setEmailError(error.response?.data?.message)
                    axiosErrorHandler({
                        apiUrl: postUrl,
                        component: 'NewsletterForm',
                        dispatch,
                        error,
                        reduxAuth,
                        reference: 'handleNewsletterSubmit',
                        skipAlertStatusCode: [400],
                    })
                })
        } catch (error) {
            setIsLoading(false)
            dispatch(reduxModalErrorEventHandler(
                error,
                'NewsletterForm',
                'handleNewsletterSubmit',
            ))
        }
    }

    function onClose() {
        try {
            dispatch(reduxFormSetIsNewsletterOpen(false))
            setEmail('')
            setEmailError('')
        } catch (error) {
            dispatch(reduxModalErrorEventHandler(
                error,
                'NewsletterForm',
                'onClose',
            ))
        }
    }

    return (
        <IonModal
            // @ts-ignore
            cssClass='newsletter-form-web'
            isOpen={reduxForm.isNewsletterOpen}
            onDidDismiss={onClose}
        >
            <IonContent fullscreen>
                <div className='nfw-content'>
                    <p className='nfw-title'>{reduxText[672]}</p>
                    <ImageHelper
                        alt=''
                        className='hpw-image'
                        dominant_color={reduxText[45200]}
                        src={reduxText[452]}
                    />
                    <TextInput
                        error={emailError}
                        name='message'
                        onChange={handleNewsletterInputChange}
                        label={reduxText[479]}
                        value={email}
                    />
                </div>
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <div className='nfw-toolbar-wrap'>
                        <Button
                            onClick={onClose}
                            text={reduxText[630]}
                        />
                        <Button
                            disabled={newsletterButtonDisabled}
                            onClick={() => handleNewsletterSubmit()}
                            text={reduxText[457]}
                        />
                    </div>
                </IonToolbar>
            </IonFooter>
            <Loader isOpen={isLoading} />
        </IonModal>
    )
}
