// constants
export const REDUX_FORM_SET_IS_NEWSLETTER_OPEN = 'REDUX_FORM_SET_IS_NEWSLETTER_OPEN'
export const REDUX_FORM_SET_IS_SUCCESS_TOAST_OPEN = 'REDUX_FORM_SET_IS_SUCCESS_TOAST_OPEN'
export const REDUX_FORM_SET_REFRESH = 'REDUX_FORM_SET_REFRESH'
export const REDUX_FORM_SET_SUCCESS_MODAL = 'REDUX_FORM_SET_SUCCESS_MODAL'

// actions
export function reduxFormSetIsNewsletterOpen(boolean: boolean) {
    return {
        type: REDUX_FORM_SET_IS_NEWSLETTER_OPEN,
        payload: boolean,
    }
}

export function reduxFormSetIsSuccessToastOpen(value: boolean, message?: string) {
    return {
        type: REDUX_FORM_SET_IS_SUCCESS_TOAST_OPEN,
        payload: {
            boolean: value,
            message: message,
        },
    }
}

export function reduxFormSetRefresh(refreshType: 'refreshDetail' | 'refreshInlineList' | 'refreshProfile') {
    return {
        type: REDUX_FORM_SET_REFRESH,
        payload: {
            refreshType: refreshType,
            refreshValue: Math.random(),
        },
    }
}

export function reduxFormSetSuccessModal(isOpen: boolean, text1?: string, text2?: string, buttonText?: string, image?: string) {
    return {
        type: REDUX_FORM_SET_SUCCESS_MODAL,
        payload: {
            buttonText: buttonText,
            image: image,
            isOpen: isOpen,
            text1: text1,
            text2: text2,
        },
    }
}
