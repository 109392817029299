// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'
import {
    Link,
} from 'react-router-dom'

// components
import {
    BookmarkButton,
    ImageHelper,
} from 'components'

// data
import {
    defaultReduxState,
} from 'data'

// serializers
import {
    PropertyListMapSerializer,
} from 'serializers'

// props
type PropertyListMapBlockProps = {
    object: PropertyListMapSerializer
}

// main
export const PropertyListMapBlock: React.FC<PropertyListMapBlockProps> = React.memo(({
    object,
}) => {

    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    return (
        <Link
            className='property-list-map-block-web'
            to={object.get_absolute_url}
        >
            <div className='plmbw-image-wrap'>
                <ImageHelper
                    alt={object.title}
                    className='plmbw-image'
                    dominant_color={object.get_dominant_color}
                    src={object.get_image_lq}
                />
                <p className={`plmbw-transaction transaction-${object.transaction}`}>{reduxText[object.transaction === 1 ? 714 : 715]}</p>
            </div>
            <div className='plmbw-info'>
                <p className='plmbw-name mb-clamp1'>{object.title}</p>
                <div className='plmbw-footer'>
                    <div className='plmbw-info-wrap'>
                        <p className='plmbw-price mb-clamp1'>{object.get_price_first}{' € '}{object.get_price_type}</p>
                        <p className='plmbw-area mb-clamp1'>{object.get_area_total} m<sup>2</sup></p>
                        <p className='plmbw-city mb-clamp1'>{object.city?.name}</p>
                    </div>
                    <div className='plmbw-bookmark'>
                        <BookmarkButton
                            app='catalog'
                            model='property'
                            objectId={object.id}
                        />
                    </div>
                </div>
            </div>
        </Link>
    )
})
