// data
import {
    api_url_component_form_create,
    api_url_component_form_detail,
    api_url_component_form_list,
    api_url_component_form_patch,
    api_url_component_form_update,
    api_url_property_form_detail,
    reduxAuthState,
} from 'data'

// serializers
import {
    ComponentDetailFormSerializer,
    ComponentDetailFormSerializerError,
} from 'serializers'

// services
import {
    getApiUrl,
} from 'services'

// props
export type ComponentFormStateProps = {
    errors: ComponentDetailFormSerializerError | undefined
    fields: ComponentDetailFormSerializer | undefined
}

// main
export function ComponentFormState(reduxAuth: reduxAuthState) {
    return {
        // Urls
        createUrl: getApiUrl(api_url_component_form_create, reduxAuth),
        detailInlineUrl: getApiUrl(api_url_component_form_detail, reduxAuth),
        detailUrl: getApiUrl(api_url_property_form_detail, reduxAuth),
        listUrl: getApiUrl(api_url_component_form_list, reduxAuth),
        patchUrl: getApiUrl(api_url_component_form_patch, reduxAuth),
        updateUrl: getApiUrl(api_url_component_form_update, reduxAuth),

        // Fields
        errors: {},
        fields: undefined,

        // Required fields
        requiredAttribute: [
            'area',
        ],
        requiredForeignKey: [
            'designation',
            'property',
            'type',
        ],
        requiredManyToMany: [],

        // Form fields
        formAttribute: [
            'area',
        ],
        formFile: [],
        formForeignKey: [
            'designation',
            'property',
            'type',
        ],
        formJson: [],
        formManyToMany: [],
    }
}
