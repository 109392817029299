// react components
import slugify from 'slugify'

// _main
export function ecSlugify(text: string) {
    let slugifiedText = slugify(text, { replacement: ' ', lower: true })
    slugifiedText = slugifiedText.replace("'", ' ')
    slugifiedText = slugifiedText.replace('.', ' ')
    return slugifiedText
}
